import { GuideType } from "./guide.type";

export class Guide implements GuideType {
    guide: string;
    photo: string;
    comment: string;
    hora: string;
    entrada: string;
    
    constructor(){
        this.guide = '';
        this.photo = '';
        this.comment = '';
        this.hora = '';
        this.entrada = '';
    }
}