import { Dialog, Divider, MenuItem, SelectChangeEvent, Stack } from "@mui/material";
import { BasicSwalConfig, Colors, RouteConfigProps, RoutesConfig } from "Constants";
import { StyleObject } from "GlobalTypes";
import { CameraAltIcon, DeleteForeverIcon, ErrorOutlineIcon, HighlightOffIcon, MessageIcon, MoreHorizIcon, NearMeIcon } from "Iconos";
import { adaptGuideType, adaptPrinterType, adaptResponseJDAPI, adaptWarehouse } from "adapters";
import { Camera, CustomButton, CustomSelect, CustomTextInput } from "components";
import { NavigationContext } from "context/global";
import { CatalogoType, GuideType, WareHouseType, Guide, User } from "models";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetAllGuideTypes, GetPrinters, GetWareHouses, PrintGuides, SaveGuides, SearchSerial } from "services";
import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";
import { formatTime, isEnglish, isStringEmpty } from "utilities/Generals";
import './Guide.styles.css';
import AppStore from '../../../redux/store';
import SignatureCanvas from 'react-signature-canvas'
import ReactSignatureCanvas from "react-signature-canvas";
import Awaiter from "hooks/Awaiter";

type SoundType = 'E' | 'U' | 'S';

const ShowAlert = ( title: string, message : string, icono : SweetAlertIcon = 'info') => {
    const customConfig : SweetAlertOptions = {
        icon: icono,
        title: isStringEmpty(title)? BasicSwalConfig.title : title,
        text: isStringEmpty(message)? BasicSwalConfig.text : message
    }
    
    Swal.fire({...BasicSwalConfig,...customConfig});
}

const UserContext = React.createContext<User>(AppStore.getState().user);

export default function GuidePage(){
    const navigationContext = useContext(NavigationContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [location, setLocation] = useState<string>('')
    const [locations, setLocations] = useState<Array<WareHouseType>>([]);
    const [guideType, setGuideType] = useState<number>(0);
    const [guideTypes, setGuideTypes] = useState<Array<any>>([]);
    const locationPage = useLocation().pathname;
    const [showFloatMenu, setShowFloatMenu] = useState<boolean>(false);
    const [guide, setGuide] = useState<GuideType>(new Guide());
    const [guides, setGuides] = useState<Array<GuideType>>([]);
    const audioPlayer = useRef<HTMLAudioElement>(null);
    const [sound] = useState<string>(require('../../../assets/sounds/Success.mp3'));
    const user = AppStore.getState().user;
    const [selectedGuide, setSelectedGuide] = useState<GuideType>(new Guide());
    const [openSelectedGuideCommentModal, setOpenSelectedGuideCommentModal] = useState<boolean>(false);
    const [openCameraModal, setOpenCameraModal] = useState<boolean>(false);
    const [openSignatureModal, setOpenSignatureModal] = useState<boolean>(false);
    const [guidesInitialTime, setGuidesInitialTime] = useState<string>('');
    const [openPrintingModal, setOpenPrintingModal] = useState<boolean>(false);
    const [idGuia, setIdGuia] = useState<number>(0);
    
    useEffect(() => {
        const routeByLocation = Object.entries(RoutesConfig.private).find(([, route] : [string, RouteConfigProps]) =>  locationPage === `/Global/${route.path}${locationPage.endsWith('/')? '/' : ''}`);
        navigationContext.onChangePage((isEnglish()? routeByLocation?.[1].titleEn : routeByLocation?.[1].title)?? '');
        setGuidesInitialTime(formatTime(new Date()));
        GetGuideTypes()
        GetLocations();
    },[]);

    const playSound = async (_status : SoundType) => {
        const sounds = [
            { 
                status: 'E',
                sound: require('../../../assets/sounds/Wrong.mp3')
            },
            { 
                status: 'U',
                sound: require('../../../assets/sounds/Urgent.mp3')
            },
            { 
                status: 'S',
                sound: require('../../../assets/sounds/Success.mp3')
            }
        ];
        const soundPlayer = audioPlayer.current;
        const soundToPlay = sounds.find(({status}) => status === _status)?.sound;
        if(soundPlayer){
            soundPlayer.src = soundToPlay;
            soundPlayer.play();
        }
    }

    const GetGuideTypes = async () => {
        GetAllGuideTypes().then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                Swal.fire({...BasicSwalConfig,
                    title: isEnglish()? 'Error while getting types of guides' : 'Error al obtener los tipos de guías',
                    text: isEnglish()? response.data.MessageEn : response.data.Message
                });
                return;
            }
            if(responseAPI.data){
                const operationTypes = (response.data.Object as Array<any>).map(guideType => adaptGuideType(guideType))
                setGuideTypes(operationTypes);
            }
        })
        .catch(error => {
            ShowAlert('Critical Error',`${isEnglish()? 'There was a problem trying to get types of guides' : 'Ocurrió un problema al intentar obtener tipos de guías'}\n ${error}`, 'error');
        })
    };

    const GetLocations = async () => {
        GetWareHouses().then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                Swal.fire({...BasicSwalConfig,
                    text: isEnglish()? response.data.MessageEn : response.data.Message
                });
                return;
            }
            if(responseAPI.data){
                const locations = (response.data.Object as Array<any>).map(location => adaptWarehouse(location))
                setLocations(locations);
            }
        })
        .catch(error => {
            ShowAlert('Critical Error',`${isEnglish()? 'There was a problem trying to get warehouse' : 'Ocurrio un problema al intentar obtener almacenes'}\n ${error}`, 'error');
        });
    };

    const AddGuide = (guide : GuideType) => {
        if(isStringEmpty(location)){
            ShowMessage(isEnglish()? 'You have not selected warehouse' : 'No ha seleccionado almacen', 'E');
            return;
        }
        if(guideType <= 0){
            ShowMessage(isEnglish()? 'You have not selected the type of guide' : 'No ha seleccionado tipo de guía', 'E');
            return;
        }

        if(isStringEmpty(guide.guide)){
            ShowMessage(isEnglish()? 'Guide cannot be empty' : 'Guía no puede estar vacía', 'E')
            return;
        }

        guide.hora = formatTime(new Date());
        if(guides.find((_guide : GuideType) => _guide.guide === guide.guide)){
            setGuide(new Guide());
            ShowMessage(isEnglish()? 'Guide is already scanned' : 'Guía ya se encuentra escaneada', 'E');
            return;
        }

        if(guide.guide.length < 12){
            playSound('S');
            setGuides([...guides, guide]);
        }else{
            setIsLoading(true);
            SearchSerial(guide.guide, user.idUsuarioCOBO).then(response => {
                setIsLoading(false);
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    ShowMessage(isEnglish()? responseAPI.messageEn : responseAPI.messageEs, 'E');
                }else{
                    const documento = (responseAPI.data as any)?.documento?? '';
                    if(!isStringEmpty(documento)){
                        guide.comment = 'Urgent Tracking';
                        guide.entrada = documento;
                        ShowMessage(`${isEnglish()? 'Urgent Tracking' : 'Rastreo Urgente'}: ${documento}`, 'U');
                    }else{
                        playSound('S');
                    }
                    setGuides([...guides, guide]);
                }
            })
            .catch(error => {
                setIsLoading(false);
                ShowMessage(`${isEnglish()? 'There was a problem trying to validate urgent tracking' : 'Ocurrió un problema al intentar validar rastreo urgente'}\n ${error}`, 'E');
            })
        }

        setGuide(new Guide());
    }

    const RemoveGuide = () => {
        const customConfig : SweetAlertOptions = {
            title: isEnglish()? 'Delete' : 'Eliminar',
            text: isEnglish()? 'Are you sure you want to delete it?': 'Estas seguro que quieres eliminarlo?',
            showCancelButton: true
        }
        
        Swal.fire({...BasicSwalConfig,...customConfig}).then(result => {
            if(result.isConfirmed){
                setGuides(guides.filter((_guide : Guide) => _guide.guide !== selectedGuide.guide))
                setSelectedGuide(new Guide());
            }
        });
    }

    const ShowMessage = ( message : string, sound: SoundType) => {
        playSound(sound);
        const customConfig : SweetAlertOptions = {
            text: isStringEmpty(message)? BasicSwalConfig.text : message,
            showCancelButton: true
        }
        
        Swal.fire({...BasicSwalConfig,...customConfig}).then(result => {
            if(!result.isConfirmed){
                ShowMessage(message, sound);
            }
        });
    }

    const onSaveCapturedGuides = (requirePrint : boolean, idGuia = 0) => {
        setGuides([]);
        setSelectedGuide(new Guide());
        setOpenSignatureModal(false);
        setShowFloatMenu(false);
        if(requirePrint){
            setIdGuia(idGuia);
            setOpenPrintingModal(true);
        }
    }
    
    return(
        <UserContext.Provider value={user}>
            <Awaiter showLoader={isLoading}>
                <Stack direction='column' style={{width:'100%', height: '100%'}} divider={<Divider/>}>
                    <audio src={sound} style={{display:'none'}} ref={audioPlayer}></audio>
                    <Stack direction='row' style={{justifyContent:'space-between', height:'64px', margin: '0px 10px'}}>
                        <CustomSelect
                            idLabelSelect='Almacen'
                            idSelect='Almacen'
                            value={location}
                            label={isEnglish()? 'Warehouse' : 'Almacén'}
                            width='45%'
                            disabled={isLoading}
                            onChangeSelection={(event : SelectChangeEvent) => setLocation(event.target.value)}
                        >
                            {
                                locations.map((location : WareHouseType) => <MenuItem value={location.id} key={location.id}>{location.description}</MenuItem>)
                            }
                        </CustomSelect>
                        <CustomSelect
                            idLabelSelect='TipoGuia'
                            idSelect='TipoGuia'
                            value={guideType}
                            width='45%'
                            label={isEnglish()? 'Guide Type' : 'Tipo de guía'}
                            disabled={isLoading}
                            onChangeSelection={(event : SelectChangeEvent) => setGuideType(+(event.target.value))}
                        >
                            {
                                guideTypes.map((guideType : CatalogoType) => <MenuItem value={guideType.id} key={guideType.id}>{guideType.description}</MenuItem>)
                            }
                        </CustomSelect>
                    </Stack>
                    <Stack direction='row' style={{justifyContent:'space-between', height:'40', margin: '10px 10px'}}>
                        <CustomTextInput 
                            variant='standard'
                            label={isEnglish()? 'Guide' : 'Guía'}
                            size='small'
                            sx={{
                                width: '100%',
                                minWidth: '150px'
                            }}
                            onChange={(event : React.ChangeEvent<HTMLInputElement>) => setGuide({...guide, guide : event.target.value.trim()})}
                            onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                                if(event.key === 'Enter'){
                                    AddGuide(guide);
                                }
                            }}
                            value={guide.guide}
                        />
                    </Stack>
                    <p style={{margin: '10px', fontWeight: 'bold', color: Colors.primary, height: '20px'}}>{isEnglish()? 'Guides' : 'Guías'}</p>
                    <Stack direction='column' style={{height: 'calc(100vh - 310px)', overflowY: 'auto'}}>
                        {
                            guides.map((_guide : Guide) => 
                                <div 
                                    key={_guide.guide} 
                                    onClick={() => setSelectedGuide(_guide)}
                                    style={{...styles.guideItem, backgroundColor: (_guide.guide === selectedGuide.guide)? '#E9E9E9' : 'transparent'}}
                                >
                                    <p>{_guide.guide}</p>
                                    <div style={styles.guideIconContainer}>
                                        { !isStringEmpty(_guide.entrada) && 
                                            <div onClick={() => ShowAlert('Urgente', `Guía:${_guide.guide}\n\nEntrada:${_guide.entrada}`,'warning')}>
                                                <ErrorOutlineIcon style={{...styles.iconStyle, color: '#FFD800'}}/>
                                            </div>}
                                        { !isStringEmpty(_guide.comment) && <MessageIcon style={{...styles.iconStyle, color: '#808080'}}/>}
                                    </div>
                                </div>
                            )
                        }
                    </Stack>
                    <Stack direction='column' style={{display: 'flex', height:'75px', justifyContent:'center'}}>
                        <div style={{borderBottom: '1px rgba(0, 0, 0, 0.12) solid'}}>
                            <p 
                                style={{
                                    margin: '10px', 
                                    fontWeight: 'bold', 
                                    color: Colors.primary,
                                    alignSelf: 'flex-start'
                                }}
                            >
                                { isEnglish()? `Guides Summary: ${guides.length}` : `Total Guías: ${guides.length}`}
                            </p>
                        </div>
                        <CustomButton
                            style={{
                                width: '50%',
                                minWidth: '150px',
                                maxWidth: '300px',
                                alignSelf: 'center'
                            }}
                            variant='contained'
                            onClick={() => { setOpenSignatureModal(true); }}
                            disabled={guides.length === 0}
                        >
                            { isEnglish()? 'Sign' : 'Firmar'}
                        </CustomButton>
                    </Stack>
                    <div style={{...styles.floatButton, opacity: isStringEmpty(selectedGuide.guide)? '0.5' : '1'}}>
                        <div 
                            style={{
                                ...styles.floatButtonOptionsContainer, 
                                ...{
                                        display: `${showFloatMenu? 'block' : 'none'}`, 
                                        animation: `${showFloatMenu? 'appear' : 'disappear'} 0.5s`
                                    }
                                }}
                            >
                            <div style={styles.floatButtonOptions}>
                                <div 
                                    style={{...styles.floatButtonOption, backgroundColor: '#DD5144'}}
                                    onClick={() => RemoveGuide()}
                                >
                                    <DeleteForeverIcon style={{fontSize:'1.2em', color:'#FFF'}} />
                                </div>
                                <div 
                                    style={{...styles.floatButtonOption, backgroundColor: '#3B5998'}}
                                    onClick={() => {
                                        if(selectedGuide){
                                            setOpenCameraModal(true);
                                        }
                                    }}
                                >
                                    <CameraAltIcon style={{fontSize:'1.2em', color:'#FFF'}} />
                                </div>
                                <div 
                                    style={{...styles.floatButtonOption, backgroundColor: '#34A34F'}}
                                    onClick={() => {
                                        if(selectedGuide){
                                            setOpenSelectedGuideCommentModal(true);
                                        }
                                    }}
                                >
                                    <MessageIcon style={{fontSize:'1.2em', color:'#FFF'}} />
                                </div>
                            </div>
                        </div>
                        <div style={styles.floatButtonIconContainer} onClick={() => {
                                if(!isStringEmpty(selectedGuide.guide)){
                                    setShowFloatMenu(!showFloatMenu)
                                }
                            }}
                        >
                            <MoreHorizIcon style={{fontSize:'1.5em', color:'#FFF'}}/>
                        </div>
                    </div>
                    <Dialog
                        open={openSelectedGuideCommentModal}
                        fullWidth
                        maxWidth='md'
                    >
                        <SelectedGuideCommentModal 
                            selectedGuide={selectedGuide}
                            onUpdateSelectedGuide={(_guide : Guide) => {
                                setSelectedGuide(_guide);
                                setGuides(guides.map((guideItem : Guide) => {
                                    if(guideItem.guide === _guide.guide){
                                        return {...guideItem, comment: _guide.comment}
                                    }
                                    return guideItem;
                                }));
                            }}
                            onClose={() => {
                                setOpenSelectedGuideCommentModal(false);
                            }}
                        />
                    </Dialog>
                    <Dialog
                        open={openCameraModal}
                        fullScreen
                    >
                        <Stack direction='column' style={{height:'80vh', width:'100vw'}}>
                            <Camera 
                                isModal={true}
                                onClose={() => setOpenCameraModal(false)}
                                photoParameters={{
                                    pantalla: 'GUI',
                                    folio: selectedGuide.guide,
                                    entrada: ''
                                }}
                            />
                        </Stack>
                    </Dialog>
                    <Dialog
                        open={openSignatureModal}
                        fullWidth
                        maxWidth='md'
                    >
                        <SignatureModal 
                            onClose={() => setOpenSignatureModal(false)}
                            guidesParams={{
                                guidesToSave: guides, 
                                guidesType: guideType, 
                                guidesInitialTime
                            }}
                            onLoad={(_isLoading : boolean) => setIsLoading(_isLoading)}
                            onSaveGuides={(requirePrint : boolean, idGuia : number) => onSaveCapturedGuides(requirePrint, idGuia)}
                        />
                    </Dialog>
                    <Dialog
                        open={openPrintingModal}
                        fullWidth
                        maxWidth='md'
                    >
                        <PrintingModal 
                            idGuias={idGuia}
                            onClose={() => setOpenPrintingModal(false)}
                            onLoad={(_isLoading : boolean) => setIsLoading(_isLoading)}
                        />
                    </Dialog>
                </Stack>
            </Awaiter>
        </UserContext.Provider>
    )
}

type SelectedGuideCommentModalProps = {
    selectedGuide: GuideType,
    onUpdateSelectedGuide: (guide : Guide) => void,
    onClose: () => void
}

const SelectedGuideCommentModal = ({selectedGuide, onUpdateSelectedGuide, onClose} : SelectedGuideCommentModalProps) => {
    const [comment, setComment] = useState<string>(selectedGuide.comment);

    return(
        <Stack direction='column' style={{display:'flex', alignItems:'flex-start', gap:'10px', margin:'20px'}}>
            <p>Comentarios</p>
            <CustomTextInput 
                variant='standard'
                label=''
                sx={{
                    width: '100%',
                    minWidth: '150px',
                    maxHeight: '181px',
                    overflowY:'auto',
                    '& .MuiInputBase-root': {
                        '& textarea': {
                            padding: '10px'
                        }
                    }
                }}
                onChange={(event : React.ChangeEvent<HTMLInputElement>) => setComment(event.target.value)}
                value={comment}
                multiline={true}
            />
            <Stack direction='row' style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'10px'}}>
                <CustomButton
                    style={{
                        width: '50%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center'
                    }}
                    variant='contained'
                    onClick={() =>{ 
                        onUpdateSelectedGuide({...selectedGuide, comment : comment});
                        onClose();
                    }}
                >
                    Guardar
                </CustomButton>
                <CustomButton
                    style={{
                        width: '50%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center',
                        backgroundColor: '#B9B9B9'
                    }}
                    variant='contained'
                    onClick={onClose}
                >
                    Cancelar
                </CustomButton>
            </Stack>
        </Stack>
    )
};

type SignatureModalProps = {
    onClose: () => void,
    guidesParams: {
        guidesToSave: Array<GuideType>,
        guidesType: number,
        guidesInitialTime: string 
    },
    onLoad: (isLoading : boolean) => void,
    onSaveGuides: (requirePrint : boolean, idGuide : number) => void
}

const SignatureModal = ({onClose, guidesParams, onLoad, onSaveGuides} : SignatureModalProps) => {
    const [transpotistSignature, setTranspotistSignature] = useState<string>(''); 
    const [receivedSignature, setReceivedSignature] = useState<string>('');
    const transpotistSignatureCanvas = useRef<ReactSignatureCanvas>(null);
    const receivedSignatureCanvas = useRef<ReactSignatureCanvas>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const user = useContext(UserContext);

    const changeLoadingState = (_isLoading : boolean) => {
        if(_isLoading){
            transpotistSignatureCanvas.current?.off();
            receivedSignatureCanvas.current?.off();
        }else{
            transpotistSignatureCanvas.current?.on();
            receivedSignatureCanvas.current?.on();
        }
        setIsLoading(_isLoading);
        onLoad(_isLoading);
    }

    const onCloseModal = () => {
        onLoad(false);
        onClose();
    }

    const saveGuides = () => {
        if(isStringEmpty(transpotistSignature)){
            ShowAlert(isEnglish()? 'Warning' : 'Advertencia', isEnglish()? 'You have not captured the carrier signature': 'No ha capturado la firma de transportista', 'warning');
            return;
        }
        if(isStringEmpty(receivedSignature)){
            ShowAlert(isEnglish()? 'Warning' : 'Advertencia', isEnglish()? 'You have not captured the received signature' : 'No ha capturado la firma de recibido', 'warning')
            return;
        }

        changeLoadingState(true);
        SaveGuides({
            idType: guidesParams.guidesType,
            TimeInitial: guidesParams.guidesInitialTime,
            UserId: user.idUsuarioCOBO,
            SignatureT: transpotistSignature,
            SignatureR: receivedSignature,
            GuideDetail: guidesParams.guidesToSave,
            TimeFinal: new Date().toDateString()
        }).then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const customConfig : SweetAlertOptions = {
                    icon: 'error',
                    text: isEnglish()? responseAPI.messageEn : responseAPI.messageEs
                }
                
                Swal.fire({...BasicSwalConfig,...customConfig}).then(result => {
                    changeLoadingState(false);
                });
            }else{
                changeLoadingState(false);
                onCloseModal();
                const customConfig : SweetAlertOptions = {
                    icon: 'success',
                    title: isEnglish()? 'Success' : 'Éxito',
                    text: isEnglish()? 'Do you want to print a document?' : '¿Desea imprimir documento?',
                    showCancelButton: true
                }
                
                Swal.fire({...BasicSwalConfig,...customConfig}).then(result => {
                    const _idGuia = result.isConfirmed? +((responseAPI.data as any)?.idGuia) : 0; 
                    console.log(_idGuia);
                    onSaveGuides(result.isConfirmed, _idGuia);
                });
            }
        }).catch(error => {
            changeLoadingState(false);
        })
    }

    return(
        <Stack direction='column' style={{display:'flex', gap:'10px', margin:'20px'}} spacing={1}>
            <div onClick={onCloseModal} style={{alignSelf:'flex-end'}}>
                <HighlightOffIcon style={{...styles.icon, color: Colors.redError}}/>
            </div>
            <p>{ isEnglish()? 'Carrier:' : 'Transportista:'}</p>
            <Stack direction='column'>
                <SignatureCanvas 
                    penColor="#000"
                    canvasProps={{className: 'signature-canvas'}}
                    ref={transpotistSignatureCanvas}
                />
                <Stack direction='row' style={{borderTop: '1px rgba(0, 0, 0, 0.12) solid'}}>
                    <div style={{width:'100%', display:'flex', justifyContent: 'space-between', alignItems:'center',  marginTop:'10px'}}>
                        <CustomButton
                            style={{
                                width: '30%',
                                minWidth: '50px',
                                maxWidth: '300px',
                                height: '30px',
                                alignSelf: 'center'
                            }}
                            variant='contained'
                            onClick={() => setTranspotistSignature(transpotistSignatureCanvas.current?.toDataURL()?? '')}
                            disabled={isLoading}
                        >
                            Guardar
                        </CustomButton>
                        <CustomButton
                            style={{
                                width: '30%',
                                minWidth: '50px',
                                maxWidth: '300px',
                                alignSelf: 'center',
                                height: '30px',
                                backgroundColor: Colors.redError
                            }}
                            variant='contained'
                            onClick={() => {
                                transpotistSignatureCanvas.current?.clear();
                                setTranspotistSignature('');
                            }}
                            disabled={isLoading}
                        >
                            Limpiar
                        </CustomButton>
                    </div>
                </Stack>
            </Stack>
            <p>{ isEnglish()? 'Received:' : 'Recibió:'}</p>
            <Stack direction='column'>
                <SignatureCanvas 
                    penColor="#000"
                    canvasProps={{className: 'signature-canvas'}}
                    ref={receivedSignatureCanvas}
                />
                <Stack direction='row' style={{display:'flex', justifyContent: 'space-between', alignItems:'center', borderTop: '1px rgba(0, 0, 0, 0.12) solid'}}>
                    <div style={{width:'100%', display:'flex', justifyContent: 'space-between', alignItems:'center',  marginTop:'10px'}}>
                        <CustomButton
                            style={{
                                width: '30%',
                                minWidth: '50px',
                                maxWidth: '300px',
                                height: '30px',
                                alignSelf: 'center'
                            }}
                            variant='contained'
                            onClick={() => setReceivedSignature(receivedSignatureCanvas.current?.toDataURL()?? '')}
                            disabled={isLoading}
                        >
                            Guardar
                        </CustomButton>
                        <CustomButton
                            style={{
                                width: '30%',
                                minWidth: '50px',
                                maxWidth: '300px',
                                alignSelf: 'center',
                                height: '30px',
                                backgroundColor: Colors.redError
                            }}
                            variant='contained'
                            onClick={() => {
                                receivedSignatureCanvas.current?.clear();
                                setReceivedSignature('');
                            }}
                            disabled={isLoading}
                        >
                            Limpiar
                        </CustomButton>
                    </div>
                </Stack>
            </Stack>
            <Stack direction='row' style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'10px', borderTop: '1px rgba(0, 0, 0, 0.12) solid'}}>
                <CustomButton
                    style={{
                        width: '50%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center',
                        marginTop: '10px'
                    }}
                    variant='contained'
                    onClick={() =>{ 
                        saveGuides();
                    }}
                    disabled={isStringEmpty(transpotistSignature) || isStringEmpty(receivedSignature) || isLoading}
                >
                    <NearMeIcon />
                </CustomButton>
            </Stack>
        </Stack>
    )
}

type PrintingModalProps = {
    idGuias : number,
    onClose: () => void,
    onLoad: (isLoading : boolean) => void,
}

const PrintingModal = ({idGuias, onClose, onLoad} : PrintingModalProps) => {
    const [printer, setPrinter] = useState<number>(0);
    const [printers, setPrinters] = useState<Array<CatalogoType>>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        GetPrinters('Normal').then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const customConfig : SweetAlertOptions = {
                    icon: 'error',
                    text: isEnglish()? responseAPI.messageEn : responseAPI.messageEs
                }
                
                Swal.fire({...BasicSwalConfig,...customConfig});
            }else{
                const adaptedPrinters = ((responseAPI.data as Array<CatalogoType>)?? []).map((printer : any) => adaptPrinterType(printer));
                setPrinters(adaptedPrinters);
            }
        })
    },[]);

    const changeLoadingState = (_isLoading : boolean) => {
        setIsLoading(_isLoading);
        onLoad(_isLoading);
    }

    const printGuides = () => {
        if(printer > 0) {
            if(idGuias > 0){
                changeLoadingState(true);
                PrintGuides(idGuias, printer).then(response => {
                    changeLoadingState(false);
                    const responseAPI = adaptResponseJDAPI(response.data);
                    if(responseAPI.error){
                        ShowAlert('Error', isEnglish()? responseAPI.messageEn : responseAPI.messageEs);
                    }else{
                        const customConfig : SweetAlertOptions = {
                            icon: 'info',
                            title: isEnglish()? 'Success' : 'Éxito',
                            text: isEnglish()? responseAPI.messageEn : responseAPI.messageEs
                        }
                        
                        Swal.fire({...BasicSwalConfig,...customConfig}).then(() => {
                            onClose();
                        });
                    }
                })
                .catch(error => {
                    changeLoadingState(false);
                    ShowAlert('Critical Error', `${isEnglish()? 'There is no a valid guide id' : 'No se cuenta con un id de guía válido'}: ${error}`, 'error');
                })
            }else{
                ShowAlert('Error', isEnglish()? 'There is no a valid guide id' : 'No se cuenta con un id de guía válido', 'error');
            }
        }else{
            ShowAlert('Error', isEnglish()? 'No printer selected' : 'No se ha seleccionado impresora', 'error');
        }
    }

    return(
        <Stack direction='column' style={{display:'flex', gap:'10px', margin:'20px'}} spacing={1}>
            <CustomSelect
                idLabelSelect='Impresora'
                idSelect='Impresora'
                value={printer}
                label='Impresora'
                width='45%'
                disabled={isLoading}
                onChangeSelection={(event : SelectChangeEvent) => setPrinter(+(event.target.value))}
            >
                {
                    printers.map((printer : CatalogoType) => <MenuItem value={printer.id} key={printer.id}>{printer.description}</MenuItem>)
                }
            </CustomSelect>
            <Stack direction='row' style={{display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
                <CustomButton
                    style={{
                        width: '45%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center'
                    }}
                    variant='contained'
                    onClick={printGuides}
                    disabled={printer <= 0}
                >
                    {isEnglish()? 'Print' : 'Imprimir'}
                </CustomButton>
                <CustomButton
                    style={{
                        width: '45%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center',
                        backgroundColor: '#B9B9B9'
                    }}
                    variant='contained'
                    onClick={onClose}
                >
                    { isEnglish()? 'Cancel' : 'Cancelar'}
                </CustomButton>
            </Stack>
        </Stack>
    )
}

const styles : StyleObject = {
    floatButton: {
        position:'absolute', 
        bottom:'20px', 
        right:'10px', 
        backgroundColor: Colors.primary,
        width: '50px',
        height: '50px',
        borderRadius: '50%',
    },
    floatButtonIconContainer: {
        display: 'flex', 
        width:'100%', 
        height:'100%', 
        alignItems: 'center', 
        justifyContent:'center'
    },
    floatButtonOption: {
        display: 'flex', 
        width:'40px', 
        height:'40px', 
        borderRadius: '50%',
        alignItems:'center',
        justifyContent: 'center'
    },
    floatButtonOptions: {
        display:'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px'
    },
    floatButtonOptionsContainer: {
        position: 'absolute',
        bottom: '55px',
        width: '50px',
        animation: 'appear 0.5s' 
    },
    guideItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        margin: '5px 5px 0px 5px',
        padding: '5px',
        borderBottom: `1px ${Colors.primary} solid`
    },
    guideIconContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        gap: '5px'
    },
    iconStyle: {
        fontSize: '1.5em',
        color: Colors.primary
    }
}