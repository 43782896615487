import { api_url } from "Constants"
import AxiosRequest from "./AxiosRequest"
import { MasterLabelDetailType, MasterLabelType } from "pages/global/MasterLabel/MasterLabel";

const apiUrl = api_url.WareHouse;

export const SearchODC = (ODC : string) => {
    return AxiosRequest.get(`${apiUrl}EtiquetaGrupo/BuscarODC?odc=${ODC}`);
}

export const CreateGroupLabel = () => {
    return AxiosRequest.get(`${apiUrl}EtiquetaGrupo/CrearEtiquetaGrupo`);
}

export const SaveGroupLabel = (masterLabel : { EtiquetaMaestra : MasterLabelType, EtiquetaMaestraDetalle : Array<MasterLabelDetailType>}) => {
    return AxiosRequest.post(`${apiUrl}EtiquetaGrupo/Guardar`, masterLabel);
}

export const PrintMasterLabel = ({master, impresora, odc} : {master: string, impresora: number, odc: string}) => {
    return AxiosRequest.get(`${apiUrl}EtiquetaMaestra/ImprimirEtiqueta?etiqueta=${master}&impresora=${impresora}&odc=${odc}`);
}

export const PrintDeliveryRelation = ({impresora, odc} : {master: string, impresora: number, odc: string}) => {
    return AxiosRequest.get(`${apiUrl}EtiquetaMaestra/ImprimirRelacionEntrega?odc=${odc}&impresora=${impresora}`);
}