import { createUser, resetUser } from "redux/slices/user.slice";
import { useDispatch } from "react-redux";
import React, { CSSProperties, useState } from "react";
import { CustomTextInput } from "components";
import JDLogo from '../../assets/images/JDLogo.svg';
import Background from '../../assets/images/loginBackground.webp';
import { Button } from "@mui/material";
import { Colors, RoutesConfig } from "Constants";
import { NearMeIcon, PersonOutlineIcon, VisibilityIcon, VisibilityOffIcon } from 'Iconos';
import Awaiter from "hooks/Awaiter";
import { getLogin, getUserInfo } from "services";
import Swal from 'sweetalert2';
import { isEnglish, isStringEmpty } from "utilities/Generals";
import { adaptUser } from "adapters";
import { useNavigate } from "react-router-dom";
import { resetLanguage } from "redux/slices/language.slice";

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    dispatch(resetUser());
    dispatch(resetLanguage());

    const EyeIcon = () => {
        const iconStyle = {
            marginRight: '5px'
        }
        return showPassword? <VisibilityOffIcon style={iconStyle} onClick={() => setShowPassword(false)} /> : <VisibilityIcon  style={iconStyle} onClick={() => setShowPassword(true)}/>
    }

    const handleEnter = (event : React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === 'Enter'){
            handleClick();
        }
    }

    const handleClick = async () => {
        setIsLoading(true);
        const loginToken = await getLogin({
            username: username, 
            password: password,
            client_id: 'fd03669b-26f3-45e2-86a9-f50dbfc5e22a',
            client_secret: 'FoAY8fx921Fb4OfvAFfu+XWTbE3WA8WQUrxJIrpWHymqfZc9spXsbfijUjQM9Ctu/SN+obVp7XgKJgzH8zOuGQ==',
            grant_type: 'password'
        })
        .then(response => {
            setIsLoading(false);
            if(response.data?.Error){
                Swal.fire({
                    icon: 'error',
                    title: isEnglish()? 'Error while getting token' : 'Error al obtener token',
                    text: isEnglish()? 'There was a problem getting access token for credentials provided' : 'Ocurrió un problema al obtener el token de acceso con las credenciales proporcionadas'
                })
            }else{
                return response.data?.access_token?? '';
            }
        })
        .catch(error=> {
            setIsLoading(false);
            Swal.fire({
                icon: 'error',
                title: isEnglish()? 'Error while getting token' : 'Error al obtener token',
                text: isEnglish()? 'There was a problem getting access token for credentials provided' : 'Ocurrió un problema al obtener el token de acceso con las credenciales proporcionadas'
            });
            console.error(error);
            return '';
        });
        
        if(!isStringEmpty(loginToken)){
            await getUserInfo(username).then(response => {
                if(response.data?.Error){
                    Swal.fire({
                        icon: 'error',
                        title: isEnglish()? 'Error while getting user information' : 'Error al obtener la información del usuario',
                        text: response.data.Message
                    })
                    return;
                }
                if(response.data?.Object){
                    const adaptedUser = adaptUser(response.data.Object);
                    dispatch(createUser(adaptedUser));
                    navigate(`/Global/${RoutesConfig.private.inventario.path}`);
                }
            });
        }
    }

    return (
        <main style={styles.loginContainer}>
            <Awaiter showLoader={isLoading}>
                <section style={styles.loginFormContainer}>
                    <img src={JDLogo} alt='Logo de JDGroup' style={styles.imgLogo}/>
                    <CustomTextInput 
                        variant='standard' 
                        label='Usuario'
                        InputProps={{
                            startAdornment: <PersonOutlineIcon />
                        }}
                        onKeyDown={handleEnter}
                        onChange={(event : React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
                    />
                    <CustomTextInput 
                        type={showPassword? 'text' : 'password'}
                        variant='standard' 
                        label='Contraseña'
                        onKeyDown={handleEnter}
                        InputProps={{
                            startAdornment: <EyeIcon/>
                        }}
                        onChange={(event : React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                    />
                    <Button 
                        variant='contained'
                        style={styles.loginButton}
                        disabled={isLoading}
                        onClick={handleClick}
                    >
                        <NearMeIcon />
                    </Button>
                </section>
            </Awaiter>
        </main>
    )    
}

const styles : Record<string, CSSProperties> = {
    loginContainer : {
        width: '100vw',
        height: '100vh',
        backgroundImage: `linear-gradient(rgba(37, 109, 100,0.5), rgba(37, 109, 100,0.5)), url('${Background}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loginFormContainer : {
        backgroundColor: '#fff',
        padding: 'min(10%, 40px) min(10%, 40px) min(20%, 80px) min(10%, 40px)',
        opacity: 0.8,
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        rowGap: '20px'
    },
    loginButton: {
        backgroundColor: Colors.primary,
        width: '50%'
    },
    imgLogo:{
        width: '100px',
        height: '100px'
    }
}