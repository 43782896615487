import { api_url } from "Constants"
import AxiosRequest from "./AxiosRequest"

const apiUrl = api_url.WareHouse;

export const SubirFoto = (data : FormData) => {
    return AxiosRequest.post(`${apiUrl}General/SubirFoto`, data, {
        headers: {
            'Content-Type' : 'multipart/form-data'
        }
    });
}

export const GetOperations = () => {
    return AxiosRequest.get(`${apiUrl}General/GetOperation`);
}

export const GetWareHouses = () => {
    return AxiosRequest.get(`${apiUrl}General/GetWareHouse`);
}

export const GetAllGuideTypes = () => {
    return AxiosRequest.get(`${apiUrl}General/GetGuideType`);
}

export const GetPrinters = (tipo : string) => {
    return AxiosRequest.get(`${apiUrl}General/GetImpresora?tipo=${tipo}`);
}

export const GetLocations = () => {
    return AxiosRequest.get(`${apiUrl}General/GetLocation`);
}

export const GetClients = () => {
    return AxiosRequest.get(`${apiUrl}General/GetClient`);
}

export const GetPackageTypes = () => {
    return AxiosRequest.get(`${apiUrl}General/GetBulto`);
}

export const GetMerchandise = () => {
    return AxiosRequest.get(`${apiUrl}General/GetMercancia`);
}

export const GetDamageTypes = () => {
    return AxiosRequest.get(`${apiUrl}General/GetDanos`);
}