import { Divider, MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import { Colors, RouteConfigProps, RoutesConfig } from 'Constants';
import { StyleObject } from 'GlobalTypes';
import { adaptClientsInventory, adaptFoliosInventory, adaptOperationTypes, adaptWarehouse } from 'adapters/inventory.adapter';
import { CustomButton, CustomCheckBox, CustomDatePicker, CustomSelect, CustomTextInput } from 'components';
import Awaiter from 'hooks/Awaiter';
import { ClientInventoryType, FolioInventoryType, OperationType, WareHouseType } from 'models';
import { useContext, useEffect, useState } from 'react';
import { GetClientByOperationAndLocation, GetFoliosByDate, GetOperations, GetWareHouses, PostCreateInventory } from 'services';
import Swal from 'sweetalert2';
import { isEnglish, isStringEmpty } from 'utilities/Generals';
import './Inventory.css';
import dayjs, { Dayjs } from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavigationContext } from 'context/global';
import { LibraryAddCheckIcon, StraightIcon } from 'Iconos';

type ClientSelectableType = ClientInventoryType & {
    isSelected : boolean
}

type SortBy = 'clientCode' | 'clientName';
type SortOrder = 'asc' | 'desc'
type SortOrderParameters = {
    clientCode: SortOrder,
    clientName: SortOrder
}

export default function Inventory() {
    const navigate = useNavigate();
    const navigationContext = useContext(NavigationContext);
    const [operation, setOperation] = useState<number>(0);
    const [operationTypes, setOperacionTypes] = useState<Array<OperationType>>([]);
    const [location, setLocation] = useState<string>('');
    const [locations, setLocations] = useState<Array<WareHouseType>>([]);
    const [clients, setClients] = useState<Array<ClientSelectableType>>([]);
    const [filteredClients, setFilteredClients] = useState<Array<ClientSelectableType>>([]);
    const [folio,] = useState<number>(0);
    const [folios, setFolios] = useState<Array<FolioInventoryType>>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedDate,] = useState<Dayjs>(dayjs(new Date()));
    const [searchParam, setSearchParam] = useState<string>('');
    const [sortOrderParams, setSortOrderParams] = useState<SortOrderParameters>({
        clientCode: 'asc',
        clientName: 'asc'
    });
    const [activeSortOrderParam, setActiveSortOrderParam] = useState<'' | SortBy>('');
    const locationPage = useLocation().pathname;

    useEffect(() => {
        const routeByLocation = Object.entries(RoutesConfig.private).find(([, route] : [string, RouteConfigProps]) =>  locationPage === `/Global/${route.path}${locationPage.endsWith('/')? '/' : ''}`);
        navigationContext.onChangePage((isEnglish()? routeByLocation?.[1].titleEn : routeByLocation?.[1].title)?? '');
        GetTypeOfOperation();
        GetLocations();
        GetFolios(selectedDate);
    },[]);

    const GetTypeOfOperation = async () => {
        GetOperations().then(response => {
            if(response?.data?.Error){
                Swal.fire({
                    icon: 'error',
                    title: isEnglish()? 'Error while getting types of operation' : 'Error al obtener los tipos de operación',
                    text: isEnglish()? response.data.MessageEn : response.data.Message,
                    confirmButtonColor: Colors.primary
                });
                return;
            }
            if(response?.data?.Object){
                const operationTypes = (response.data.Object as Array<any>).map(operationType => adaptOperationTypes(operationType))
                setOperacionTypes(operationTypes);
            }
        })
    };

    const GetLocations = async () => {
        GetWareHouses().then(response => {
            if(response?.data?.Error){
                Swal.fire({
                    icon: 'error',
                    title: isEnglish()? 'Error while getting warehouse' : 'Error al obtener los almacenes',
                    text: isEnglish()? response.data.MessageEn : response.data.Message,
                    confirmButtonColor: Colors.primary
                });
                return;
            }
            if(response?.data?.Object){
                const locations = (response.data.Object as Array<any>).map(location => adaptWarehouse(location))
                setLocations(locations);
            }
        });
    };

    const GetClientes = (operation : number, location : string) => {
        setIsLoading(true);
        GetClientByOperationAndLocation({operation, location}).then(response => {
            setIsLoading(false);
            if(response?.data?.Error){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: isEnglish()? response.data.MessageEn : response.data.Message,
                    confirmButtonColor: Colors.primary
                });
                return;
            }
            if(response?.data?.Object){
                const clients : Array<ClientSelectableType> = (response.data.Object as Array<any>).map(client => {
                    return({...adaptClientsInventory(client), isSelected : false})
                });
                setClients(clients);
                setFilteredClients(clients);
            }
        })
        .catch(() => {
            setIsLoading(false);
        });
    }

    const ChangeOperation = (operationType : number) => {
        setOperation(operationType);
        if(operationType > 0 && !isStringEmpty(location)){
            GetClientes(operationType, location);
        }
    }

    const ChangeWareHouse = (warehouse : string) => {
        setLocation(warehouse);
        if(operation > 0 && !isStringEmpty(warehouse)){
            GetClientes(operation, warehouse);
        }
    }

    const GetFolios = (selectedDate : Dayjs) => {
        setIsLoading(true);
        const dateParam = selectedDate.format('YYYY-MM-DD'); 
        GetFoliosByDate(dateParam).then(response => {
            setIsLoading(false);
            if(response?.data?.Error){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: isEnglish()? response.data.MessageEn : response.data.Message,
                    confirmButtonColor: Colors.primary
                });
                return;
            }
            if(response?.data?.Object){
                const folios = (response.data.Object as Array<any>).map(folio => adaptFoliosInventory(folio))
                setFolios(folios)
            }
        })
        .catch(() => {
            setIsLoading(false);
        });
    }

    const ChangeSelectedDate = (selectedDate : Dayjs) => {
        GetFolios(selectedDate);
    }

    const GoToInventoryByFolio = (folio : string) => {
        if(!isStringEmpty(folio)){
            navigationContext.onChangePage(`${RoutesConfig.private.reporte_inventario.title}`);
            navigate(`/Global/${RoutesConfig.private.reporte_inventario.path}`, {state: {folio}});
        }
    }

    const FilterByClient = (clientCode : string) => {
        setSearchParam(clientCode);
        const filteredClients = isStringEmpty(clientCode)? clients : clients.filter((client : ClientSelectableType) => client.clientCode.toUpperCase().includes(clientCode.toUpperCase()));
        setFilteredClients(filteredClients);
    }

    const SelectAllClients = (selectAll : boolean) => {
        setSelectAll(selectAll);
        setClients(clients.map((client => ({...client, isSelected: selectAll}))))
        setFilteredClients(filteredClients.map((client => ({...client, isSelected: selectAll}))));
    }

    const ChangeSelectedClient = (selected : boolean, clientCode: string) => {
        const clientsModified = clients.map((client : ClientSelectableType) => {
            if(client.clientCode === clientCode){
                return {...client, isSelected: selected}
            }
            return client;
        });
        const filteredClientsModified = isStringEmpty(searchParam)? clientsModified : clientsModified.filter((client : ClientSelectableType) => client.clientCode.toUpperCase().includes(searchParam.toUpperCase()));
        setFilteredClients(filteredClientsModified);
        setClients(clientsModified);
        setSelectAll(clientsModified.every((client : ClientSelectableType) => client.isSelected));
    }
    
    const sortFunction = (sortOrderParam : SortOrder, current : string, next : string) => {
        current = current.toUpperCase();
        next = next.toUpperCase();
        let comparison = 0;
        if(current > next)
            comparison = sortOrderParam === 'asc'? 1 : -1;
        else
            comparison = sortOrderParam === 'asc'? -1 : 1;

        return comparison;
    }

    const OrderBy = (orderParam : SortBy) => {
        setActiveSortOrderParam(orderParam);
        let sortedClients = clients;
        if(orderParam === 'clientCode'){
            sortedClients = clients.sort((current : ClientSelectableType, next : ClientSelectableType) => sortFunction(sortOrderParams.clientCode, current.clientCode, next.clientCode));
            const filteredClientsSorted = isStringEmpty(searchParam)? sortedClients : sortedClients.filter((client : ClientSelectableType) => client.clientCode.toUpperCase().includes(searchParam.toUpperCase()));
            setFilteredClients(filteredClientsSorted);
            setSortOrderParams({clientCode: sortOrderParams.clientCode === 'asc'? 'desc' : 'asc', clientName: sortOrderParams.clientName})
        }else if(orderParam === 'clientName'){
            sortedClients = clients.sort((current : ClientSelectableType, next : ClientSelectableType) => sortFunction(sortOrderParams.clientName, current.clientName, next.clientName));
            const filteredClientsSorted = isStringEmpty(searchParam)? sortedClients : sortedClients.filter((client : ClientSelectableType) => client.clientCode.toUpperCase().includes(searchParam.toUpperCase()));
            setFilteredClients(filteredClientsSorted);
            setSortOrderParams({clientCode: sortOrderParams.clientCode, clientName: sortOrderParams.clientName === 'asc'? 'desc' : 'asc'})
        }
    }

    const CreateInventory = () => {
        const selectedClients = clients.filter((client : ClientSelectableType) => client.isSelected);
        if(selectedClients.length > 0){
            setIsLoading(true);
            const clientCodes = selectedClients.map((client : ClientSelectableType) => client.clientCode);
            PostCreateInventory({operation, location, clients : clientCodes}).then(response => {
                setIsLoading(false);
                if(response?.data?.Error){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: isEnglish()? response.data.MessageEn : response.data.Message,
                        confirmButtonColor: Colors.primary
                    });
                    return;
                }else{
                    GetFolios(dayjs(new Date()));
                    SelectAllClients(false);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: isEnglish()? 'You must have selected a client to generate the inventory' : 'Debe tener seleccionado algun cliente para generar el inventario',
                confirmButtonColor: Colors.primary
            })
        }
    }

    return(        
        <Stack direction='column' style={{width:'100%', height: '100%'}} divider={<Divider/>}>
            <Stack direction='row' style={{justifyContent:'space-between', height:'64px', margin:'0px 5px'}}>
                <CustomSelect
                    idLabelSelect='Operacion'
                    idSelect='Operacion'
                    value={operation}
                    width='45%'
                    label={isEnglish()? 'Operation' : 'Operación'}
                    disabled={isLoading}
                    onChangeSelection={(event : SelectChangeEvent) => ChangeOperation(+(event.target.value))}
                >
                    {
                        operationTypes.map((operationType : OperationType) => <MenuItem value={operationType.id} key={operationType.id}>{operationType.description}</MenuItem>)
                    }
                </CustomSelect>
                <CustomSelect
                    idLabelSelect='Almacen'
                    idSelect='Almacen'
                    value={location}
                    label={isEnglish()? 'Warehouse' : 'Almacén'}
                    width='45%'
                    disabled={isLoading}
                    onChangeSelection={(event : SelectChangeEvent) => ChangeWareHouse(event.target.value)}
                >
                    {
                        locations.map((location : WareHouseType) => <MenuItem value={location.id} key={location.id}>{location.description}</MenuItem>)
                    }
                </CustomSelect>
            </Stack>
            <Stack direction='row' spacing={2} style={{justifyContent: 'space-between', alignItems: 'center', margin: '5px', height: '42px'}}>
                <CustomCheckBox 
                    checked={selectAll}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => SelectAllClients(event.target.checked)}
                />
                <div style={styles.sortContainers}>
                    <p style={{fontWeight: 'bold'}} onClick={() => OrderBy('clientCode')}>{isEnglish()? 'Code' : 'Clave'}</p>
                    { activeSortOrderParam === 'clientCode' && <StraightIcon style={{...styles.sortIcon, transform: `rotate(${sortOrderParams.clientCode === 'asc'? 0 : 180}deg)`}}/> }
                </div>
                <div style={styles.sortContainers}>
                    <p style={{fontWeight: 'bold'}} onClick={() => OrderBy('clientName')}>{isEnglish()? 'Client' : 'Cliente'}</p>
                    { activeSortOrderParam === 'clientName' && <StraightIcon style={{...styles.sortIcon, transform: `rotate(${sortOrderParams.clientName === 'asc'? 0 : 180}deg)`}}/> }
                </div>
                <CustomTextInput 
                    variant='outlined'
                    label={isEnglish()? 'Search' : 'Buscar'}
                    size='small'
                    sx={{
                        width: '20%',
                        minWidth: '150px'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => FilterByClient(event.target.value)}
                />
            </Stack>
            <Stack 
                direction='row' 
                style={{...styles.tableDisplay,...styles.tableHeader}} 
                sx={{
                    '& > p' : { 
                        color: '#fff', margin:'2px 5px'
                    }
                }}>
                <div onClick={() => SelectAllClients(false)} style={{display: 'flex', justifyContent:'center'}}>
                    <LibraryAddCheckIcon style={{color: '#fff', margin: '5px', fontSize: '1em'}}/>
                </div>
                <p>{isEnglish()? 'Client Code' : 'Clave Cliente'}</p>
                <p>{isEnglish()? 'Client Name' : 'Nombre Cliente'}</p>
            </Stack>
            <Awaiter showLoader={isLoading}>
                <div style={styles.tableBody}>
                {
                    filteredClients.map((client : ClientSelectableType) => {
                        return(
                            <div key={client.clientCode} style={{...styles.tableDisplay, height:'auto'}} className='rowClientItem'>
                                <CustomCheckBox
                                    checked={client.isSelected}
                                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => ChangeSelectedClient(event.target.checked, client.clientCode)}
                                />
                                <p>{client.clientCode}</p>
                                <p>{client.clientName}</p>
                            </div>
                        )
                    })
                }
                </div>
            </Awaiter>
            <Stack direction='column' style={{margin: '5px 10px 0px'}}>
                <Stack direction='row' style={{justifyContent:'space-between'}}>
                    <CustomDatePicker 
                        value={selectedDate}
                        onChangeDate={(date : Dayjs | null) => ChangeSelectedDate(date?? dayjs(new Date()))}
                    />
                    <CustomButton
                        variant='contained'
                        onClick={CreateInventory}
                    >
                    {isEnglish()? 'Create' : 'Crear'}
                    </CustomButton>
                </Stack>
                <CustomSelect
                    idLabelSelect='Folios'
                    idSelect='Folios'
                    value={folio}
                    width='90%'
                    label='Folios'
                    disabled={isLoading}
                    onChangeSelection={(event : SelectChangeEvent) => GoToInventoryByFolio(event.target.value)}
                >
                    {
                        folios.map((folio : FolioInventoryType) => <MenuItem value={folio.idInventoryDetail} key={folio.idInventoryDetail}>{folio.folio}</MenuItem>)
                    }
                </CustomSelect>
            </Stack>
        </Stack>
    );
};

const styles : StyleObject = {
    tableCell: {
        borderBottomColor: 'gray', 
        borderBottomWidth: 1, 
        borderBottomStyle:'solid'
    },
    tableDisplay: {
        display: 'grid',
        gridTemplateColumns: '6% 40% auto',
        height: '30px',
        alignItems: 'center'
    },
    tableHeader: {
        backgroundColor: Colors.primary,
        alignItems: 'center'
    },
    tableBody: {
        height: 'calc(100vh - 300px)',
        overflowY: 'auto'
    },
    sortContainers: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    sortIcon: {
        fontSize: '1em',
    }
}