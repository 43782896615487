import { CatalogoType, IDetalle } from "models";

export const adaptClient = (data : any) : CatalogoType => {
    const adaptedClient : CatalogoType = {
        id: data?.Id?? '',
        description: data?.Description?? ''
    };

    return adaptedClient;
}

export const adaptDetalleEntradaODC = (data : any) : IDetalle => {
    const adaptedDetalleODC : IDetalle = {
        idDetalle: data?.idDetalle?? 0,
        entradaNumero: data?.EntradaNumero?? '',
        tipoBulto: data?.Tipobulto?? 0,
        bulto: data?.Bulto?? 0,
        existencia: data?.Existencia?? 0,
        cantidad: data?.Cantidad,
        scanner: data?.Scanner,
        documento: data?.Documento?? '',
        nivel: data?.Nivel?? '',
        tipoMercancia: data?.TipoMercancia?? '',
        ubicacion: data?.Ubicacion?? '',
        numeroParte: data?.NumeroParte?? '', 
        unidadMedida: data?.UnidadMedida?? '',
        peso: data?.Peso?? '',
        descripcion: data?.Descripcion?? '',
        ordenCarga: data?.OrdenCarga?? ''
    }

    return adaptedDetalleODC;
}
