import { ReciboDetalleType } from "./recibo.type";

export class ReciboDetalle implements ReciboDetalleType {
    idDetalle: number;
    cantidad: number;
    idTipoBulto: string;
    descripcion: string;
    idUbicacion: string;
    idTipoMercancia: string;
    referencia1: string;
    referencia2: string;
    referencia3: string;
    sello: string;
    entrada: string;
    constructor(){
        this.idDetalle = 0;
        this.cantidad = 0;
        this.idTipoBulto = '';
        this.descripcion = '';
        this.idUbicacion = '';
        this.idTipoMercancia = '';
        this.referencia1 = '';
        this.referencia2 = '';
        this.referencia3 = '';
        this.sello = '';
        this.entrada = '';
    }
}