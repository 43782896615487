import { IDetalle } from "./masterlabel.type";

export class Detalle implements IDetalle {
    idDetalle: number;
    entradaNumero: string;
    tipoBulto: number;
    bulto: string;
    existencia: number;
    cantidad: number | null;
    scanner: number | null;
    documento: string;
    nivel: string;
    tipoMercancia: string;
    ubicacion: string;
    numeroParte: string;
    unidadMedida: string;
    peso: string;
    descripcion: string;
    ordenCarga: string;
    
    constructor(){
        this.idDetalle = 0;
        this.entradaNumero = '';
        this.tipoBulto = 0;
        this.bulto = '';
        this.existencia = 0;
        this.cantidad = null;
        this.scanner = null;
        this.documento = '';
        this.nivel = '';
        this.tipoMercancia = '';
        this.ubicacion = '';
        this.numeroParte = '';
        this.unidadMedida = '';
        this.peso = '';
        this.descripcion = '';
        this.ordenCarga = '';
    }
}