import { Dialog, Divider, MenuItem, SelectChangeEvent, Stack } from "@mui/material";
import { BasicSwalConfig, Colors, RouteConfigProps, RoutesConfig } from "Constants";
import { StyleObject } from "GlobalTypes";
import { CameraAltIcon, CancelIcon, DeleteForeverIcon } from "Iconos";
import { adaptResponseJDAPI } from "adapters";
import { adaptDamageType } from "adapters/damageReport.adapter";
import { AxiosResponse } from "axios";
import { Camera, CustomButton, CustomLabelCheckBox, CustomSelect, CustomTextInput } from "components";
import { NavigationContext } from "context/global";
import Awaiter from "hooks/Awaiter";
import { DamageType } from "models";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetDamageTypes, SaveDamageReport, SubirFoto } from "services";
import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";
import { isEnglish, isStringEmpty } from "utilities/Generals";

const ShowAlert = ( title: string, message : string, icono : SweetAlertIcon = 'info') => {
    const customConfig : SweetAlertOptions = {
        icon: icono,
        title: isStringEmpty(title)? BasicSwalConfig.title : title,
        text: isStringEmpty(message)? BasicSwalConfig.text : message
    }
    
    Swal.fire({...BasicSwalConfig,...customConfig});
}

type EvidenceFile = {
    pictureName: string,
    pictureId: string,
    pictureUrl: string,
    file: File
}

export default function DamageReport(){
    const location = useLocation();
    const navigationContext = useContext(NavigationContext);
    const [idEntrada,] = useState<number>(location.state.idEntrada?? 0);
    const [entrada,] = useState<string>(location.state.entrada?? '');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [damagedPackages, setDamagedPackages] = useState<number>(0);
    const [hasMissingPieces, setHasMissingPieces] = useState<boolean>(false);
    const [missingPieces, setMissingPieces] = useState<number>(0);
    const [damageType, setDamageType] = useState<number>(0);
    const [damageTypes, setDamageTypes] = useState<Array<DamageType>>([]);
    const [pictures, setPictures] = useState<Array<EvidenceFile>>([]);
    const [openCameraModal, setOpenCameraModal] = useState<boolean>(false);
    const [photoCounter, setPhotoCounter] = useState<number>(0);
    const [openPhotoPreviewModal, setOpenPhotoPreviewModal] = useState<boolean>(false);
    const [selectedPhoto, setSelectedPhoto] = useState<EvidenceFile>({pictureName: '', pictureId: '', pictureUrl: '', file: new File([''], 'EmptyFile')});
    const [allowSave, setAllowSave] = useState<boolean>(false);

    useEffect(() => {
        const routeByLocation = Object.entries(RoutesConfig.private).find(([, route] : [string, RouteConfigProps]) =>  location.pathname === `/Global/${route.path}${location.pathname.endsWith('/')? '/' : ''}`);
        navigationContext.onChangePage((isEnglish()? routeByLocation?.[1].titleEn : routeByLocation?.[1].title)?? '');
        
        GetDamageTypes().then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const {messageEn, messageEs} = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                return;
            }

            if(responseAPI.data){
                const adaptedDamageTypes = (responseAPI.data as Array<any>).map((damageType : any) => adaptDamageType(damageType));
                setDamageTypes(adaptedDamageTypes);
            }
        })
        .catch(error => {
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get warehouse' : 'Ocurrió un problema al intentar obtener almacenes'}:\n ${error.message}`, 'error');
        })
    },[]);

    useEffect(() => {
        const isValid = [(idEntrada > 0), (damagedPackages > 0), (hasMissingPieces? missingPieces > 0 : true), (damageType > 0)].every((condition : boolean) => condition === true);
        setAllowSave(isValid);
    },[idEntrada, damagedPackages, hasMissingPieces, missingPieces, damageType]);

    const deletePhoto = (photo : EvidenceFile) => {
        const customConfig : SweetAlertOptions = {
            icon: 'warning',
            title: isEnglish()? 'Delete photo' : 'Eliminar fotografía',
            text: isEnglish()? `Are you sure you want to delete photo '${photo.pictureName}'?` : `¿Estás seguro que deseas eliminar la fotografía '${photo.pictureName}'?`,
            confirmButtonText: isEnglish()? 'Yes, delete' : 'Si, eliminar',
            showCancelButton: true,
            cancelButtonText: isEnglish()? 'No, cancel' : 'No, cancelar'
        }
        
        Swal.fire({...BasicSwalConfig,...customConfig}).then(result => {
            if(result.isConfirmed){
                setPictures(pictures.filter((picture : EvidenceFile) => picture.pictureId !== photo.pictureId));
            }
        });
    }

    const Save = async () => {
        const damage = {
            idReporteDanos: 0,
            CantidadDanadas: damagedPackages,
            PiezasFaltante: hasMissingPieces,
            CantidadFaltante: missingPieces,
            idTipoDano: damageType,
            idEntrada: idEntrada
        }
        setIsLoading(true);
        const saved = await SaveDamageReport(damage).then(response => {
            setIsLoading(false);
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const {messageEn, messageEs} = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                return false;
            }
            return true;
        })
        .catch(() => {
            setIsLoading(false);
            return false;
        });;

        if(saved){
            for(const file of pictures){
                const uploaded = await UploadPhoto(file).then(response => {
                    const responseAPI = adaptResponseJDAPI(response.data);
                    if(responseAPI.error){
                        const {messageEn, messageEs} = responseAPI;
                        ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                        return false;
                    }
                    return true;
                })
                .catch(error => {
                    ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to save the photo': 'Ocurrió un error al querer guardar la fotografía'}: ${error.message}`, 'error');
                    return false;
                });

                if(uploaded)
                    continue;
                else
                    break;
            }
        }
        setIsLoading(false);
        Swal.fire({...BasicSwalConfig,...{
            icon: 'success',
            title: isEnglish()? 'Success!' : 'Éxito',
            text: isEnglish()? 'Damage report has been registered successfully' : 'Reporte de daño se ha realizado correctamente'
        }}).then(result => {
            if(result.isConfirmed){
                ClearPage();
            }
        });
    }

    const UploadPhoto = (photo : EvidenceFile) : Promise<AxiosResponse<any,any>> => {
        let formData =  new FormData();
        formData.set('photo', photo.file);
        return SubirFoto(formData)
    }

    const ClearPage = () => {
        setDamagedPackages(0);
        setHasMissingPieces(false);
        setMissingPieces(0);
        setDamageType(0);
        setPhotoCounter(0);
        setPictures([]);
    }

    return(
        <Awaiter showLoader={isLoading}>
            <Stack direction='column' divider={<Divider />} spacing={1} style={{padding: '0px 5px'}}>
                <CustomTextInput 
                    variant='standard'
                    label={isEnglish()? 'Damaged Boxes/Pallet' : 'Cajas/Tarimas dañadas'}
                    size='small'
                    sx={{
                        minWidth: '100px'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => setDamagedPackages(+(event.target.value))}
                    onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                        if(!['0','1','2','3','4','5','6','7','8','9','Backspace'].includes(event.key)){
                            event.preventDefault();
                        }
                        
                    }}
                    value={damagedPackages}
                />
                <Stack direction='row' style={{display:'flex', justifyContent:'space-between', alignItems:'center', gap: '10px'}}>
                    <CustomLabelCheckBox 
                        checked={hasMissingPieces}
                        onCheckedChange={(event: React.ChangeEvent<HTMLInputElement>) => setHasMissingPieces(event.target.checked)} 
                        label={isEnglish() ? 'Missing pieces' : 'Piezas faltantes'}       
                    />
                    <CustomTextInput 
                        variant='standard'
                        label={isEnglish()? 'Quantity' : 'Cantidad'}
                        size='small'
                        sx={{
                            minWidth: '100px'
                        }}
                        onChange={(event : React.ChangeEvent<HTMLInputElement>) => setMissingPieces(+(event.target.value))}
                        onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                            if(!['0','1','2','3','4','5','6','7','8','9','Backspace'].includes(event.key)){
                                event.preventDefault();
                            }
                            
                        }}
                        value={missingPieces}
                    />
                </Stack>
                <CustomSelect
                    idLabelSelect='DamageType'
                    idSelect='DamageType'
                    value={damageType}
                    label={isEnglish()? 'Observation/Type of damage' : 'Observación/Tipo de daño'}
                    width='100%'
                    disabled={isLoading}
                    onChangeSelection={(event : SelectChangeEvent) => setDamageType(+(event.target.value))}
                >
                    {
                        damageTypes.map((damageType : DamageType) => <MenuItem key={damageType.id} value={damageType.id}>{damageType.description}</MenuItem>)
                    }
                </CustomSelect>
                <p style={{backgroundColor: Colors.primary, color: '#FFF', padding:'5px'}}>{isEnglish()? 'Photographs' : 'Fotografías'}</p>
                <Stack direction='column' style={styles.tableBody}>
                    {
                        pictures.map((picture : EvidenceFile) => 
                            <div key={picture.pictureId} style={styles.photoItem}>
                                <div>
                                    <p 
                                        style={{color:Colors.softBlue, fontStyle: 'italic'}}
                                        onClick={() => {
                                            setSelectedPhoto(picture);
                                            setOpenPhotoPreviewModal(true);
                                        }}
                                    >{picture.pictureName}</p>
                                </div>
                                <div
                                    onClick={() => deletePhoto(picture)}
                                >
                                    <DeleteForeverIcon style={{fontSize:'25px', color: Colors.primary}} />
                                </div>
                            </div>
                        )
                    }
                </Stack>
                <Stack direction='row' style={{padding:'0px 5px', gap:'20px', marginTop:'10px'}}>
                    <CustomButton
                        style={{
                            height:'30px',
                            width: '100%',
                            minWidth: '100px',
                            maxWidth: '200px',
                            alignSelf:'center',
                            justifySelf:'center'
                        }}
                        disabled={false}
                        onClick={() => {
                            setOpenCameraModal(true);
                        }}
                    >
                        <CameraAltIcon style={{fontSize:'1.5em'}}/>
                    </CustomButton>
                    <CustomButton
                        style={{
                            height:'30px',
                            width: '100%',
                            minWidth: '100px',
                            maxWidth: '200px',
                            alignSelf:'center',
                            justifySelf:'center'
                        }}
                        disabled={!allowSave}
                        onClick={Save}
                    >
                        {isEnglish()? 'Save' : 'Guardar'}
                    </CustomButton>
                </Stack>
                <Dialog
                    open={openCameraModal}
                    fullScreen
                >
                    <Stack direction='column' style={{height:'80vh', width:'100vw'}}>
                        <Camera 
                            onSavePhoto={(photo : File, picDataUrl : string) => {
                                const newPicture : EvidenceFile = {
                                    pictureId: `Foto${photoCounter + 1}`,
                                    pictureName: `Foto${photoCounter + 1}.jpeg`,
                                    pictureUrl: picDataUrl,
                                    file: photo
                                }
                                setPhotoCounter(photoCounter + 1);
                                pictures.push(newPicture);
                                setPictures(pictures);
                            }}
                            isModal={true}
                            onClose={() => setOpenCameraModal(false)}
                            photoParameters={{
                                pantalla: 'DAM',
                                folio: '',
                                entrada: entrada
                            }}
                            photoName={`DAM-${entrada}.jpeg`}
                            onTakenPictureAction='localSave'
                        />
                    </Stack>
                </Dialog>
                <Dialog
                    open={openPhotoPreviewModal}
                    fullWidth
                    maxWidth='md'
                >
                    <PhotoPreviewModal 
                        photo={selectedPhoto}
                        onClose={() => setOpenPhotoPreviewModal(false)}
                    />
                </Dialog>
            </Stack>
        </Awaiter>
    )
}

type PhotoPreviewModalProps = {
    photo: EvidenceFile,
    onClose: () => void
}

const PhotoPreviewModal = ({photo, onClose} : PhotoPreviewModalProps) => {
    return(
        <Stack direction='column' style={{margin:'0px 5px'}}>
            <div style={{display: 'flex', justifyContent:'flex-end', padding:'5px'}}>
                <div
                    onClick={onClose} 
                >
                    <CancelIcon style={{fontSize:"1.5em", color: Colors.redError}}/>
                </div>
            </div>
            <div style={styles.previewImageContainer}>
                <img 
                    src={photo.pictureUrl} 
                    alt='damage of package'
                    style={styles.previewImage}
                />
            </div>
        </Stack>
    )
}

const styles : StyleObject = {
    tableDisplay: {
        display: 'grid',
        gridTemplateColumns: '10% 15% 1fr 1fr',
        height: '30px',
        alignItems: 'center'
    },
    tableHeader: {
        backgroundColor: Colors.primary,
        alignItems: 'center'
    },
    tableBody: {
        height: 'calc(100vh - 350px)', 
        overflowY: 'auto',
        padding:'0px 5px'
    },
    photoItem:{
        display: 'flex', 
        gap:'5px',
        justifyContent:'space-between', 
        alignItems:'center',
        borderBottom: `1px ${Colors.primary} solid`,
        padding:'5px 0px'
    },
    previewImageContainer:{
        padding: '10px',
        width: 'calc(100% - 20px)',
        minWidth: '200px',
        height: 'calc(100% - 20px)',
        minHeight: '400px'
    },
    previewImage:{
        objectFit: 'scale-down',
        width: '100%',
        height: '100%'
    }
}