export interface User {
    id: number;
    name: string;
    email: string;
    firstName: string;
    lastName: string;
    idUsuarioCOBO: number,
    token: string;
}

export const UserEmptyState: User = {
    id: 0,
    name: '',
    email: '',
    firstName: '',
    lastName: '',
    idUsuarioCOBO: 0,
    token: ''
}