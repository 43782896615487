import { Dialog, Divider, Stack } from "@mui/material";
import { BasicSwalConfig, Colors, RouteConfigProps, RoutesConfig } from "Constants";
import { StyleObject } from "GlobalTypes";
import { AddIcon, CancelIcon } from "Iconos";
import { adaptMailByGroup, adaptMailGroup, adaptResponseJDAPI } from "adapters";
import { CustomButton, CustomTextInput } from "components";
import Awaiter from "hooks/Awaiter";
import { MailByGroup, MailByGroupType, MailGroup, MailGroupType } from "models";
import { useContext, useEffect, useState } from "react";
import { GetMailGroups, GetMailsByGroup, SaveMail, SaveMailGroup } from "services";
import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";
import { isEnglish, isStringEmpty } from "utilities/Generals";
import { useLongPress } from 'use-long-press';
import { NavigationContext } from "context/global";
import { useLocation } from "react-router-dom";

const ShowAlert = ( title: string, message : string, icono : SweetAlertIcon = 'info') => {
    const customConfig : SweetAlertOptions = {
        icon: icono,
        title: isStringEmpty(title)? BasicSwalConfig.title : title,
        text: isStringEmpty(message)? BasicSwalConfig.text : message
    }
    
    Swal.fire({...BasicSwalConfig,...customConfig});
}

export default function MailGroupPage(){
    const navigationContext = useContext(NavigationContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [mailGroups, setMailGroups] = useState<Array<MailGroupType>>([]);
    const [openNewMailGroupModal, setOpenNewMailGroupModal] = useState<boolean>(false);
    const [openMailsByGroupModal, setOpenMailsByGroupModal] = useState<boolean>(false);
    const [selectedMailGroup, setSelectedMailGroup] = useState<MailGroupType>(new MailGroup());
    const locationPage = useLocation().pathname;

    useEffect(() => {
        const routeByLocation = Object.entries(RoutesConfig.private).find(([, route] : [string, RouteConfigProps]) =>  locationPage === `/Global/${route.path}${locationPage.endsWith('/')? '/' : ''}`);
        navigationContext.onChangePage((isEnglish()? routeByLocation?.[1].titleEn : routeByLocation?.[1].title)?? '');
        
        GetMailGroupsList();
    }, []);

    const GetMailGroupsList = () => {
        setIsLoading(true);
        GetMailGroups().then(response => {
            setIsLoading(false);
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const { messageEn, messageEs } = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                return;
            }
            
            if(responseAPI.data){
                const adaptedMailGroups = (responseAPI.data as Array<any>)
                    .map((mailGroup : any) => adaptMailGroup(mailGroup))
                    .filter((mailGroup : MailGroupType) => mailGroup.isActive);
                setMailGroups(adaptedMailGroups);
            }
        })
        .catch(error => {
            setIsLoading(false);
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get Mail Group' : 'Ocurrio un problema al intentar obtener Grupo de Correos'}\n ${error}`, 'error')
        })
    }

    const onLongPress = useLongPress(() => {}, {
        onFinish: (_event, meta) => { 
            const mailGrupSelected = (meta?.context as MailGroupType)?? new MailGroup();
            setSelectedMailGroup(mailGrupSelected);
            setOpenNewMailGroupModal(true);
        },
        threshold: 300,
        cancelOnMovement: 30,
        cancelOutsideElement: true
    });

    return(
        <Awaiter showLoader={isLoading}>
            <Stack direction='column' divider={<Divider/>}>
                {
                    mailGroups.map((mailGroup : MailGroupType) => 
                        <div key={mailGroup.id} style={styles.listItem}>
                            <p
                                {...onLongPress(mailGroup)}
                                onClick={() => {
                                    setSelectedMailGroup(mailGroup);
                                    setOpenMailsByGroupModal(true);
                                }}
                            >{mailGroup.description}</p>
                        </div>
                    )
                }
                <div style={styles.floatButton}>
                    <div 
                        style={styles.floatButtonIconContainer} 
                        onClick={() => {
                            setSelectedMailGroup(new MailGroup())
                            setOpenNewMailGroupModal(true)
                        }}
                    >
                        <AddIcon style={{fontSize:'1.5em', color:'#FFF'}}/>
                    </div>
                </div>
                <Dialog
                    open={openNewMailGroupModal}
                    fullWidth
                    maxWidth='md'
                >
                    <NewMailGroupModal 
                        MailGroup={selectedMailGroup}
                        onClose={(updateList : boolean) => {
                            setOpenNewMailGroupModal(false);
                            if(updateList){
                                GetMailGroupsList();
                            }
                        }}
                        onLoad={(_isLoading : boolean) => setIsLoading(_isLoading)}
                    />
                </Dialog>
                <Dialog
                    open={openMailsByGroupModal}
                    fullWidth
                    maxWidth='md'
                >
                    <MailsByGroupModal 
                        MailGroup={selectedMailGroup}
                        onClose={() => setOpenMailsByGroupModal(false)}
                        onLoad={(_isLoading : boolean) => setIsLoading(_isLoading)}
                    />
                </Dialog>
            </Stack>
        </Awaiter>
    )
}

type NewMailGroupModalProps = {
    MailGroup: MailGroupType,
    onClose: (updateList : boolean) => void,
    onLoad: (isLoading : boolean) => void
}

const NewMailGroupModal = ({MailGroup, onClose, onLoad} : NewMailGroupModalProps) => {
    const [currentMailGroup, setCurrentMailGroup] = useState<MailGroupType>(MailGroup);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const changeLoadingState = (_isLoading : boolean) => {
        setIsLoading(_isLoading);
        onLoad(_isLoading);
    }

    const Save = (mailGroup : MailGroupType) => {
        if(!isStringEmpty(mailGroup.description)){
            changeLoadingState(true);
            SaveMailGroup(mailGroup).then(response => {
                changeLoadingState(false);
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    const { messageEn, messageEs } = responseAPI;
                    ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                    return;
                }
                onClose(true);
            })
            .catch(error => {
                changeLoadingState(false);
                ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to save information' : 'Ocurrio un problema al intentar guardar información'}\n ${error}`, 'error')
            })
        }else{
            ShowAlert('Error', isEnglish()? 'You need to name the group' : 'Es necesario asignar un nombre al grupo', 'error');
        }
    }

    return(
        <Stack direction='column' style={{display:'flex', alignItems:'flex-start', gap:'10px', margin:'20px'}}>
            <CustomTextInput 
                variant='standard'
                label={isEnglish()? 'Description' : 'Descripción'}
                sx={{
                    width: '100%'
                }}
                onChange={(event : React.ChangeEvent<HTMLInputElement>) => setCurrentMailGroup({...currentMailGroup, description : event.target.value})}
                value={currentMailGroup.description}
            />
            <Stack direction='row' style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'10px'}}>
                <CustomButton
                    style={{
                        width: '50%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center'
                    }}
                    variant='contained'
                    onClick={() =>{ 
                        Save({...currentMailGroup, isActive : true});
                    }}
                    disabled={isLoading}
                >
                    Guardar
                </CustomButton>
                <CustomButton
                    style={{
                        width: '50%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center',
                        backgroundColor: '#B9B9B9'
                    }}
                    variant='contained'
                    onClick={() => onClose(false)}
                    disabled={isLoading}
                >
                    Cancelar
                </CustomButton>
            </Stack>
            {
                currentMailGroup.id > 0 &&
                <CustomButton
                    style={{
                        width: '100%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center',
                        backgroundColor: Colors.redError
                    }}
                    variant='contained'
                    onClick={() => {
                        currentMailGroup.isActive = false;
                        Save(currentMailGroup);
                    }}
                    disabled={isLoading}
                >
                    Eliminar
                </CustomButton>
            }
        </Stack>
    )
}

type MailByGroupModalProps = {
    MailGroup: MailGroupType,
    onClose: () => void,
    onLoad: (isLoading : boolean) => void
}

const MailsByGroupModal = ({MailGroup, onClose} : MailByGroupModalProps) => {
    const [mailsByGroup, setMailsByGroup] = useState<Array<MailByGroupType>>([]);
    const [selectedMail, setSelectedMail] = useState<MailByGroupType>(new MailByGroup());
    const [openNewMailByGroupModal, setOpenNewMailByGroupModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        GetMailsByGroupList();
    },[]);

    const GetMailsByGroupList = () => {
        setIsLoading(true);
        GetMailsByGroup(MailGroup.id).then(response => {
            setIsLoading(false);
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const { messageEn, messageEs } = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                return;
            }
            
            if(responseAPI.data){
                const adaptedMailsByGroup = (responseAPI.data as Array<any>)
                    .map((mail : any) => adaptMailByGroup(mail))
                    .filter((mail : MailByGroupType) => mail.isActive);
                    console.log(adaptedMailsByGroup);
                setMailsByGroup(adaptedMailsByGroup);
            }
        })
        .catch(error => {
            setIsLoading(false);
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get mails' : 'Ocurrio un problema al intentar obtener correos'}\n ${error}`, 'error')
        })
    }

    const onLongPress = useLongPress(() => {}, {
        onFinish: (_event, meta) => { 
            const mailByGroup = (meta?.context as MailByGroupType)?? new MailByGroup();
            setSelectedMail(mailByGroup);
            setOpenNewMailByGroupModal(true);
        },
        threshold: 300,
        cancelOnMovement: 30,
        cancelOutsideElement: true
    });

    return(
        <Awaiter showLoader={isLoading}>
            <Stack direction='column' divider={<Divider />}>
                <div 
                    style={{display: 'flex', justifyContent:'flex-end', padding:'5px'}}
                    onClick={onClose}    
                >
                    <CancelIcon style={{fontSize:"1.5em", color: Colors.redError}}/>
                </div>
                <div style={{minHeight: '100px', maxHeight:'500px', overflowY:'auto', padding:'0px 10px'}}>
                {
                    mailsByGroup.length === 0? 
                    <p style={{textAlign:'center', margin:'5px 0px'}}>{isEnglish()? 'No data to display' : 'Sin información para mostrar'}</p>
                    :
                    mailsByGroup.map((mail : MailByGroupType) => 
                        <div key={mail.id} style={{...styles.listItem, borderBottom:`1px ${Colors.primary} solid`}}>
                            <p
                                {...onLongPress(mail)}
                            >{mail.email}</p>
                        </div>
                    )
                }
                </div>
                <div style={styles.floatButton}>
                    <div 
                        style={styles.floatButtonIconContainer} 
                        onClick={() => {
                            setSelectedMail({...new MailByGroup(), idMailGroup : MailGroup.id })
                            setOpenNewMailByGroupModal(true)
                        }}
                    >
                        <AddIcon style={{fontSize:'1.5em', color:'#FFF'}}/>
                    </div>
                </div>
                <Dialog
                    open={openNewMailByGroupModal}
                    fullWidth
                    maxWidth='md'
                >
                    <NewMailByGroupModal 
                        Mail={selectedMail}
                        onClose={(updateList : boolean) => {
                            setOpenNewMailByGroupModal(false);
                            if(updateList){
                                GetMailsByGroupList();
                            }
                        }}
                        onLoad={(_isLoading : boolean) => setIsLoading(_isLoading)}
                    />
                </Dialog>
            </Stack>
        </Awaiter>
    )
}


type NewMailByGroupModalProps = {
    Mail: MailByGroupType,
    onClose: (updateList : boolean) => void,
    onLoad: (isLoading : boolean) => void
}

const NewMailByGroupModal = ({Mail, onClose, onLoad} : NewMailByGroupModalProps) => {
    const [currentMail, setCurrentMail] = useState<MailByGroupType>(Mail);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const changeLoadingState = (_isLoading : boolean) => {
        setIsLoading(_isLoading);
        onLoad(_isLoading);
    }

    const Save = (mail : MailByGroupType) => {
        if(!isStringEmpty(mail.email)){
            changeLoadingState(true);
            SaveMail(mail).then(response => {
                changeLoadingState(false);
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    const { messageEn, messageEs } = responseAPI;
                    ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                    return;
                }
                onClose(true);
            })
            .catch(error => {
                changeLoadingState(false);
                ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to save mail' : 'Ocurrio un problema al intentar guardar correo'}\n ${error}`, 'error')
            })
        }else{
            ShowAlert('Eror', isEnglish()? 'It is necessary to assign an email': 'Es necesario asignar un correo', 'error');
        }
    }

    return(
        <Stack direction='column' style={{display:'flex', alignItems:'flex-start', gap:'10px', margin:'20px'}}>
            <CustomTextInput 
                variant='standard'
                label={isEnglish()? 'Email' : 'Correo'}
                sx={{
                    width: '100%'
                }}
                onChange={(event : React.ChangeEvent<HTMLInputElement>) => setCurrentMail({...currentMail, email : event.target.value})}
                value={currentMail.email}
            />
            <Stack direction='row' style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'10px'}}>
                <CustomButton
                    style={{
                        width: '50%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center'
                    }}
                    variant='contained'
                    onClick={() =>{ 
                        Save({...currentMail, isActive : true});
                    }}
                    disabled={isLoading}
                >
                    Guardar
                </CustomButton>
                <CustomButton
                    style={{
                        width: '50%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center',
                        backgroundColor: '#B9B9B9'
                    }}
                    variant='contained'
                    onClick={() => onClose(false)}
                    disabled={isLoading}
                >
                    Cancelar
                </CustomButton>
            </Stack>
            {
                currentMail.id > 0 &&
                <CustomButton
                    style={{
                        width: '100%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center',
                        backgroundColor: Colors.redError
                    }}
                    variant='contained'
                    onClick={() => {
                        currentMail.isActive = false;
                        Save(currentMail);
                    }}
                    disabled={isLoading}
                >
                    Eliminar
                </CustomButton>
            }
        </Stack>
    )
}

const styles : StyleObject = {
    listItem: {
        padding: '10px 5px'
    },
    floatButton: {
        position:'absolute', 
        bottom:'20px', 
        right:'10px', 
        backgroundColor: Colors.primary,
        width: '50px',
        height: '50px',
        borderRadius: '50%',
    },
    floatButtonIconContainer: {
        display: 'flex', 
        width:'100%', 
        height:'100%', 
        alignItems: 'center', 
        justifyContent:'center'
    },
}