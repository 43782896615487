import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import { Login } from './pages';
import './index.css';
import { AuthGuard } from 'guards/auth.guard';
import { Global } from 'pages/global/Global';
import NotFound from 'pages/NotFound';
import AppStore from 'redux/store';
import { RoutesConfig } from 'Constants';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function App() {
  const user = AppStore.getState().user;
  const isLoggedIn = user.id > 0;
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Navigate to={ isLoggedIn? 'Global' : RoutesConfig.public.login.path}/>}></Route>
          <Route path={RoutesConfig.public.login.path} Component={Login}></Route>
          <Route Component={AuthGuard}>
            <Route path='/Global/*' Component={Global}></Route>
          </Route>
          <Route path='*' Component={NotFound}/>
        </Routes>
      </BrowserRouter>
      </LocalizationProvider>
    </div>
  );
}

export default App;
