import { configureStore } from '@reduxjs/toolkit'
import { User } from 'models';
import { userReducer, languageReducer } from './slices';

export interface AppStore {
    user: User,
    language: string;
}

export default configureStore<AppStore>({
    reducer: {
        user: userReducer,
        language: languageReducer
    }
})