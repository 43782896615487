import { isEnglish } from 'utilities/Generals'
import NotFoundImg from '../assets/images/404NotFound.png'

export default function NotFound(){
    return(
        <main style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems: 'center', margin:'10px'}}>
            <img src={NotFoundImg} alt='404 Not Found' style={{objectFit: 'cover', width:'100%'}}/>
            <h2>{isEnglish()? 'Page not found' : 'No se encontró la página'}</h2>
        </main>
    )
}