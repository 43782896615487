import { Divider, Stack, Switch } from "@mui/material";
import { Colors, RouteConfigProps, RoutesConfig } from "Constants";
import { StyleObject } from "GlobalTypes";
import { NavigationContext } from "context/global";
import Awaiter from "hooks/Awaiter";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateLanguage } from "redux/slices/language.slice";
import { isEnglish } from "utilities/Generals";

export default function Configuration(){
    const navigate = useNavigate();
    const navigationContext = useContext(NavigationContext);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const locationPage = useLocation().pathname;

    useEffect(() => {
        const routeByLocation = Object.entries(RoutesConfig.private).find(([, route] : [string, RouteConfigProps]) =>  locationPage === `/Global/${route.path}${locationPage.endsWith('/')? '/' : ''}`);
        navigationContext.onChangePage((isEnglish()? routeByLocation?.[1].titleEn : routeByLocation?.[1].title)?? '');
    },[]);

    return(
        <Awaiter showLoader={isLoading}>
            <Stack direction='column' divider={<Divider/>} style={{margin: '0px 10px 0px 10px'}} spacing={1}>
                <Stack direction='row' style={styles.rowItem}>
                    <p style={{fontSize:'1.2em'}}>{isEnglish()? 'English' : 'Inglés'}</p>
                    <Switch defaultChecked={isEnglish()}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const self = event.target; 
                            setIsLoading(true);
                            dispatch(updateLanguage(self.checked? 'en' : 'es'));
                            setTimeout(() => {
                                setIsLoading(false);
                                window.location.href = window.location.origin;
                            }, 100);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{
                            '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb':{
                                backgroundColor: Colors.primary
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':{
                                backgroundColor: Colors.primary
                            }
                        }}
                    />
                </Stack>
                <p 
                    style={{fontSize:'1.2em'}}
                        onClick={() => { navigate(`/Global/${RoutesConfig.private.grupo_correos.path}`)}}
                >
                    {isEnglish()? 'Mail Group' : 'Grupo de Correos'}
                </p>
            </Stack>
        </Awaiter>
    )
}

const styles : StyleObject = {
    rowItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
} 