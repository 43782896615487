import { Dialog, Divider, MenuItem, SelectChangeEvent, Stack } from "@mui/material";
import { BasicSwalConfig, Colors, RouteConfigProps, RoutesConfig } from "Constants";
import { StyleObject } from "GlobalTypes";
import { CameraAltIcon, DeleteForeverIcon, SearchIcon } from "Iconos";
import { adaptCatalog, adaptResponseJDAPI, adaptSalida } from "adapters";
import { Camera, CustomButton, CustomSelect, SmallOutlinedCustomTextInput } from "components";
import Awaiter from "hooks/Awaiter";
import {  DetalleOrdenCargaType, IEntrada, Entrada, CatalogoType } from "models";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GetSalidasByODC, SaveSalida, ValidateMasterLabel } from "services";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { exists, isEnglish, isStringEmpty } from "utilities/Generals";
import AppStore from "redux/store";
import { NavigationContext } from "context/global";
import { useLocation } from "react-router-dom";

export default function Output(){
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [entrada, setEntrada] = useState<IEntrada>(new Entrada());
    const [entradas, setEntradas] = useState<Array<DetalleOrdenCargaType>>([]);
    const [salida, setSalida] = useState<CatalogoType>({id: '', description:''});
    const [salidas, setSalidas] = useState<Array<CatalogoType>>([]);
    const [etiqueta, setEtiqueta] = useState<string>('');
    const [scannerStatus, setScannerStatus] = useState<ScannerStatusType>('none');
    const [odc, setODC] = useState<string>('');
    const [tarima, setTarima] = useState<string>('');
    const [scannedLabels, setScannedLabels] = useState<Array<string>>([]);
    const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
    const [openCameraModal, setOpenCameraModal] = useState<boolean>(false);
    const user = AppStore.getState().user;
    const [sound, setSound] = useState<string>('');
    const audioPlayer = useRef<HTMLAudioElement>(null);
    const navigationContext = useContext(NavigationContext);
    const location = useLocation().pathname;

    useEffect(() =>{ 
        const routeByLocation = Object.entries(RoutesConfig.private).find(([, route] : [string, RouteConfigProps]) =>  location === `/Global/${route.path}${location.endsWith('/')? '/' : ''}`);
        navigationContext.onChangePage((isEnglish()? routeByLocation?.[1].titleEn : routeByLocation?.[1].title)?? '');
    },[]);

    const playSound = async (status : string) => {
        if(status === 'E'){
            setSound(require('../../../assets/sounds/Wrong.mp3'));
        }else{
            setSound(require('../../../assets/sounds/Success.mp3'));
        }
        audioPlayer.current?.play();
    }

    const buscarSalida = (odc : string) => {
        setScannerStatus(ScannerStatus.NONE);
        setScannedLabels([]);
        if(!isStringEmpty(odc)){
            setIsLoading(true);
            GetSalidasByODC(odc).then(response => {
                setIsLoading(false);
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    ShowErrorMessage(isEnglish()? responseAPI.messageEn : responseAPI.messageEs);
                    setEntrada(new Entrada());
                    setEntradas([]);
                    setODC('');
                }else{
                    if(!isStringEmpty(responseAPI.messageEs)){
                        ShowErrorMessage(isEnglish()? responseAPI.messageEn : responseAPI.messageEs);
                    }

                    if(responseAPI.data){
                        const salida = adaptSalida(responseAPI.data);
                        setEntrada(salida.entrada);
                        setEntradas(salida.detalle);
                    }else{
                        setEntrada(new Entrada());
                        setEntradas([]);
                    }
                }
            }).catch(error => {
                setIsLoading(false);
                setEntrada(new Entrada());
                setEntradas([]);
                ShowErrorMessage(isEnglish()? 'There was a problem trying to get load order information\n' : `Ocurrió un problema al intentar obtener información de la orden de carga \n${error.message}`)
            });
        }else{
            ShowErrorMessage(isEnglish()? 'ODC/Master not valid' : 'ODC/Master no válida.')
        }
    } 

    const AgregarEscaneo = (etiqueta : string) => {
        if(entradas.length === 0){
            ShowErrorMessage(isEnglish()? 'There is no load order information to scan' : 'No hay informacion de orden de carga a escanear.');
            setEtiqueta('');
            return;
        }else{
            if(scannerStatus === ScannerStatus.COMPLETADA){
                ShowErrorMessage(isEnglish()? 'Load order is complete, you cannot scan anything else' : 'Orden de carga esta completada, no puede escanear nada más');
                setEtiqueta('');
                return;
            }
            
            if(!isStringEmpty(etiqueta)){
                if(isMasterLabel(etiqueta)){
                    ValidaEtiquetaMaster(etiqueta);
                }else{
                    ValidaSoloBulto(etiqueta);
                }
            }
        }
        setEtiqueta('');
    }

    const isMasterLabel = (etiqueta : string) => etiqueta.toUpperCase().startsWith('M');

    const ValidaEtiquetaMaster = (etiqueta : string) => {
        if(!isStringEmpty(entrada.cliente) && !isStringEmpty(entrada.idEntrada.toString())){
            ValidateMasterLabel({etiqueta, idEntrada : entrada.idEntrada, cliente : entrada.cliente}).then(response => {
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    ShowErrorMessage(isEnglish()? responseAPI.messageEn : responseAPI.messageEs);
                    return;
                }
                
                if(responseAPI.data){
                    const EntradasPorEtiquetaMaestra : Array<string> = responseAPI.data as Array<string>;
                    EntradasPorEtiquetaMaestra.forEach(etiqueta => ValidaSoloBulto(etiqueta));
                }
            }).catch(error=>{
                ShowErrorMessage(`${(isEnglish()? 'There was a problem validating master label' : 'Ocurrió un problema al validar etiqueta maestra')}\n${error.message}`);
            })
        }else{
            ShowErrorMessage(isEnglish()? 'A load order has not been scanned' : 'No se ha escaneado una orden de carga');
        }
    }

    const ValidaSoloBulto = (etiqueta : string) => {
        try{
            if(scannerStatus === ScannerStatus.COMPLETADA){
                ShowErrorMessage(isEnglish()? 'Load order is complete, you cannot scan anything else' : 'Orden de carga esta completada, no puede escanear nada mas');
                return;
            }
            const splittedLabel = etiqueta.split('-');
            const [numeroEntrada, tipoBulto] = splittedLabel;
            if(!isStringEmpty(numeroEntrada) && !isStringEmpty(tipoBulto)){
                if(!entradas.find((entrada : DetalleOrdenCargaType) => entrada.entradaNumero == numeroEntrada && entrada.tipoBulto == tipoBulto)){
                    ShowErrorMessage(isEnglish()? 'Package does not belong to this load order' : 'Paquete no pertenece a esta orden de carga');
                    return;
                }

                const labelHasBeenScanned = exists(scannedLabels.filter((scannedLabel : string) => !isStringEmpty(scannedLabel)).find((scannedLabel : string) => scannedLabel === etiqueta));
                if(labelHasBeenScanned){
                    ShowErrorMessage(isEnglish()? `Package ${etiqueta} was already scanned. It belongs to the ODC`: `Paquete ${etiqueta} ya fue escaneado. Si pertenece a la ODC`) 
                }else{
                    playSound('S');
                    ValidateOrderIsComplete({numeroEntrada, tipoBulto});
                    let tmp = scannedLabels;
                    tmp.push(etiqueta)
                    setScannedLabels([...tmp]);
                }
            }else{
                ShowErrorMessage(isEnglish()? 'The label is incomplete' : 'La etiqueta esta incompleta');
            }
        }catch(error){
            ShowErrorMessage(`${(isEnglish()? 'There was a problem validating package' : 'Ocurrio un problema al validar bulto')}\n${error}`)
        }
    }

    const ValidateOrderIsComplete = ({numeroEntrada, tipoBulto} : {numeroEntrada : string, tipoBulto : string}) => {
        const entrada = entradas.find((entrada : DetalleOrdenCargaType) => entrada.entradaNumero == numeroEntrada && entrada.tipoBulto == tipoBulto);
        if(entrada){
            entrada.scanner += 1;
        }

        const entradaCompletada = entradas.find((entrada :  DetalleOrdenCargaType) => entrada.scanner === 0 || entrada.scanner < entrada.cantidad);
        if(!entradaCompletada){
            setScannerStatus(ScannerStatus.COMPLETADA);
            ShowAlert({title: 'Aviso', icon: 'info', text: isEnglish()? 'All packages have been scanned' : 'Se han escaneado todos los bultos.'})
        }
    }

    const ShowErrorMessage = ( message : string) => {
        playSound('E');
        const customConfig : SweetAlertOptions = {
            text: isStringEmpty(message)? BasicSwalConfig.text : message,
            showCancelButton: true
        }
        
        Swal.fire({...BasicSwalConfig,...customConfig}).then(result => {
            if(!result.isConfirmed){
                ShowErrorMessage(message);
            }
        });
    }

    const ShowAlert = (customConfig : SweetAlertOptions) => {
        Swal.fire({...BasicSwalConfig,...customConfig});
    }

    /**
     * 
     * @param leftValue 
     * @param rightValue 
     * @returns -1 si leftValue es menor que rightValue, 0 si son iguales ó 1 si leftValue es mayor que rigthValue
     */
    const ComparerFunction = (leftValue: number, rightValue: number) : -1 | 0 | 1 => {
        let diff = leftValue - rightValue;
        return diff === 0? diff : (diff > 0? 1 : -1);
    }

    const ClearScreen = () => {
        Swal.fire({...BasicSwalConfig, ...{
            title: isEnglish()? 'Delete' : 'Eliminar', 
            text: isEnglish()? 'Are you sure you want to clean the screen?' : '¿Está seguro que desea limpiar la pantalla?',
            confirmButtonText: isEnglish()? 'Yes' : 'Si',
            showCancelButton: true,
            cancelButtonText: 'No'
        }}).then(result => {
            if(result.isConfirmed){
                setODC('');
                setEtiqueta('');
                setEntrada(new Entrada());
                setEntradas([]);
            }
        });
    }

    const Guardar = () => {
        if(entradas.length === 0){
            ShowAlert({text: isEnglish()? 'There is no detail to evaluate' : 'No hay detalle para evaluar'});
            return;
        }
        if(scannerStatus !== ScannerStatus.COMPLETADA){
            ShowAlert({text: isEnglish()? 'Some packages are still pending' : 'Algunos paquetes aun están pendientes'});
        }else{
            if(scannerStatus !== ScannerStatus.PROCESANDO){
                if(scannerStatus !== ScannerStatus.GUARDADO){
                    if(tarima && !isStringEmpty(tarima)){
                        entrada.tarimas = tarima;
                        entrada.elaboro = user.idUsuarioCOBO.toString();
                        setIsLoading(true);
                        setScannerStatus(ScannerStatus.PROCESANDO);
                        SaveSalida({entrada, detalle : entradas}).then(response => {
                            setIsLoading(false);
                            setScannerStatus(ScannerStatus.NONE);
                            const responseAPI = adaptResponseJDAPI(response.data);
                            if(responseAPI.error){
                                ShowErrorMessage(isEnglish()? responseAPI.messageEn : responseAPI.messageEs);
                            }else{
                                setSalidas([...salidas, ...(responseAPI.data as Array<any>).map((obj : CatalogoType) => adaptCatalog(obj))]);
                                ShowAlert({title: 'Aviso', icon:'success', text: isEnglish()? 'Output was successfully saved' :'Se ha guardado correctamente la salida.'});
                                setScannerStatus(ScannerStatus.GUARDADO);
                            }
                            
                        }).catch(error => {
                            setIsLoading(false);
                            setScannerStatus(ScannerStatus.NONE);
                            ShowAlert({title: 'Critical Error', text:  `${(isEnglish()? 'There was a problem saving the information' : 'Ocurrio un problema al guardar la informacion')}\n${error.message}`});
                        })
                    }else{
                        ShowAlert({title: 'Aviso', icon:'warning', text: isEnglish()? 'You must add quantity of pallets \n' : 'Debe agregar cantidad de tarimas'});
                    }
                }else{
                    ShowAlert({title: 'Aviso', icon:'warning', text: isEnglish()? 'An output has already been created for this WO' : 'Ya se ha creado una salida para esta ODC.'});
                }
            }else{
                ShowAlert({title: 'Aviso', icon:'warning', text: isEnglish()? 'Operation is in process, wait for it to finish' : 'Operación se encuentra en proceso, esperar ha que finalice.'});
            }
        }
    }

    return(
        <Stack direction='column' style={{width:'100%', height: '100%'}} divider={<Divider/>}>
            <audio src={sound} style={{display:'none'}} ref={audioPlayer}></audio>
            <Stack direction='row' style={styles.rowFields}>
                <p>ODC/Master:</p>
                <SmallOutlinedCustomTextInput
                    disabled={false}
                    value={odc}
                    onChangeText={(event : React.ChangeEvent<HTMLInputElement>) => setODC(event.target.value) }
                    onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                        if(event.key === 'Enter'){
                            buscarSalida(odc);
                        }
                    }}
                />
            </Stack>
            <Stack direction='row' style={styles.rowFields}>
                <p>{isEnglish()? 'Date' : 'Fecha'}:</p>
                <SmallOutlinedCustomTextInput 
                    disabled={true}
                    value={entrada.fecha}
                />
            </Stack>
            <Stack direction='row' style={styles.rowFields}>
                <p>{isEnglish()? 'Output' : 'Salida'}:</p>
                <CustomSelect 
                    idLabelSelect='Salida'
                    idSelect='Salida'
                    label='' 
                    value={salida.id} 
                    onChangeSelection={(event: SelectChangeEvent) => {
                        setSalida(salidas.find((salida : CatalogoType) => salida.id === event.target.value)?? {id: '', description: ''})
                    }}
                    variant='outlined'
                    width="100%"
                >
                    {
                        salidas.map((salida : any) => <MenuItem key={salida.id} value={salida.id}>{salida.description}</MenuItem>)
                    }
                </CustomSelect>
            </Stack>
            <Stack direction='row' style={styles.rowFields}>
                <p>{isEnglish()? 'Label' : 'Etiqueta'}:</p>
                <SmallOutlinedCustomTextInput
                    disabled={false}
                    value={etiqueta}
                    onChangeText={(event : React.ChangeEvent<HTMLInputElement>) => setEtiqueta(event.target.value) }
                    onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                        if(event.key === 'Enter'){
                            AgregarEscaneo(etiqueta);
                        }
                    }}
                />
            </Stack>
            <CustomButton
                variant='contained'
                style={{
                    width:'10%', 
                    minWidth:'20px',
                    maxWidth:'40px', 
                    alignSelf: 'flex-end',
                    margin:'2px 5px'
                }}
                onClick={ClearScreen}
            >
                <DeleteForeverIcon style={{fontSize:'1.5em'}}/>
            </CustomButton>
            <Stack 
                direction='row' 
                style={{...styles.tableDisplay,...styles.tableHeader}} 
                sx={{
                    '& > p' : { 
                        color: '#fff', margin:'2px 5px'
                    }
                }}>
                <p>{isEnglish()? 'Entry':'Entrada'}</p>
                <p>{isEnglish()? 'Package' : 'Bulto'}</p>
                <p>{isEnglish()? 'Exist' : 'Existe'}</p>
                <p>{isEnglish()? 'Load' : 'Carga'}</p>
                <p>Scan</p>
            </Stack>
            <Awaiter showLoader={isLoading}>
                <div style={styles.tableBody}>
                {
                    entradas.map((detalleODC : DetalleOrdenCargaType) => {
                        return(
                            <div 
                                key={detalleODC.entradaNumero} 
                                style={{...styles.tableDisplay, height:'auto', fontSize:'0.9em', backgroundColor: rowColor[ComparerFunction(detalleODC.existencia, detalleODC.scanner).toString()].backgroundColor}} 
                                className='rowClientItem'>
                                <p>{detalleODC.documento}</p>
                                <p>{detalleODC.bulto}</p>
                                <p>{detalleODC.existencia}</p>
                                <p>{detalleODC.cantidad}</p>
                                <p>{detalleODC.scanner}</p>
                            </div>
                        )
                    })
                }
                </div>
            </Awaiter>
            <Stack 
                display='row' 
                style={{display: 'grid', gridTemplateColumns:'auto auto auto', gap:'10px', margin:'5px 5px'}}
            >
                <CustomButton
                    variant='contained'
                    onClick={() => setOpenDetailModal(true)}
                >
                    <SearchIcon style={{fontSize:'1.5em'}}/>
                </CustomButton>
                <CustomButton
                    variant='contained'
                    onClick={() => setOpenCameraModal(true)}
                >
                    <CameraAltIcon style={{fontSize:'1.5em'}}/>
                </CustomButton>
                <CustomButton
                    variant='contained'
                    onClick={Guardar}
                >
                    {isEnglish()? 'Save' : 'Guardar'}
                </CustomButton>
            </Stack>
            <Dialog
                open={openDetailModal}
                fullScreen
                style={{justifyContent: 'space-between'}}
            >
                <Stack direction='column' style={{margin: '10px', height:'100vh'}}>
                    <p>{isEnglish()? 'Client:' : 'Cliente:'}</p>
                    <SmallOutlinedCustomTextInput
                        value={entrada.cliente}
                        disabled={true}
                    />
                    <p>{isEnglish()? 'Carrier:' : 'Transportista:'}</p>
                    <SmallOutlinedCustomTextInput
                        value={entrada.transportista}
                        disabled={true}
                    />
                    <p>{isEnglish()? 'Shipment type:' : 'Tipo embarque:'}</p>
                    <SmallOutlinedCustomTextInput
                        value={entrada.tipoEmbarque}
                        disabled={true}
                    />
                    <p>{isEnglish()? 'Plates:' : 'Placas:'}</p>
                    <SmallOutlinedCustomTextInput
                        value={entrada.placas}
                        disabled={true}
                    />
                    <p>{isEnglish()? 'Document type:' : 'Tipo documento:'}</p>
                    <SmallOutlinedCustomTextInput
                        value={entrada.tipoDocumento}
                        disabled={true}
                    />
                    <p>{isEnglish()? 'Number:' : 'Número:'}</p>
                    <SmallOutlinedCustomTextInput
                        value={entrada.tipoDocNumero}
                        disabled={true}
                    />
                    <p>{isEnglish()? 'Pedimento:' : 'Pedimento:'}</p>
                    <SmallOutlinedCustomTextInput
                        value={entrada.pedimento}
                        disabled={true}
                    />
                    <p>{isEnglish()? 'Stamp:' : 'Sello:'}</p>
                    <SmallOutlinedCustomTextInput
                        value={entrada.sello}
                        disabled={true}
                    />
                    <p>{isEnglish()? 'Notes:' : 'Notas:'}</p>
                    <SmallOutlinedCustomTextInput
                        value={entrada.observaciones}
                        disabled={true}
                    />
                    <p>{isEnglish()? 'Reviewed by:' : 'Revisado por:'}</p>
                    <SmallOutlinedCustomTextInput
                        value={`${user.firstName} ${user.lastName}`}
                        disabled={true}
                    />
                    <p>{isEnglish()? 'Uploaded by:' : 'Cargado por:'}</p>
                    <SmallOutlinedCustomTextInput
                        value={`${user.firstName} ${user.lastName}`}
                        disabled={true}
                    />
                </Stack>
                <Stack direction='column' style={{margin: '10px'}}>
                    <div style={{display:'grid', gridTemplateColumns:'15% auto', alignItems:'center' , justifySelf: 'flex-end'}}>
                        <p>{isEnglish()? 'Pallet:' : 'Tarima:'}</p>
                        <SmallOutlinedCustomTextInput
                            onChangeText={(event: React.ChangeEvent<HTMLInputElement>) => setTarima(event.target.value.trim())}
                            onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                                if(!['0','1','2','3','4','5','6','7','8','9','Backspace','Enter'].includes(event.key)){
                                    event.preventDefault();
                                }
                            }}
                            value={tarima}
                            disabled={false}
                        />
                    </div>
                    <CustomButton
                        variant='contained'
                        onClick={() => setOpenDetailModal(false)}
                    >
                        {isEnglish()? 'Close' : 'Cerrar'}
                    </CustomButton>
                    </Stack>
            </Dialog>
            <Dialog
                open={openCameraModal}
                fullScreen
            >
                <Stack direction='column' style={{height:'80vh', width:'100vw'}}>
                    <Camera 
                        onUploadPhoto={(urlPhoto : string) => {

                        }}
                        isModal={true}
                        onClose={() => setOpenCameraModal(false)}
                        photoParameters={{
                            pantalla: 'OUT',
                            folio: odc,
                            entrada: ''
                        }}
                    />
                </Stack>
            </Dialog>
        </Stack>
    )
}

const styles : StyleObject = {
    rowFields : {
        display: 'grid', 
        gridTemplateColumns:'25% 75%', 
        alignItems:'center',
        margin: '0px 5px'
    },
    tableDisplay: {
        display: 'grid',
        gridTemplateColumns: '25% 25% 15% 20% 15%',
        height: '30px',
        alignItems: 'center'
    },
    tableHeader: {
        backgroundColor: Colors.primary,
        alignItems: 'center'
    },
    tableBody: {
        height: 'calc(100vh - 305px)',
        overflowY: 'auto'
    },
}

const rowColor : StyleObject = {
    '-1' : {
        backgroundColor: Colors.redError
    },
    '0': {
        backgroundColor:  Colors.greenSuccess
    },
    '1' : {
        backgroundColor: 'transparent'
    }
}

type ScannerStatusType = 'none' | 'completada' | 'procesando' | 'guardado';
const ScannerStatus : {[key : string] : ScannerStatusType} = Object.freeze({
    NONE : 'none',
    COMPLETADA : 'completada',
    PROCESANDO : 'procesando',
    GUARDADO : 'guardado'
});