import { api_url } from "Constants"
import AxiosRequest from "./AxiosRequest"

const apiUrl = api_url.WareHouse;
export const SearchEntry = (entrada : string) => {
    return AxiosRequest.get(`${apiUrl}Recibo/ObtenerEntrada?entrada=${entrada}`);
}

export const ValidateLabel = (etiqueta : string) => {
    return AxiosRequest.get(`${apiUrl}Recibo/ValidarEtiqueta?etiqueta=${etiqueta}`);
}

export const SaveDetail = (reciboDetalle : object) => {
    return AxiosRequest.post(`${apiUrl}Recibo/GuardarDetalle`, reciboDetalle, {
        headers: {
            'Content-Type' : 'application/json'
        }
    })
}

export const PrintLabel = ({impresora, idDetalle} : {impresora : number, idDetalle : number}) => {
    return AxiosRequest.get(`${apiUrl}Recibo/ImprimirEtiqueta?idDetalle=${idDetalle}&impresora=${impresora}`)
}

export const SaveDamageReport = (damage : object) => {
    return AxiosRequest.post(`${apiUrl}Recibo/GuardarReporteDano`, damage, {
        headers: {
            'Content-Type' : 'application/json'
        }
    })
}