import { InventoryType } from "models";

export class Inventory implements InventoryType {
    idInventoryDetails: number;
    folio: string;
    clientCode: string;
    clientName: string;
    entry: string;
    location: string;
    tipoBulto: string;
    bulto: string;
    exist: number;
    scanned: number;
    anterior: string;
    detalle: string;
    comment: string;
    
    constructor(){
        this.idInventoryDetails = 0;
        this.folio = '';
        this.clientCode = '';
        this.clientName = '';
        this.entry = '';
        this.location = '';
        this.tipoBulto = '';
        this.bulto = '';
        this.exist = 0;
        this.scanned = 0;
        this.anterior = '';
        this.detalle = '';
        this.comment = '';
    }
}