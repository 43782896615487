import AppStore from "redux/store";

export const isStringEmpty = (variable : string) => variable === '' || variable.length <= 0; 
export const isEnglish = () => {
    const language = AppStore.getState().language;
    return language === 'en';
};
export const exists = (obj : any) : boolean => obj !== null && obj !== undefined;
export const formatTime = ( date : Date) : string => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours < 10? `0${hours}` : hours}:${minutes < 10? `0${minutes}` : minutes}`;
} 