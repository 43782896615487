import { CatalogoType, DetalleOrdenCargaType, IEntrada, SalidaType } from "models/output.type"

export const adaptDetalleODC = (data : any) => {
    const adaptedDetalleODC : DetalleOrdenCargaType = {
        entradaNumero: data?.EntradaNumero?? '',
        documento: data?.Documento ?? '',
        bulto: data?.Bulto?? '',
        existencia: data?.Existencia?? 0,
        cantidad: data?.Cantidad?? 0,
        scanner: data?.Scanner?? 0,
        tipoBulto: data?.Tipobulto?? '',
        OrdenCarga: data?.OrdenCarga?? ''
    }

    return adaptedDetalleODC;
}

export const adaptEntrada = (data : any) : IEntrada => {
    const adaptedEntrada : IEntrada = {
        idEntrada: data?.idEntrada?? 0,
        documento: data?.Documento?? '',
        cliente: data?.Cliente?? '',
        nombreCliente: data?.NombreCliente?? '',
        fecha: data?.Fecha?? '',
        observaciones: data?.Observaciones?? '',
        pedimento: data?.Pedimento?? '',
        ocupado: data?.Ocupado?? false,
        placas: data?.Placas?? '',
        sello: data?.Sello?? '',
        tipoDocNumero: data?.TipoDocNumero?? '',
        idTipoDocumento: data?.idTipoDocumento?? '',
        tipoDocumento: data?.TipoDocumento?? '',
        tipoEmbarque: data?.TipoEmbarque?? '',
        idTransportista: data?.idTransportista?? '',
        transportista: data?.Transportista?? '',
        tarimas: data?.Tarimas?? '',
        elaboro: data?.Elaboro?? ''
    }

    return adaptedEntrada;
}

export const adaptSalida = (data : any) : SalidaType => {
    const adaptedSalida : SalidaType = {
        entrada : adaptEntrada(data?.Entrada),
        detalle : (data?.Detalle)? data.Detalle.map((detalle : any) => adaptDetalleODC(detalle)) : []
    }

    return adaptedSalida;
}

export const adaptCatalog = (data : any) : CatalogoType => {
    const adaptedCatalog : CatalogoType = {
        id: data?.Id?? '',
        description: data?.Description?? ''
    }

    return adaptedCatalog;
}