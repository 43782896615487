import { Dialog, Divider, MenuItem, SelectChangeEvent, Stack } from "@mui/material";
import { BasicSwalConfig, Colors, RouteConfigProps, RoutesConfig } from "Constants";
import { StyleObject } from "GlobalTypes";
import { AddIcon, PrintIcon } from "Iconos";
import { adaptDetalleEntradaODC, adaptEntrada, adaptPrinterType, adaptResponseJDAPI } from "adapters";
import { CustomButton, CustomSelect, CustomTextInput } from "components";
import { CatalogoType, Detalle, Entrada, IDetalle, IEntrada } from "models";
import { useContext, useEffect, useRef, useState } from "react";
import { CreateGroupLabel, GetPrinters, PrintDeliveryRelation, PrintMasterLabel, SaveGroupLabel, SearchODC } from "services";
import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";
import { exists, isEnglish, isStringEmpty } from "utilities/Generals";
import './MasterLabel.css';
import { NavigationContext } from "context/global";
import { useLocation } from "react-router-dom";
import AppStore from "redux/store";

const ShowAlert = ( title: string, message : string, icono : SweetAlertIcon = 'info') => {
    const customConfig : SweetAlertOptions = {
        icon: icono,
        title: isStringEmpty(title)? BasicSwalConfig.title : title,
        text: isStringEmpty(message)? BasicSwalConfig.text : message
    }
    
    Swal.fire({...BasicSwalConfig,...customConfig});
}

type PrinterType = 'Normal' | 'Zebra';
type PrintersType = {
    Normal: Array<CatalogoType>,
    Zebra: Array<CatalogoType>
}

type StatusODCType = 'none' | 'procesando' | 'guardado' | 'completado'; 
const StatusODC : {[key : string] : StatusODCType} = Object.freeze({
    NONE : 'none',
    COMPLETADA : 'completado',
    PROCESANDO : 'procesando',
    GUARDADO : 'guardado'
});

export type MasterLabelType = {
    NumeroCarga : string, 
    Cliente: string, 
    idUsuario: number
}

export type MasterLabelDetailType = {
    idEtiquetaGrupo: number,
    EtiquetaBulto: string,
    Entrada: string,
    idTipoBulto: number
}

type SoundType = 'E' | 'U' | 'S';

/**
 * 
 * @param leftValue 
 * @param rightValue 
 * @returns -1 si leftValue es menor que rightValue, 0 si son iguales ó 1 si leftValue es mayor que rigthValue
 */
const ComparerFunction = (leftValue: number, rightValue: number) : -1 | 0 | 1 => {
    let diff = leftValue - rightValue;
    return diff === 0? diff : (diff > 0? 1 : -1);
}

export default function MasterLabel(){
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ODC, setODC] = useState<string>('');
    const [label, setLabel] = useState<string>('');
    const [printerType, setPrinterType] = useState<PrinterType>('Normal');
    const [statusODC, setStatusODC] = useState<StatusODCType>('none');
    const [entrada, setEntrada] = useState<IEntrada>(new Entrada());
    const [entradaDetalleList, setEntradaDetalleList] = useState<Array<IDetalle>>([]);
    const [masterLabels, setMasterLabels] = useState<Array<CatalogoType>>([]);
    const [masterLabel, setMasterLabel] = useState<string>('');
    const [scanned, setScanned] = useState<Array<MasterLabelDetailType>>([]);
    const [sound] = useState<string>(require('../../../assets/sounds/Success.mp3'));
    const audioPlayer = useRef<HTMLAudioElement>(null);
    const navigationContext = useContext(NavigationContext);
    const location = useLocation().pathname;
    const user = AppStore.getState().user;
    const [openPrintingModal, setOpenPrintingModal] = useState<boolean>(false);
    const [selectedEntradaDetalle, setSelectedEntradaDetalle] = useState<IDetalle>(new Detalle())

    useEffect(() => {
        const routeByLocation = Object.entries(RoutesConfig.private).find(([, route] : [string, RouteConfigProps]) =>  location === `/Global/${route.path}${location.endsWith('/')? '/' : ''}`);
        navigationContext.onChangePage((isEnglish()? routeByLocation?.[1].titleEn : routeByLocation?.[1].title)?? '');
    }, []);

    const playSound = async (_status : SoundType) => {
        const sounds = [
            { 
                status: 'E',
                sound: require('../../../assets/sounds/Wrong.mp3')
            },
            { 
                status: 'U',
                sound: require('../../../assets/sounds/Urgent.mp3')
            },
            { 
                status: 'S',
                sound: require('../../../assets/sounds/Success.mp3')
            }
        ];
        const soundPlayer = audioPlayer.current;
        const soundToPlay = sounds.find(({status}) => status === _status)?.sound;
        if(soundPlayer){
            soundPlayer.src = soundToPlay;
            soundPlayer.play();
        }
    }

    const SearchODCLabel = (ODC : string) => {
        setStatusODC('none');
        if(!isStringEmpty(ODC)){
            setIsLoading(true);
            SearchODC(ODC).then(response => {
                setIsLoading(false);
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    const { messageEn, messageEs } = responseAPI;
                    ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                    setODC('');
                    setEntradaDetalleList([]);
                    return;
                }
                if(responseAPI.data){
                    const API_Data = responseAPI.data as {Entrada: IEntrada, Detalle: Array<IDetalle>};
                    setEntrada(adaptEntrada(API_Data.Entrada));
                    const detalle = API_Data.Detalle.map((_detalle : IDetalle) => adaptDetalleEntradaODC(_detalle));
                    setEntradaDetalleList(detalle);
                }else{
                    setEntradaDetalleList([]);
                }
            })
            .catch(error => {
                setIsLoading(false);
                setEntradaDetalleList([]);
                ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get load order information' : 'Ocurrió un problema al intentar obtener información de la orden de carga'}:\n ${error.message}`, 'error');
            })
        }else{
            ShowAlert('Error', isEnglish()? 'Load Order not valid' : 'Orden de Carga no válida', 'error');
        }
    }

    const CreateGroup_Label = (ODC : string) => {
        if(!isStringEmpty(ODC)){
            setIsLoading(true);
            CreateGroupLabel().then(response => {
                setIsLoading(false);
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    const { messageEn, messageEs } = responseAPI;
                    ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                    return;
                }
                const label = response.data.Object?? '';
                if(!isStringEmpty(label)){
                    const _masterLabel : CatalogoType = {id : label, description: label};
                    setMasterLabels([...masterLabels, _masterLabel]);
                }
            })
            .catch(error => {
                setIsLoading(false);
                ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get master load order information' : 'Ocurrió un problema al intentar obtener información de la Orden de Carga Maestra'}:\n ${error.message}`, 'error');
            })
        }else{
            ShowAlert('Error', isEnglish()? 'Load Order not valid' : 'Orden de Carga no válida', 'error');
        }
    }

    const AddScan = (label : string) => {
        if(masterLabels.length <= 0){
            ShowAlert('Error', isEnglish()? 'A Master Label has not been created' : 'No se ha creado una Etiqueta Maestra.', 'error');
            setLabel('');
            return;
        }
        if(entradaDetalleList.length <= 0){
            ShowErrorMessage(isEnglish()? 'There is no load order information to scan' : 'No hay información de orden de carga a escanear.');
            setLabel('');
            return;
        }

        if(!isStringEmpty(label)){
            if(!isStringEmpty(masterLabel)){
                const splittedLabel = label.split('-');
                const [numeroEntrada, tipoBulto] = splittedLabel;
                if(!isStringEmpty(numeroEntrada) && !isStringEmpty(tipoBulto)){
                    if(ValidateCode(numeroEntrada, +(tipoBulto))){
                        if(!ValidateScanned(label, numeroEntrada, +(tipoBulto))){
                            AddCounter(numeroEntrada, +(tipoBulto));
                            playSound('S');
                        }else{
                            ShowErrorMessage(isEnglish()? 'The package was previously scanned.' : 'El bulto fue escaneado previamente');
                        }
                    }
                }else{
                    ShowErrorMessage(isEnglish()? 'The label is incomplete' : 'La etiqueta esta incompleta');
                }
            }else{
                ShowErrorMessage(isEnglish()? 'A Master Label must be selected' : 'Se debe tener seleccionada una Etiqueta Maestra');
            }
        }
        setLabel('');
    }

    const ValidateCode = (entrada : string, bulto : number) => {
        const result = entradaDetalleList.find((entradaDetalle : IDetalle) => entradaDetalle.entradaNumero == entrada && entradaDetalle.tipoBulto == bulto)
        let isValid = false;
        if(result){
            if(result.existencia === result.scanner){
                ShowErrorMessage(isEnglish()? 'All packages have been scanned for this entry' : 'Se han escaneados todos los paquetes para esta entrada');
            }else if(result.existencia > (result.scanner?? 0)){
                isValid = true;
            }else{
                ShowErrorMessage(isEnglish()? 'The package does not belong to the order' : 'El bulto no pertenece a la orden');
            }
        }else{
            ShowErrorMessage(isEnglish()? 'The package does not belong to the order' : 'El bulto no pertenece a la orden');
        }
        return isValid;
    }

    const ValidateScanned = (etiqueta : string, entrada : string, tipoBulto : number) => {
        const idGroupLabel = masterLabel.replaceAll('M','');
        const labelHasBeenScanned = exists(scanned.filter(({ EtiquetaBulto} : MasterLabelDetailType) => !isStringEmpty(EtiquetaBulto)).find(({ EtiquetaBulto} : MasterLabelDetailType) => EtiquetaBulto == etiqueta));
        if(!labelHasBeenScanned){
            const result = entradaDetalleList.find((entradaDetalle : IDetalle) => entradaDetalle.tipoBulto == tipoBulto && entradaDetalle.entradaNumero == entrada);
            const newScan : MasterLabelDetailType = { idEtiquetaGrupo: +(idGroupLabel), EtiquetaBulto: etiqueta, Entrada: (result?.documento?? ''), idTipoBulto: tipoBulto };
            setScanned([...scanned, newScan]);
        }
        return labelHasBeenScanned;
    }

    const AddCounter = (entrada : string, tipoBulto : number) => {
        const result = entradaDetalleList.find((entradaDetalle : IDetalle) => entradaDetalle.entradaNumero == entrada && entradaDetalle.tipoBulto == tipoBulto);
        if(result){
            result.scanner = (result.scanner?? 0) + 1;
        }

        const resultCompletada = entradaDetalleList.find((entradaDetalle : IDetalle) => (entradaDetalle.scanner?? 0) === 0 || (entradaDetalle.scanner?? 0) < (entradaDetalle.cantidad?? 0));
        if(!resultCompletada){
            setStatusODC(StatusODC.COMPLETADA);
            ShowAlert(isEnglish()? 'Warning' : 'Aviso', isEnglish()? 'All packages have been scanned' : 'Se han escaneado todos los bultos.', 'info')
        }
    }

    const Save = () => {
        const etiquetaMaestra = { NumeroCarga : ODC, Cliente: entrada.cliente, idUsuario: user.idUsuarioCOBO };
        const etiquetaMaestraDetalle = scanned;

        if(entradaDetalleList.length === 0){
            ShowAlert('Error', isEnglish()? 'There is no detail to evaluate' : 'No hay detalle para evaluar', 'error');
            return;
        }

        if(statusODC !== StatusODC.COMPLETADA){
            ShowAlert('Error', isEnglish()? 'Some packages are still pending' : 'Algunos paquetes aun estan pendientes', 'error');
        }else if(statusODC === StatusODC.GUARDADO){
            ShowAlert(isEnglish()? 'Warning' : 'Aviso', isEnglish()? 'The information has already been saved' : 'Ya se ha guardado la informacion.', 'warning');
        }else{
            setIsLoading(true);
            SaveGroupLabel({EtiquetaMaestra: etiquetaMaestra, EtiquetaMaestraDetalle: etiquetaMaestraDetalle}).then(response => {
                setIsLoading(false);
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    const {messageEn, messageEs} = responseAPI;
                    ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                    return;
                }else{
                    ShowAlert(isEnglish()? 'Success' : 'Éxito', isEnglish()? 'It was successfully saved' :'Se ha guardado correctamente.', 'success');
                    setStatusODC(StatusODC.GUARDADO);
                }
            })
            .catch(error => {
                setIsLoading(false);
                ShowAlert('Critical Error', `${isEnglish()? 'There was a problem saving the information' : 'Ocurrio un problema al guardar la informacion'}:\n ${error.message}`, 'error');
            });
        }
    }

    const ShowErrorMessage = ( message : string) => {
        playSound('E');
        const customConfig : SweetAlertOptions = {
            text: isStringEmpty(message)? BasicSwalConfig.text : message,
            showCancelButton: true
        }
        
        Swal.fire({...BasicSwalConfig,...customConfig}).then(result => {
            if(!result.isConfirmed){
                ShowErrorMessage(message);
            }
        });
    }

    return(
        <Stack direction='column' divider={<Divider />} spacing={1}>
            <Stack direction='row' style={{display: 'grid', gridTemplateColumns: '30% 30px 30% auto', margin:'0px 10px', gap:'5px'}}>
                <audio src={sound} style={{display:'none'}} ref={audioPlayer}></audio>
                <CustomTextInput 
                    variant='standard'
                    label='ODC'
                    size='small'
                    sx={{
                        minWidth: '100px',
                        alignSelf:'flex-end'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => setODC(event.target.value)}
                    onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                        if(event.key === 'Enter' || event.keyCode == 9){
                            SearchODCLabel(ODC);
                        }
                    }}
                    value={ODC}
                />
                <CustomButton
                    style={{
                        width: '100%',
                        minWidth: '20px',
                        maxWidth: '40px',
                        height:'40px',
                        alignSelf:'center'
                    }}
                    variant='contained'
                    onClick={() => CreateGroup_Label(ODC)}
                    disabled={false}
                >
                    <AddIcon style={{fontSize:'1.2em'}} />
                </CustomButton>
                <CustomSelect
                    idLabelSelect='MasterLabel'
                    idSelect='MasterLabel'
                    value={masterLabel}
                    label='Master'
                    width='100%'
                    disabled={isLoading}
                    onChangeSelection={(event : SelectChangeEvent) => setMasterLabel(event.target.value)}
                >
                    {
                        masterLabels.map((groupLabel : CatalogoType) => <MenuItem key={groupLabel.id} value={groupLabel.id}>{groupLabel.description}</MenuItem>)
                    }
                </CustomSelect>
                <CustomButton
                    style={{
                        width: '100%',
                        minWidth: '100px',
                        maxWidth: '200px',
                        height:'40px',
                        alignSelf:'center',
                        justifySelf:'center'
                    }}
                    variant='contained'
                    onClick={() => {
                        setPrinterType('Zebra');
                        setOpenPrintingModal(true);
                    }}
                    disabled={false}
                >
                    {isEnglish()? 'Print' : 'Imprimir'}
                </CustomButton>
            </Stack>
            <div style={{margin:'0px 10px'}}>
                <CustomTextInput 
                    variant='standard'
                    label={isEnglish()? 'Label' : 'Etiqueta'}
                    size='small'
                    sx={{
                        width: '100%',
                        minWidth: '100px'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => setLabel(event.target.value)}
                    onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                        if(event.key === 'Enter'){
                            AddScan(label);
                        }
                    }}
                    value={label}
                />
            </div>
            <Stack 
                direction='row' 
                style={{...styles.tableDisplay,...styles.tableHeader}} 
                sx={{
                    '& > p' : { 
                        color: '#fff', margin:'2px 5px', textAlign: 'center'
                    }
                }}
            >
                <p>Id</p>
                <p>{isEnglish()? 'Entry' : 'Entrada'}</p>
                <p>{isEnglish()? 'Package' : 'Bulto'}</p>
                <p>{isEnglish()? 'Exist' : 'Existe'}</p>
                <p>Scan</p>
            </Stack>
            <Stack direction='column' style={styles.tableBody}>
                {
                    entradaDetalleList.map((_detalle : IDetalle) => 
                        <div 
                            key={_detalle.idDetalle} 
                            style={{
                                ...styles.tableDisplay, 
                                height:'auto', 
                                fontSize:'0.9em', 
                                backgroundColor: rowColor[_detalle.idDetalle === selectedEntradaDetalle.idDetalle? 'selected' : ComparerFunction(_detalle.existencia, _detalle.scanner?? 0).toString()].backgroundColor
                            }} 
                            className='rowDetalleItem'
                            onClick={() => setSelectedEntradaDetalle(_detalle)}
                        >
                            <p>{_detalle.idDetalle}</p>
                            <p>{_detalle.documento}</p>
                            <p>{_detalle.bulto}</p>
                            <p>{_detalle.existencia}</p>
                            <p>{_detalle.scanner}</p>
                        </div>
                    )
                }
            </Stack>
            <Stack direction='column'>
                <div 
                    style={{alignSelf:'flex-end', margin:'0px 5px', color: Colors.primary}}
                    onClick={() => {
                        setPrinterType('Normal');
                        setOpenPrintingModal(true);
                    }}
                >
                    <PrintIcon style={{fontSize:'2em'}}/>
                </div>
                <CustomButton
                    style={{
                        width: '100%',
                        minWidth: '100px',
                        maxWidth: '200px',
                        alignSelf:'center',
                        justifySelf:'center'
                    }}
                    disabled={!(statusODC == StatusODC.COMPLETADA) || entradaDetalleList.length === 0}
                    onClick={Save}
                >
                    { isEnglish()? 'Completed' : 'Completado' }
                </CustomButton>
            </Stack>
            <Dialog
                open={openPrintingModal}
                fullWidth
                maxWidth='md'
            >
                <PrintingModal 
                    printerType={printerType}
                    onClose={() => setOpenPrintingModal(false)}
                    onLoad={(_isLoading : boolean) => setIsLoading(_isLoading)}
                    printerParams={printerType === 'Zebra'? {
                            odc : ODC,
                            master: masterLabel
                        } : {
                            odc : ODC
                        }
                    }
                />
            </Dialog>
        </Stack>
    )
}

type PrintingModalProps = {
    printerType : PrinterType,
    onClose: () => void,
    onLoad: (isLoading : boolean) => void,
    printerParams : {
        odc : string, 
        master?: string
    }
}

const PrintingModal = ({printerType, onClose, onLoad, printerParams} : PrintingModalProps) => {
    const [printer, setPrinter] = useState<number>(0);
    const [printers, setPrinters] = useState<Array<CatalogoType>>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        GetPrinters(printerType as string).then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const customConfig : SweetAlertOptions = {
                    icon: 'error',
                    text: isEnglish()? responseAPI.messageEn : responseAPI.messageEs
                }
                
                Swal.fire({...BasicSwalConfig,...customConfig});
            }else{
                const adaptedPrinters = ((responseAPI.data as Array<CatalogoType>)?? []).map((printer : any) => adaptPrinterType(printer));
                setPrinters(adaptedPrinters);
            }
        })
    },[]);

    const changeLoadingState = (_isLoading : boolean) => {
        setIsLoading(_isLoading);
        onLoad(_isLoading);
    }

    const Print = () => {
        const validateMaster = printerParams.master? () => !isStringEmpty(printerParams.master?? '') : () => true;
        if(printer > 0 && !isStringEmpty(printerParams.odc) && validateMaster()) {
            changeLoadingState(true);
            const printParams = {
                master : printerParams.master?? '',
                impresora : printer,
                odc: printerParams.odc
            };
            const PrintMethod = printerType === 'Zebra'? PrintMasterLabel(printParams) : PrintDeliveryRelation(printParams); 
            PrintMethod.then(response => {
                changeLoadingState(false);
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    ShowAlert('Error', isEnglish()? responseAPI.messageEn : responseAPI.messageEs);
                }else{
                    const customConfig : SweetAlertOptions = {
                        icon: 'info',
                        title: isEnglish()? 'Success' : 'Éxito',
                        text: isEnglish()? responseAPI.messageEn : responseAPI.messageEs
                    }
                    
                    Swal.fire({...BasicSwalConfig,...customConfig}).then(() => {
                        onClose();
                    });
                }
            })
            .catch(error => {
                changeLoadingState(false);
                ShowAlert('Critical Error', `${isEnglish()? 'There is no a valid guide id' : 'No se cuenta con un id de guía válido'}: ${error}`, 'error');
            })
        }else{
            ShowAlert('Error', isEnglish()? 'No printer selected' : 'No se ha seleccionado impresora', 'error');
        }
    }

    return(
        <Stack direction='column' style={{display:'flex', gap:'10px', margin:'20px'}} spacing={1}>
            <CustomSelect
                idLabelSelect='Impresora'
                idSelect='Impresora'
                value={printer}
                label='Impresora'
                width='45%'
                disabled={isLoading}
                onChangeSelection={(event : SelectChangeEvent) => setPrinter(+(event.target.value))}
            >
                {
                    printers.map((printer : CatalogoType) => <MenuItem value={printer.id} key={printer.id}>{printer.description}</MenuItem>)
                }
            </CustomSelect>
            <Stack direction='row' style={{display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
                <CustomButton
                    style={{
                        width: '45%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center'
                    }}
                    variant='contained'
                    onClick={Print}
                    disabled={printer <= 0}
                >
                    {isEnglish()? 'Print' : 'Imprimir'}
                </CustomButton>
                <CustomButton
                    style={{
                        width: '45%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center',
                        backgroundColor: '#B9B9B9'
                    }}
                    variant='contained'
                    onClick={onClose}
                >
                    { isEnglish()? 'Cancel' : 'Cancelar'}
                </CustomButton>
            </Stack>
        </Stack>
    )
}

const rowColor : StyleObject = {
    '-1' : {
        backgroundColor: Colors.redError
    },
    '0': {
        backgroundColor:  Colors.greenSuccess
    },
    '1' : {
        backgroundColor: 'transparent'
    },
    'selected' : {
        backgroundColor : '#E5E5E5'
    }
}

const styles : StyleObject = {
    tableDisplay: {
        display: 'grid',
        gridTemplateColumns: '20% 25% 25% 15% auto',
        height: '30px',
        alignItems: 'center'
    },
    tableHeader: {
        backgroundColor: Colors.primary,
        alignItems: 'center'
    },
    tableBody: {
        height: 'calc(100vh - 320px)', 
        overflowY: 'auto'
    }
}