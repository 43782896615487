import { Select, FormControl, InputLabel, SelectChangeEvent, FormControlLabel } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Colors } from 'Constants';
import { Dayjs } from 'dayjs';
import { isStringEmpty } from 'utilities/Generals';
import { CustomCheckBox, CustomTextInput } from './styledComponents';

type CustomSelectProps = {
    idLabelSelect: string,
    idSelect: string,
    label: string, 
    value: string | number,
    width?: string,
    disabled?: boolean,
    onChangeSelection: (event : SelectChangeEvent) => void,
    children: JSX.Element | Array<JSX.Element>,
    variant?: 'standard' | 'outlined' | 'filled' | undefined
}

const CustomSelect = (props : CustomSelectProps) => {
    return(
        <FormControl 
            variant={props.variant?? 'standard'} 
            sx={{
                m: 1, 
                minWidth: props.width?? '100%',
                margin: props.variant === 'standard'? '8px' :'2px 0px'
            }}>
            { !isStringEmpty(props.label) && <InputLabel id={props.idLabelSelect} style={{color: Colors.primary, fontSize:'1.1em'}}>{props.label}</InputLabel>}
            <Select
                labelId={props.idLabelSelect}
                id={props.idSelect} 
                value={props.value===0? '' : props.value.toString()}
                label={props.label}
                onChange={props.onChangeSelection}
                sx={{
                    '&:after':{
                        borderBottomColor: Colors.primary
                    },
                    '& .MuiInputBase-input' : {
                        padding: props.variant === 'standard'? '8px 14px' : '4px 8px'
                    },
                    '&.MuiInputBase-root': {
                        '& fieldset' : { 
                            borderColor: Colors.primary
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: Colors.primary
                        }
                    }
                }}
                disabled={props.disabled?? false}
            >
                { props.children }
            </Select>
        </FormControl>
    )
}

type CustomDatePickerProps = {
    value : Dayjs | null,
    onChangeDate: (date : Dayjs | null) => void
}

const CustomDatePicker = (props : CustomDatePickerProps) => {
    return( 
        <DesktopDatePicker 
            onChange={props.onChangeDate}
            value={props.value}
            sx={{
                height: '30px',
                '& .MuiInputBase-input' : {
                    padding: '8px 14px'
                },
                '& .MuiButtonBase-root' : {
                    backgroundColor: Colors.primary,
                    color: '#fff',
                    borderRadius: '5px',
                    padding: '5px'
                }
            }}
        />
    )
}

type SmallOutlinedCustomTextInputProps = {
    value: string | number,
    disabled: boolean,
    onChangeText? : (event : React.ChangeEvent<HTMLInputElement>) => void,
    onKeyDown? : (event : React.KeyboardEvent<HTMLInputElement>) => void
}

const SmallOutlinedCustomTextInput = (props : SmallOutlinedCustomTextInputProps) => {
    return(
        <CustomTextInput 
            variant='outlined'
            label=''
            size='small'
            style={{width:'100%', margin: '2px 0px'}}
            sx={{
                '& .MuiInputBase-root input' : {
                    padding: '2px 10px'
                }
            }}
            value={props.value}
            disabled={props.disabled}
            onChange={props.onChangeText}
            onKeyDown={props.onKeyDown}
        />
    )
}

type CustomLabelCheckBoxProps = {
    checked: boolean,
    onCheckedChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    label: string
}

const CustomLabelCheckBox = (props : CustomLabelCheckBoxProps) => {
    return(
        <FormControlLabel 
            control={
                <CustomCheckBox
                    checked={props.checked}
                    onChange={props.onCheckedChange} 
                />
            } 
            label={props.label}
            style={{margin:'0px'}}
            sx={{
                '&.MuiFormControlLabel-root .MuiFormControlLabel-label':{
                    color: Colors.primary
                }
            }}     
        />
    )
}

export {
    CustomSelect,
    CustomDatePicker,
    SmallOutlinedCustomTextInput,
    CustomLabelCheckBox
}