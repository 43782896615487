import { Loader } from '../components';

type AwaiterProps = {
    showLoader: boolean,
    children: JSX.Element | Array<JSX.Element>
}

const Awaiter = ({ showLoader, children } : AwaiterProps) => {
    return(
        <>
        { showLoader && <Loader/> }
        { children }
        </>
    )
};

export default Awaiter;