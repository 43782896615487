import { api_url } from "Constants"
import AxiosRequest from "./AxiosRequest"

const apiUrl = api_url.WareHouse;

export const SearchSerial = (guia : string, idUsuarioCobo: number) => {
    return AxiosRequest.get(`${apiUrl}Guide/BuscarSerial?codigo=${guia}&idUsuarioCobo=${idUsuarioCobo}`);
}

export const SaveGuides = (params : {}) => {
    return AxiosRequest.post(`${apiUrl}Guide/SaveGuides`, params, {
        headers : {
            'Content-Type' : 'application/json'
        }
    })
}

export const PrintGuides = (idGuia : number, impresora : number) => {
    return AxiosRequest.get(`${apiUrl}Guide/ImprimirGuias?idGuia=${idGuia}&impresora=${impresora}`);
}