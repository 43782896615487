import { Dialog, Divider, Stack } from "@mui/material";
import { BasicSwalConfig, Colors, RouteConfigProps, RoutesConfig } from "Constants";
import { StyleObject } from "GlobalTypes";
import { SearchIcon } from "Iconos";
import { adaptInventory, adaptLocation, adaptResponseJDAPI } from "adapters";
import { CustomButton, CustomTextInput } from "components";
import { NavigationContext } from "context/global";
import Awaiter from "hooks/Awaiter";
import { LocationType, Inventory, InventoryType } from "models";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GetLocations, GetEntryInfo, SaveChangeLocation } from "services";
import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";
import { isEnglish, isStringEmpty } from "utilities/Generals";

const ShowAlert = ( title: string, message : string, icono : SweetAlertIcon = 'info') => {
    const customConfig : SweetAlertOptions = {
        icon: icono,
        title: isStringEmpty(title)? BasicSwalConfig.title : title,
        text: isStringEmpty(message)? BasicSwalConfig.text : message
    }
    
    Swal.fire({...BasicSwalConfig,...customConfig});
}

export default function ChangeLocation(){
    const navigationContext = useContext(NavigationContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [entry, setEntry] =  useState<InventoryType>(new Inventory());
    const [entries, setEntries] = useState<Array<InventoryType>>([]);
    const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
    const [location, setLocation] = useState<LocationType>({id:-1, description:''});
    const [locations, setLocations] = useState<Array<LocationType>>([]);
    const locationPage = useLocation().pathname;

    useEffect(() => {
        const routeByLocation = Object.entries(RoutesConfig.private).find(([, route] : [string, RouteConfigProps]) =>  locationPage === `/Global/${route.path}${locationPage.endsWith('/')? '/' : ''}`);
        navigationContext.onChangePage((isEnglish()? routeByLocation?.[1].titleEn : routeByLocation?.[1].title)?? '');
        
        GetLocations().then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const { messageEn, messageEs } = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs);
            }else{
                if(responseAPI.data){
                    const adaptedLocations = (responseAPI.data as Array<LocationType>)
                        .map((location : LocationType) => adaptLocation(location))
                        .filter((location : LocationType) => !isStringEmpty(location.description));
                    setLocations(adaptedLocations);
                }
            }
        })
        .catch(error => {
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get locations' : 'Ocurrió un problema al intentar obtener ubicaciones'}:\n ${error.message}`, 'error');
        })
    },[])

    const AddEntry = (entry : InventoryType) => {
        const regexEntry = /^[\d]+[-][\d]+[-][\d]+$/g;
        if(isStringEmpty(entry.entry)){
            ShowAlert(isEnglish()? 'Warning' : 'Advertencia', isEnglish()? 'Entry cannot be empty' : 'Entrada no puede estar vacía', 'warning');
            return;
        }else if(!regexEntry.test(entry.entry)){
            ShowAlert(isEnglish()? 'Warning' : 'Advertencia', isEnglish()? 'Entry is not in the correct format.' : 'Entrada no se encuentra en el formato correcto', 'warning');
            return;
        }

        if(entries.find((_entry : any) => _entry.entry === entry)){
            ShowAlert(isEnglish()? 'Warning' : 'Advertencia', isEnglish()? 'Entry is already in the grid' : 'Entrada ya se encuentra en la tabla', 'warning');
            return;
        }
        setIsLoading(true);
        GetEntryInfo(entry.entry).then(response => {
            setIsLoading(false);
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const { messageEn, messageEs } = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
            }else{
                if(responseAPI.data){
                    if((responseAPI.data as Array<any>).length > 0){
                        const newEntry = adaptInventory((responseAPI.data as Array<any>)[0]);
                        setEntries([...entries, newEntry]);
                        setEntry(new Inventory());
                    }
                }
            }
        })
        .catch(error => {
            setIsLoading(false);
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get entry information' : 'Ocurrió un problema al intentar obtener información de la entrada'}\n${error}`, 'error');
        })
    }

    const Save = () => {
        if(location.id < 0){
            ShowAlert(isEnglish()? 'Warning' : 'Advertencia', isEnglish()? 'You have not entered the new location' : 'No ha ingresado la nueva ubicación', 'warning');
            return;
        }
        if(entries.length === 0){
            ShowAlert(isEnglish()? 'Warning' : 'Advertencia', isEnglish()? 'You have not entered any entry' : 'No ha ingresado ninguna entrada', 'warning');
            return;
        }
        setIsLoading(true);
        SaveChangeLocation(location.id, entries).then(response => {
            setIsLoading(false);
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const { messageEn, messageEs } = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
            }else{
                setEntries([]);
                setEntry(new Inventory());
                setLocation({id: -1, description: ''});
                ShowAlert(isEnglish()? 'Success' : 'Éxito', isEnglish()? 'The location has been changed correctly' : 'Se ha cambiado la ubicacion correctamente', 'success');
            }
        })
        .catch(error => {
            setIsLoading(false);
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to edit location' : 'Ocurrió un problema al intentar editar ubicación'}\n${error}`, 'error');
        })
    }

    const SearchLocation = (locationDescription : string) => {
        const matchedLocation = locations.find((location : LocationType) => location.description === locationDescription);
        if(matchedLocation){
            setLocation(matchedLocation);
            ShowAlert(isEnglish()? 'Success' : 'Éxito', isEnglish()? 'The location entered has been established as the destination location' : 'La ubicación ingresada ha sido establecida como la ubicación destino', 'success');
        }else{
            ShowAlert('Error', isEnglish()? 'The location entered is not a valid location' : 'La ubicación ingresada no es una ubicación válida', 'error');
            setLocation({id: -1, description: ''});
        }
    } 

    return(
        <Awaiter showLoader={isLoading}>
            <Stack direction='column' style={{width:'100%', height: '100%'}} divider={<Divider/>}>
                <Stack direction='column'>
                    <CustomTextInput 
                        variant='standard'
                        label={isEnglish()? 'Entry' : 'Entrada'}
                        size='small'
                        sx={{
                            width: 'calc(100% - 20px)',
                            minWidth: '150px',
                            margin: '10px'
                        }}
                        onChange={(event : React.ChangeEvent<HTMLInputElement>) => setEntry({...entry, entry : event.target.value.trim()})}
                        onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                            if(event.key === 'Enter'){
                                AddEntry(entry);
                            }
                        }}
                        value={entry.entry}
                    />
                    <Stack 
                        direction='row' 
                        style={{...styles.tableDisplay,...styles.tableHeader}} 
                        sx={{
                            '& > p' : { 
                                color: '#fff', margin:'2px 5px'
                            }
                        }}
                    >
                        <p>{isEnglish()? 'Entry' : 'Entrada'}</p>
                        <p>{isEnglish()? 'Qty' : 'Cantidad'}</p>
                        <p>{isEnglish()? 'Location' : 'Ubicacion'}</p>
                    </Stack>
                </Stack>
                <Stack direction='column' style={styles.tableBody}>
                    {
                        entries.map((entry : InventoryType) => {
                            return(
                                <div key={entry.clientCode} style={{...styles.tableDisplay, height:'auto'}} className='rowClientItem'>
                                    <p>{entry.entry}</p>
                                    <p>{entry.exist}</p>
                                    <p>{entry.location}</p>
                                </div>
                            )
                        })
                    }
                </Stack>
                <Stack direction='column'>
                    <Stack direction='row' style={{justifyContent:'space-between', alignItems:'center', height:'64px', margin: '0px 10px'}}>
                        <CustomTextInput 
                            variant='standard'
                            label={isEnglish()? 'Scan Location' : 'Escanear Ubicación'}
                            size='small'
                            sx={{
                                width: 'calc(100% - 20px)',
                                minWidth: '150px',
                                margin: '10px'
                            }}
                            onChange={(event : React.ChangeEvent<HTMLInputElement>) => {
                                const self = event.target;
                                setLocation({...location, description: self.value})
                            }}
                            onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                                if(event.key === 'Enter'){
                                    SearchLocation(location.description);
                                }
                            }}
                            value={location.description}
                        />
                        <div onClick={() => setOpenLocationModal(true)}>
                            <SearchIcon style={{fontSize: '2em', color: Colors.primary}}/>
                        </div>
                    </Stack>
                    <CustomButton
                        style={{
                            width: '50%',
                            minWidth: '150px',
                            maxWidth: '300px',
                            alignSelf: 'center'
                        }}
                        variant='contained'
                        onClick={Save}
                        disabled={entries.length === 0}
                    >
                        { isEnglish()? 'Save' : 'Guardar' }
                    </CustomButton>
                </Stack>
                <Dialog
                    open={openLocationModal}
                    fullWidth
                    maxWidth='md'
                >
                    <LocationModal 
                        locations={locations}
                        onClose={() => setOpenLocationModal(false)}
                        onSelectLocation={(location : LocationType) => setLocation(location)}
                    />
                </Dialog>
            </Stack>
        </Awaiter>
    )
}

type LocationModalProps = {
    locations: Array<LocationType>,
    onClose: () => void,
    onSelectLocation: (location : LocationType) => void,
}

const LocationModal = ({locations, onClose, onSelectLocation} : LocationModalProps) => {
    const [locationSearcParam, setLocationSearchParam] = useState<string>('');
    const [filteredLocations, setFilteredLocatios] = useState<Array<LocationType>>(locations);

    const filterLocationsByDescription = (locationDescription : string) => {
        if(!isStringEmpty(locationDescription)){
            setFilteredLocatios(locations.filter((location : LocationType) => location.description.includes(locationDescription)));
        }else{
            setFilteredLocatios(locations);
        }
    }

    return(
        <Stack direction='column' style={{display:'flex', alignItems:'flex-start', gap:'10px', margin:'20px'}}>
            <CustomTextInput 
                variant='standard'
                label={isEnglish()? 'Search Location' : 'Buscar Ubicación'}
                size='small'
                sx={{
                    width: 'calc(100% - 20px)',
                    minWidth: '150px',
                    margin: '10px'
                }}
                onChange={(event : React.ChangeEvent<HTMLInputElement>) => {
                    const self = event.target;
                    setLocationSearchParam(self.value)
                    filterLocationsByDescription(self.value)
                }}
                onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                    if(event.key === 'Enter'){
                        
                    }
                }}
                value={locationSearcParam}
            />
            <div
                style={{
                    width: '100%',
                    minHeight: '300px',
                    maxHeight: '400px',
                    height: '50%',
                    overflowY: 'auto'
                }}
            >
                {
                    filteredLocations.map((location : LocationType) => 
                        <div 
                            key={location.id} 
                            onClick={() => {
                                onSelectLocation(location)
                                onClose();
                            }}
                            style={styles.locationItem}
                        >
                            <p>{location.description}</p>
                        </div>
                    )
                }
            </div>
            <CustomButton
                style={{
                    width: '50%',
                    minWidth: '150px',
                    maxWidth: '300px',
                    alignSelf: 'center'
                }}
                variant='contained'
                onClick={onClose}
            >
                { isEnglish()? 'Cancel' : 'Cancelar' }
            </CustomButton>
        </Stack>    
    )
}

const styles : StyleObject = {
    tableCell: {
        borderBottomColor: 'gray', 
        borderBottomWidth: 1, 
        borderBottomStyle:'solid'
    },
    tableDisplay: {
        display: 'grid',
        gridTemplateColumns: '50% 25% auto',
        height: '30px',
        alignItems: 'center'
    },
    tableHeader: {
        backgroundColor: Colors.primary,
        alignItems: 'center'
    },
    tableBody: {
        height: 'calc(100vh - 255px)',
        overflowY: 'auto'
    },
    locationItem: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px 5px 0px 0px',
        padding: '10px 5px',
        borderBottom: `1px ${Colors.primary} solid`
    }
}