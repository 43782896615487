import { Colors } from 'Constants';
import axios from 'axios';
import Swal from 'sweetalert2';

const AxiosRequest = axios.create();

AxiosRequest.interceptors.request.use(config => config, 
    error => {
        Swal.fire({
            icon: 'error',
            title: error.code,
            text: error.message,
            confirmButtonColor: Colors.primary
        })
    }
);

AxiosRequest.interceptors.response.use(response => response, 
    error => {
        Swal.fire({
            icon: 'error',
            title: error.code,
            text: error.message,
            confirmButtonColor: Colors.primary
        });
        return Promise.reject(error);
    }
)

export default AxiosRequest;