import { ResponseJDAPI } from "GlobalTypes"

export const adaptResponseJDAPI = (data : any) : ResponseJDAPI => {
    const adaptedResponse : ResponseJDAPI = {
        error: data?.Error?? false,
        messageEn: data?.MessageEN?? '',
        messageEs: data?.Message?? '',
        data: data?.Object?? null
    }

    return adaptedResponse;
}