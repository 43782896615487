import AxiosRequest from "services/AxiosRequest";
import { api_url } from "Constants";

const loginUrl = `${api_url.SILA}token`;

export const getLogin = async (credentials : object) => {
    return AxiosRequest.post(loginUrl, credentials, {
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    })
}