import { createSlice } from '@reduxjs/toolkit'
import { adaptUser } from 'adapters';
import { User, UserEmptyState } from 'models';

const getInitialState = () : User => {
    const userInStorage = localStorage.getItem('user'); 
    return userInStorage? adaptUser(JSON.parse(userInStorage as string)) : UserEmptyState;
}

export const userSlice = createSlice({
    name: 'user',
    initialState: getInitialState(),
    reducers: {
        createUser: (state, action) => {
            localStorage.setItem('user', JSON.stringify(action.payload));
            return action.payload;
        },
        modifyUser: (state, action) => {
            const formattedData = {...state, ...action.payload};
            localStorage.setItem('user', JSON.stringify(formattedData));
            return formattedData;
        },
        resetUser: () => {
            localStorage.removeItem('user');
            return UserEmptyState;
        }
    }
});

export const { createUser, modifyUser, resetUser} = userSlice.actions;

export default userSlice.reducer;