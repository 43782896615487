import { createSlice } from '@reduxjs/toolkit'

const getInitialState = () : string => {
    const languageInStorage = localStorage.getItem('language'); 
    return languageInStorage? languageInStorage : 'es';
}

export const languageSlice = createSlice({
    name: 'language',
    initialState: getInitialState(),
    reducers: {
        updateLanguage: (state, action) => {
            state = action.payload;
            localStorage.setItem('language', state);
            return state;
        },
        resetLanguage: () => {
            localStorage.removeItem('language');
            return 'es';
        }
    }
});

export const { updateLanguage, resetLanguage} = languageSlice.actions;

export default languageSlice.reducer;