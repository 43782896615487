import { api_url } from "Constants"
import AxiosRequest from "./AxiosRequest"
import { InventoryType } from "models";

const apiUrl = api_url.WareHouse;

export const GetEntryInfo = (entry : string) => {
    return AxiosRequest.get(`${apiUrl}Location/GetEntryInfo?entry=${entry}`);
}

export const SaveChangeLocation = (locationID : number, entries : Array<InventoryType>) => {
    return AxiosRequest.post(`${apiUrl}Location/ChangeLocation?newLocation=${locationID}`, entries, {
        headers: {
            'Content-Type' : 'application/json'
        }
    })
}