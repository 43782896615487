import { Divider, Drawer, Stack } from "@mui/material";
import { LogoutIcon, OutputIcon } from "Iconos";
import { useNavigate } from 'react-router-dom';
import { StyleObject } from '../../GlobalTypes';
import { Colors, RoutesConfig } from "Constants";
import AppStore from '../../redux/store';
import { useContext } from "react";
import { NavigationContext } from "context/global";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faBoxOpen, faCodeCompare, faGear, faListCheck, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { isEnglish } from "utilities/Generals";
import { resetLanguage } from "redux/slices/language.slice";

export default function Menu({...props}){
    const setOpenMenu = useContext(NavigationContext).onOpenMenu;
    return(
        <Drawer
            container={window.document.body}
            variant='temporary'
            open={props.openMenu}
            sx={{
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '15%', minWidth: '300px' },
            }}
            onClick={() => setOpenMenu(false)}
        >
            <MenuTemplate onChangePage={setOpenMenu}/>
        </Drawer>
    )
}

const MenuTemplate = ({...props}) => {
    const navigate = useNavigate();
    const user = AppStore.getState().user;
    const changeTitle = useContext(NavigationContext).onChangePage;
    const handleClickChangePage = (title : string, pathToGo : string) => {
        changeTitle(title);
        props.onChangePage(false);
        navigate(pathToGo);
    }


    return(
        <section style={{width: '100%'}}>
            <Stack direction='column' style={styles.menuHeader} sx={{'& p' : { color : '#FFF'}}}>
                <div style={styles.avatar}>
                    { `${user.firstName.charAt(0).toUpperCase()}${user.lastName.charAt(0).toUpperCase()}` }
                </div>
                <p style={{marginTop:'10px'}}>{user.name}</p>
                <p>{user.email}</p>
            </Stack>
            <Stack direction='column' spacing={1} style={{marginTop:'10px'}}>
                {
                    MenuOptions.map((option : MenuOption) => {
                        return(
                            <div key={option.SectionName} onClick={()=> handleClickChangePage(option.SectionName, option.SectionPath)}>
                                <Stack direction='row' spacing={1} style={styles.menuOption}>
                                    {option.SectionIcon}
                                    <p>{option.SectionName}</p>
                                </Stack>
                                <Divider style={{marginLeft: '20px'}}/>
                            </div>
                        )
                    })
                }
            </Stack>
        </section>
    )
}

type MenuOption = {
    SectionName: string,
    SectionIcon: JSX.Element,
    SectionPath: string
}

const MenuOptions : Array<MenuOption> = [
    {
        SectionName: isEnglish()? RoutesConfig.private.configuracion.titleEn : RoutesConfig.private.configuracion.title,
        SectionIcon: <FontAwesomeIcon icon={faGear} />,
        SectionPath: `${RoutesConfig.private.configuracion.path}/`
    },
    {
        SectionName: isEnglish()? RoutesConfig.private.inventario.titleEn : RoutesConfig.private.inventario.title,
        SectionIcon: <FontAwesomeIcon icon={faListCheck} />,
        SectionPath: `${RoutesConfig.private.inventario.path}/`
    },
    {
        SectionName: isEnglish()? RoutesConfig.private.guias.titleEn : RoutesConfig.private.guias.title,
        SectionIcon: <FontAwesomeIcon icon={faMapLocationDot} />,
        SectionPath: `${RoutesConfig.private.guias.path}/`
    },
    {
        SectionName: isEnglish()? RoutesConfig.private.salidas.titleEn : RoutesConfig.private.salidas.title,
        SectionIcon: <OutputIcon/>,
        SectionPath: `${RoutesConfig.private.salidas.path}/`
    },
    {
        SectionName: isEnglish()? RoutesConfig.private.cambio_ubicacion.titleEn : RoutesConfig.private.cambio_ubicacion.title,
        SectionIcon: <FontAwesomeIcon icon={faCodeCompare} />,
        SectionPath: `${RoutesConfig.private.cambio_ubicacion.path}/`
    },
    {
        SectionName: isEnglish()? RoutesConfig.private.etiqueta_master.titleEn : RoutesConfig.private.etiqueta_master.title,
        SectionIcon: <FontAwesomeIcon icon={faBarcode} />,
        SectionPath: `${RoutesConfig.private.etiqueta_master.path}/`
    },
    {
        SectionName: isEnglish()? RoutesConfig.private.recibo_mercancia.titleEn : RoutesConfig.private.recibo_mercancia.title,
        SectionIcon: <FontAwesomeIcon icon={faBoxOpen} />,
        SectionPath: `${RoutesConfig.private.recibo_mercancia.path}/`
    },
    {
        SectionName: isEnglish()? 'Log Out' : 'Cerrar sesión',
        SectionIcon: <LogoutIcon/>,
        SectionPath: `/${RoutesConfig.public.login.path}`
    },
]

const styles : StyleObject = {
    menuOption: {
        margin: '0px 0px 5px 20px',
        alignItems: 'center',
        fontSize: '1.2em',
        fontWeight: 'bold'
    },
    menuHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '200px',
        backgroundColor: Colors.primary,
        color: '#fff',
    },
    avatar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#fff',
        borderRadius: '50%',
        width: '100px',
        height: '100px',
        fontSize: '2em'
    }
}