import { api_url } from "Constants"
import { AxiosRequest } from "./"

const apiUrl = api_url.WareHouse;

export const GetFoliosByDate = (date : string) => {
    return AxiosRequest.get(`${apiUrl}Inventory/GetFolio?date=${encodeURIComponent(date)}`);
}

export const GetClientByOperationAndLocation = (params : {operation : number, location: string}) => {
    return AxiosRequest.get(`${apiUrl}Inventory/GetClients?idOperation=${params.operation}&idLocation=${params.location}`);
}

export const PostCreateInventory = (params : { operation : number, location : string, clients : Array<string>}) => {
    return AxiosRequest.post(`${api_url.WareHouse}Inventory/CreateInventory`, {
        idOperation : params.operation,
        Location: params.location,
        clients: params.clients
    },{
        headers: {
            'Content-Type' : 'application/json'
        }
    });
}

export const SendPreliminaryReportService = (folio : string) => {
    return AxiosRequest.get(`${apiUrl}Inventario/ReportePreliminar?idFolio=${folio}`);
}

export const SendFinalReportService = (folio : string) => {
    return AxiosRequest.get(`${apiUrl}Inventario/ReporteFinal?idFolio=${folio}`);
}

export const GetInventoryDetail = (idInventory : string) => {
    return AxiosRequest.get(`${apiUrl}Inventory/GetInventoryDetails?idInventory=${idInventory}`);
}

export const GetMasterLabelInventoryDetail = (label : string) => {
    return AxiosRequest.get(`${apiUrl}Inventory/GetMasterLabelDetails?label=${label}`);
}

export const SavePackageService = (folio : string, label : string) => {
    return AxiosRequest.get(`${apiUrl}Inventario/AgregarBulto?idInventario=${folio}&etiqueta=${label}`)
}

export const UpdateScannedService = ({folio, numeroEntrada, tipoBulto, tipo} : {folio : string, numeroEntrada: string, tipoBulto: string, tipo: string}) => {
    return AxiosRequest.get(`${apiUrl}Inventario/ActualizarEscaneado?idInventario=${folio}&idEntrada=${numeroEntrada}&bulto=${tipoBulto}&tipo=${tipo}`)
}

export const ValidatePackageService = (numeroEntrada : string, tipoBulto : string) => {
    return AxiosRequest.get(`${apiUrl}Inventory/ValidateBulto?numeroEntrada=${numeroEntrada}&tipoBulto=${tipoBulto}`);
}

export const SaveCommentService = (params : {idInventoryDetail : number, description : string}) => {
    return AxiosRequest.post(`${apiUrl}Inventory/SaveComment`, params, {
        headers: {
            'Content-Type' : 'application/json'
        }
    });
}