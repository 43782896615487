import { User } from "models"

export const adaptUser = (data : any) : User => {
    return({
        id : (data?.IdUser || data?.id)?? 0,
        name: (data?.UserName || data?.name)?? '',
        email: (data?.Email || data?.email)?? '',
        firstName: (data?.FirstName || data?.firstName)?? '',
        lastName: (data?.LastName || data?.lastName)?? '',
        idUsuarioCOBO: (data?.IdUserCobo || data?.idUsuarioCOBO)?? 0,
        token: (data?.token)?? ''
    })
}