import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { Stack } from "@mui/material"
import { Header, Menu } from "components"
import { useState } from "react"
import { NavigationContext } from "context/global"
import { NavigationInfo } from "GlobalTypes"
import NotFound from "pages/NotFound"
import { isEnglish } from "utilities/Generals"
import { RouteConfigProps, RoutesConfig } from "Constants"
import Inventory from "./Inventory/Inventory"
import InventoryReport from "./InventoryReport/InventoryReport"
import Output from "./Output/Output"
import Guide from './Guide/Guide'
import ChangeLocation from "./ChangeLocation/ChangeLocation"
import Configuration from "./Configuration/Configuration"
import MailGroup from "./MailGroup/MailGroup"
import MasterLabel from "./MasterLabel/MasterLabel"
import Receipt from "./Receipt/Receipt"
import DamageReport from "./DamageReport/DamageReport"

export const Global = () => {
    const initialTitle = isEnglish()? 'Inventory' : 'Inventario';
    const location = useLocation().pathname;
    const routeByLocation = Object.entries(RoutesConfig.private).find(([, route] : [string, RouteConfigProps]) =>  location === `/Global/${route.path}/`);
    const title = routeByLocation?.[1].title?? initialTitle;
    const [sectionTitle, setSectionTitle] = useState(title);
    const [openMenu, setOpenMenu] = useState(false);
    const changeSectionTitle = (title : string) => setSectionTitle(title);
    const toggleOpenMenu = (open : boolean ) => setOpenMenu(open); 
    const [navigationInfo,] = useState<NavigationInfo>({
        pageTitle: '',
        onOpenMenu: toggleOpenMenu,
        onChangePage: changeSectionTitle
    });

    return(
        <NavigationContext.Provider value={navigationInfo}>
            <Stack direction='column'>
                <Header title={sectionTitle}/>
                <Menu openMenu={openMenu}/>
                <Routes>
                    <Route path='/' element={<Navigate to={`${RoutesConfig.private.inventario.path}`}/>}/>
                    <Route path={`${RoutesConfig.private.configuracion.path}/*`} Component={Configuration}/>
                    <Route path={`${RoutesConfig.private.inventario.path}/*`} Component={Inventory}/>
                    <Route path={`${RoutesConfig.private.salidas.path}/*`} Component={Output}/>
                    <Route path={`${RoutesConfig.private.reporte_inventario.path}/*`} Component={InventoryReport}/>
                    <Route path={`${RoutesConfig.private.guias.path}/*`} Component={Guide}/>
                    <Route path={`${RoutesConfig.private.cambio_ubicacion.path}/*`} Component={ChangeLocation}/>
                    <Route path={`${RoutesConfig.private.grupo_correos.path}/*`} Component={MailGroup}/>
                    <Route path={`${RoutesConfig.private.etiqueta_master.path}/*`} Component={MasterLabel}/>
                    <Route path={`${RoutesConfig.private.recibo_mercancia.path}/*`} Component={Receipt}/>
                    <Route path={`${RoutesConfig.private.reporte_damage.path}/*`} Component={DamageReport}/>
                    <Route path='*' Component={NotFound}/>
                </Routes>
            </Stack>
        </NavigationContext.Provider>
    )
}