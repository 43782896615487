import { MailByGroupType, MailGroupType } from "./mailgroup.type";

export class MailGroup implements MailGroupType {
    id: number;
    description: string;
    isActive: boolean;
    
    constructor(){
        this.id = 0;
        this.description = '';
        this.isActive = false;
    }
}

export class MailByGroup implements MailByGroupType {
    id: number;
    email: string;
    idMailGroup: number;
    isActive: boolean;
    
    constructor(){
        this.id = 0;
        this.email = '';
        this.idMailGroup = 0;
        this.isActive = false;
    }
}