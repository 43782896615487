import { IEntrada } from "./output.type";

export class Entrada implements IEntrada {
    idEntrada: number;
    documento: string;
    cliente: string;
    nombreCliente: string;
    fecha: string;
    observaciones: string;
    pedimento: string;
    ocupado: boolean;
    placas: string;
    sello: string;
    tipoDocNumero: string;
    idTipoDocumento: string;
    tipoDocumento: string;
    tipoEmbarque: string;
    idTransportista: string;
    transportista: string;
    tarimas: string;
    elaboro: string;

    constructor(){
        this.idEntrada = 0;
        this.documento = '';
        this.cliente = '';
        this.nombreCliente = '';
        this.fecha = '';
        this.observaciones = '';
        this.pedimento = '';
        this.ocupado = false;
        this.placas = '';
        this.sello = '';
        this.tipoDocNumero = '';
        this.idTipoDocumento = '';
        this.tipoDocumento = '';
        this.tipoEmbarque = '';
        this.idTransportista = '';
        this.transportista = '';
        this.tarimas = '';
        this.elaboro = '';
    }
}