import AxiosRequest from "services/AxiosRequest";
import { api_url } from "Constants";

const apiUrl = `${api_url.WareHouse}`;

export const getUserInfo = async (username : string) => {
    return AxiosRequest.get(`${apiUrl}General/GetUserInfo?username=${encodeURIComponent(username)}`,{
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    });
}