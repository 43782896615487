import { Button, Checkbox, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Colors } from "Constants";

const CustomTextInput = styled(TextField)({
    '& label.Mui-focused': {
        color: Colors.primary
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: Colors.primary,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: Colors.primary,
        },
        '&:hover fieldset': {
            borderColor: Colors.primary,
        },
        '&.Mui-focused fieldset': {
            borderColor: Colors.primary,
        },
    },
});

const CustomCheckBox = styled(Checkbox)({
    '&.Mui-checked': {
        color: Colors.primary
    }
});

const CustomButton = styled(Button)({
    backgroundColor: Colors.primary,
    color: '#fff',
    '&.MuiButtonBase-root.MuiButton-root:hover': {
        backgroundColor: Colors.primary
    },
    '&.MuiButtonBase-root.MuiButton-root.Mui-disabled': {
        backgroundColor: Colors.primary,
        opacity: '0.5',
        color: '#FFF'
    }
})

export { 
    CustomTextInput, 
    CustomCheckBox,
    CustomButton
};