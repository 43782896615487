import { MailByGroupType, MailGroupType } from "models";

export const adaptMailGroup = (data : any) : MailGroupType => {
    const adaptedMailGroup : MailGroupType = {
        id: data?.Id?? 0,
        description: data?.Description?? '',
        isActive: data?.Active?? false
    };

    return adaptedMailGroup;
} 

export const adaptMailByGroup = (data : any) : MailByGroupType => {
    const adaptedMailByGroup : MailByGroupType = {
        id: data?.Id?? 0,
        email: data?.Email?? '',
        idMailGroup: data?.idMailGroup?? 0,
        isActive : data?.Active?? false
    };

    return adaptedMailByGroup;
}