import { api_url } from "Constants";
import AxiosRequest from "./AxiosRequest"
import { MailByGroupType, MailGroupType } from "models";

const apiUrl = api_url.WareHouse;

export const GetMailGroups = () => {
    return AxiosRequest.get(`${apiUrl}Mail/GetMailGroup`);
}

export const SaveMailGroup = (mailGroup : MailGroupType) => {
    return AxiosRequest.post(`${apiUrl}Mail/PutMailGroup`, {
        Id: mailGroup.id,
        Description: mailGroup.description,
        Active: mailGroup.isActive
    });
}

export const GetMailsByGroup = (idMailGroup : number) => {
    return AxiosRequest.get(`${apiUrl}Mail/GetMails?idMailGroup=${idMailGroup}`);
}

export const SaveMail = (mail : MailByGroupType) => {
    return AxiosRequest.post(`${apiUrl}Mail/PutMail`, {
        Id: mail.id,
        Email: mail.email,
        IdMailGroup: mail.idMailGroup,
        Active: mail.isActive
    });
}