import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Divider, MenuItem, SelectChangeEvent, Stack } from "@mui/material";
import { BasicSwalConfig, Colors, RouteConfigProps, RoutesConfig } from "Constants";
import { StyleObject } from "GlobalTypes";
import { CancelIcon, LabelIcon, SearchIcon } from "Iconos";
import { adaptLocation, adaptMercancia, adaptPrinterType, adaptRecibo, adaptReciboDetalle, adaptResponseJDAPI, adaptTipoBulto, adaptWarehouse } from "adapters";
import { CustomButton, CustomSelect, CustomTextInput } from "components";
import { NavigationContext } from "context/global";
import Awaiter from "hooks/Awaiter";
import { CatalogoType,LocationType,MercanciaType,ReciboDetalle,ReciboDetalleType, TipoBultoType } from "models";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetLocations, GetMerchandise, GetPackageTypes, GetPrinters, GetWareHouses, PrintLabel, SaveDetail, SearchEntry, ValidateLabel } from "services";
import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";
import { isEnglish, isStringEmpty } from "utilities/Generals";

const ShowAlert = ( title: string, message : string, icono : SweetAlertIcon = 'info') => {
    const customConfig : SweetAlertOptions = {
        icon: icono,
        title: isStringEmpty(title)? BasicSwalConfig.title : title,
        text: isStringEmpty(message)? BasicSwalConfig.text : message
    }
    
    Swal.fire({...BasicSwalConfig,...customConfig});
}

type PrinterType = 'Normal' | 'Zebra';

export default function Receipt(){
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [warehouse, setWarehouse] = useState<string>('');
    const [warehouses, setWarehouses] = useState<Array<CatalogoType>>([]);
    const [entryNumber, setEntryNumber] = useState<string>('');
    const [client, setClient] = useState<string>('');
    const [detalleList, setDetalleList] = useState<Array<ReciboDetalleType>>([]);
    const [selectedDetalle, setSelectedDetalle] = useState<ReciboDetalleType>(new ReciboDetalle());
    const [idEntry, setIdEntry] = useState<number>(0);
    const [openDetailModal, setOpenDetailModal] = useState<boolean>(false);
    const [openPrintingModal, setOpenPrintingModal] = useState<boolean>(false);
    const navigationContext = useContext(NavigationContext);
    const location = useLocation().pathname;

    useEffect(() => {
        const routeByLocation = Object.entries(RoutesConfig.private).find(([, route] : [string, RouteConfigProps]) =>  location === `/Global/${route.path}${location.endsWith('/')? '/' : ''}`);
        navigationContext.onChangePage((isEnglish()? routeByLocation?.[1].titleEn : routeByLocation?.[1].title)?? '');

        GetLocations();
    },[]);

    const GetLocations = async () => {
        GetWareHouses().then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const { messageEn, messageEs } = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error')
                return;
            }

            if(responseAPI.data){
                const warehouses = (responseAPI.data as Array<CatalogoType>).map(warehouse => adaptWarehouse(warehouse))
                setWarehouses(warehouses);
            }
        })
        .catch(error => {
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get warehouse' : 'Ocurrió un problema al intentar obtener almacenes'}:\n ${error.message}`, 'error');
        });
    };

    const ValidateEntryLabel = (entry : string) => {
        if(entry.split('-').length > 1){
            setIsLoading(true);
            ValidateLabel('').then(response => {
                setIsLoading(false);
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    const { messageEn, messageEs } = responseAPI;
                    ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                    return;
                }

                if(response.data.Object.Entrada){
                    const entrada = response.data.Object.Entrada;
                    setEntryNumber(entrada);
                    SearchEntryNumber(entrada);
                }
            })
            .catch(error => {
                setIsLoading(false);
                ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get merchandise' : 'Ocurrió un problema al intentar obtener mercancías'}:\n ${error.message}`, 'error');
            })
        }else{
            const entrada = entry.toUpperCase().replace('BLS','');
            setEntryNumber(entrada);
            SearchEntryNumber(entrada);
        }
    }

    const SearchEntryNumber = (entry : string) => {
        if(!isStringEmpty(entry)){
            setIsLoading(true);
            SearchEntry(entry).then(response => {
                setIsLoading(false);
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    const { messageEn, messageEs } = responseAPI;
                    ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                    return;
                }

                if(responseAPI.data){
                    const entry = adaptRecibo(responseAPI.data);
                    setIdEntry(entry.idEntrada);
                    setWarehouse(entry.almacen);
                    setClient(entry.cliente);
                    setDetalleList(entry.detalle.map((_detalle : any) => adaptReciboDetalle(_detalle)));
                }

            })
            .catch(error => {
                setIsLoading(false);
                ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get entry information' : 'Ocurrió un problema al intentar obtener información de entrada'}:\n ${error.message}`, 'error');
            })
        }else{
            ShowAlert('Error', isEnglish()?  'Entry number cannot be empty' : 'Número de entrada no puede estar vacío', 'error');
        }
    }

    const OpenDetail = (idEntrada : number) => {
        if(idEntrada > 0){
            setOpenDetailModal(true);
        }else{
            ShowAlert('Error', isEnglish()? 'You must enter an entry to continue' : 'Debe ingresar una entrada para continuar', 'error');
        }
    }

    const Continue = (idEntrada : number) => {
        if(idEntrada > 0){
            const customConfig : SweetAlertOptions = {
                icon: 'question',
                title: isEnglish()? 'Damage Report' : 'Reporte de daños',
                text: isEnglish()? 'Was damage identified during receipt?' : '¿Se identificaron daños durante el recibo?',
                confirmButtonText: isEnglish()? 'Yes, report it' : 'Si, reportar',
                showCancelButton: true,
                cancelButtonText: isEnglish()? 'No, cancel' : 'No, cancelar'
            }
            Swal.fire({...BasicSwalConfig,...customConfig}).then(result => {
                if(result.isConfirmed){
                    navigationContext.onChangePage(`${isEnglish()? RoutesConfig.private.reporte_damage.titleEn : RoutesConfig.private.reporte_damage.title}`);
                    navigate(`/Global/${RoutesConfig.private.reporte_damage.path}`, {state: {idEntrada: idEntry, entrada : entryNumber}});
                }
            });
        }else{
            ShowAlert('Error', isEnglish()? 'You must enter an entry to continue' : 'Debe ingresar una entrada para continuar', 'error');
        }
    }

    const ClearScreen = () => {
        setEntryNumber('');
        setWarehouse('');
        setClient('');
        setIdEntry(0);
        setDetalleList([]);
    }

    return(
        <Awaiter showLoader={isLoading}>
        <Stack direction='column' divider={<Divider />}>
            <Stack direction='column' style={{padding:'0px 5px'}}>
                <CustomSelect
                    idLabelSelect='Warehouse'
                    idSelect='Warehouse'
                    value={warehouse}
                    label={isEnglish()? 'Warehouse' : 'Almacén'}
                    width='100%'
                    disabled={isLoading}
                    onChangeSelection={(event : SelectChangeEvent) => setWarehouse(event.target.value)}
                >
                    {
                        warehouses.map((warehouse : CatalogoType) => <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.description}</MenuItem>)
                    }
                </CustomSelect>
                <CustomTextInput 
                    variant='standard'
                    label={isEnglish()? 'Entry Number' : 'Entrada Número'}
                    size='small'
                    sx={{
                        minWidth: '100px'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => setEntryNumber(event.target.value)}
                    onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                        if(!['0','1','2','3','4','5','6','7','8','9','Backspace'].includes(event.key)){
                            if(event.key === 'Enter'){
                                ValidateEntryLabel(entryNumber);
                            }else{
                                event.preventDefault();
                            }
                        }
                        
                    }}
                    value={entryNumber}
                />
                <CustomTextInput 
                    variant='standard'
                    label={isEnglish()? 'Client' : 'Cliente'}
                    size='small'
                    sx={{
                        minWidth: '100px'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => setClient(event.target.value)}
                    onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                        if(event.key === 'Enter'){
                        }
                    }}
                    value={client}
                    disabled={true}
                />
            </Stack>
            <Stack 
                direction='row' 
                style={{...styles.tableDisplay,...styles.tableHeader}} 
                sx={{
                    '& > p' : { 
                        color: '#fff', margin:'2px 5px', textAlign: 'center'
                    }
                }}
            >
                <p></p>
                <p>{isEnglish()? 'Qty' : 'Cant'}</p>
                <p>{isEnglish()? 'Description' : 'Descripcion'}</p>
                <p>{isEnglish()? 'References' : 'Referencias'}</p>
            </Stack>
            <Stack direction='column' style={styles.tableBody}>
                {
                    detalleList.map((_detalle : ReciboDetalleType) => 
                        <div 
                            key={_detalle.idDetalle} 
                            style={{
                                ...styles.tableDisplay, 
                                height:'auto', 
                                fontSize:'0.9em', 
                                backgroundColor: _detalle.idDetalle === selectedDetalle.idDetalle? '#E5E5E5' : 'transparent'
                            }} 
                            className='rowDetalleItem'
                            onClick={() => setSelectedDetalle({..._detalle, entrada: idEntry.toString()})}
                        >
                            <div
                                onClick={() => {
                                    setSelectedDetalle({..._detalle, entrada: idEntry.toString()});
                                    setOpenPrintingModal(true);
                                }}
                                style={{display:'flex', justifyContent:'center', alignItems: 'center'}}
                            >
                                <LabelIcon style={{fontSize:'1.2em'}} />
                            </div>
                            <p>{_detalle.cantidad}</p>
                            <p>{_detalle.descripcion}</p>
                            <p style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                {
                                    [_detalle.referencia1, _detalle.referencia2, _detalle.referencia3]
                                        .filter((referencia : string) => !isStringEmpty(referencia))
                                        .map((referencia : string, index : number) => <span key={index}>{`ref${index + 1}: ${referencia}`}</span>)
                                }
                            </p>
                        </div>
                    )
                }
            </Stack>
            <Stack direction='row' style={{padding:'0px 5px', gap:'20px', marginTop:'10px'}}>
                <CustomButton
                    style={{
                        width: '100%',
                        minWidth: '100px',
                        maxWidth: '200px',
                        alignSelf:'center',
                        justifySelf:'center'
                    }}
                    disabled={idEntry === 0}
                    onClick={() => {
                        OpenDetail(idEntry);
                    }}
                >
                    PrePacking
                </CustomButton>
                <CustomButton
                    style={{
                        width: '50px',
                        minWidth: '30px',
                        maxWidth: '50px',
                        alignSelf:'center',
                        justifySelf:'center'
                    }}
                    disabled={false}
                    onClick={ClearScreen}
                >
                    <FontAwesomeIcon icon={faEraser} style={{fontSize:'1.3em'}}/>
                </CustomButton>
                <CustomButton
                    style={{
                        width: '100%',
                        minWidth: '100px',
                        maxWidth: '200px',
                        alignSelf:'center',
                        justifySelf:'center'
                    }}
                    disabled={idEntry === 0}
                    onClick={() => {
                        Continue(idEntry);
                    }}
                >
                    { isEnglish()? 'Continue' : 'Continuar' }
                </CustomButton>
            </Stack>
            <Dialog
                open={openDetailModal}
                fullWidth
                maxWidth='md'
            >
                <DetailModal 
                    reciboDetalleItem={{...selectedDetalle, entrada : entryNumber}}
                    onClose={(entrada : string) => {
                        setOpenDetailModal(false);
                        if(!isStringEmpty(entrada)){
                            ClearScreen();
                            setEntryNumber(entrada);
                            SearchEntryNumber(entrada);
                        }
                    }}
                    onLoad={(_isLoading : boolean) => setIsLoading(_isLoading)}
                />
            </Dialog>
            <Dialog
                open={openPrintingModal}
                fullWidth
                maxWidth='md'
            >
                <PrintingModal 
                    printerType='Zebra'
                    onClose={() => setOpenPrintingModal(false)}
                    onLoad={(_isLoading : boolean) => setIsLoading(_isLoading)}
                    printerParams={{
                        idDetalle: selectedDetalle.idDetalle
                    }}
                />
            </Dialog>
        </Stack>
        </Awaiter>
    )
}

type DetailModalProps = {
    reciboDetalleItem : ReciboDetalleType,
    onClose : (entrada : string) => void,
    onLoad : (isLoading : boolean) => void
}

const DetailModal = ({reciboDetalleItem, onClose, onLoad} : DetailModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bulto, setBulto] = useState<number>(0);
    const [cantidad, setCantidad] = useState<number>(reciboDetalleItem.cantidad);
    const [descripcion, setDescripcion] = useState<string>(reciboDetalleItem.descripcion);
    const [tiposBulto, setTiposBulto] = useState<Array<TipoBultoType>>([]);
    const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
    const [location, setLocation] = useState<LocationType>({id:-1, description:''});
    const [locations, setLocations] = useState<Array<LocationType>>([]);
    const [mercancia, setMercancia] = useState<string>('');
    const [mercancias, setMercancias] = useState<Array<MercanciaType>>([]);
    const [referencia1, setReferencia1] = useState<string>(reciboDetalleItem.referencia1);
    const [referencia2, setReferencia2] = useState<string>(reciboDetalleItem.referencia2);
    const [referencia3, setReferencia3] = useState<string>(reciboDetalleItem.referencia3);
    const [sello, setSello] = useState<string>(reciboDetalleItem.sello);
    const [allowSave, setAllowSave] = useState<boolean>(false);

    useEffect(() => {
        GetPackageTypes().then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const { messageEn, messageEs } = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                return;
            }
            if(responseAPI.data){
                const adaptedPackageTypes = (responseAPI.data as Array<any>).map((tipoBulto : any) => adaptTipoBulto(tipoBulto));
                setTiposBulto(adaptedPackageTypes);
                setBulto(+(reciboDetalleItem.idTipoBulto))
            }
        })
        .catch(error => {
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get package types' : 'Ocurrió un problema al intentar obtener los tipos de bulto'}:\n ${error.message}`, 'error');
        });

        GetLocations().then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const { messageEn, messageEs } = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs);
            }else{
                if(responseAPI.data){
                    const adaptedLocations = (responseAPI.data as Array<LocationType>)
                        .map((location : LocationType) => adaptLocation(location))
                        .filter((location : LocationType) => !isStringEmpty(location.description));
                    setLocations(adaptedLocations);
                    const reciboDetalleUbicacion = adaptedLocations.find((_location : LocationType) => _location.id === +(reciboDetalleItem.idUbicacion));
                
                    if(reciboDetalleUbicacion){
                        setLocation(reciboDetalleUbicacion);
                    }
                }
            }
        })
        .catch(error => {
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get locations' : 'Ocurrió un problema al intentar obtener ubicaciones'}:\n ${error.message}`, 'error');
        })

        GetMerchandise().then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const { messageEn, messageEs } = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs);
            }else{
                if(responseAPI.data){
                    const adaptedMercancias = (responseAPI.data as Array<MercanciaType>)
                        .map((_mercancia : MercanciaType) => adaptMercancia(_mercancia))
                    setMercancias(adaptedMercancias);
                    setMercancia(reciboDetalleItem.idTipoMercancia);
                }
            }
        })
        .catch(error => {
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get merchandise' : 'Ocurrió un problema al intentar obtener mercancías'}:\n ${error.message}`, 'error');
        });

        const isValidToSave = [(!isStringEmpty(reciboDetalleItem.entrada)), (cantidad > 0), (bulto > 0), (!isStringEmpty(descripcion)), (location.id >= 0), (+(mercancia) > 0)].every((validation : boolean) => validation === true);
        setAllowSave(isValidToSave);
    },[]);

    useEffect(() => {
        const isValidToSave = [(!isStringEmpty(reciboDetalleItem.entrada)), (cantidad > 0),(bulto > 0), (!isStringEmpty(descripcion)), (location.id >= 0), (+(mercancia) > 0)].every((validation : boolean) => validation === true);
        setAllowSave(isValidToSave);
    },[reciboDetalleItem.entrada, cantidad, bulto, descripcion, location, mercancia])

    const changeLoadingState = (_isLoading : boolean) => {
        setIsLoading(_isLoading);
        onLoad(_isLoading);
    }

    const SearchLocation = (locationDescription : string) => {
        const matchedLocation = locations.find((location : LocationType) => location.description === locationDescription);
        if(matchedLocation){
            setLocation(matchedLocation);
        }else{
            ShowAlert('Error', isEnglish()? 'The location entered is not a valid location' : 'La ubicación ingresada no es una ubicación válida', 'error');
            setLocation({id: -1, description: ''});
        }
    } 

    const Save_Detail = async () => {
        const detalle = {
            Entrada : reciboDetalleItem.entrada,
            idDetalle: reciboDetalleItem.idDetalle,
            Cantidad : cantidad,
            idTipoBulto : bulto.toString(),
            Descripcion : descripcion,
            idUbicacion : location.id.toString(),
            idTipoMercancia : mercancia,
            Referencia1 : referencia1,
            Referencia2 : referencia2,
            Referencia3 : referencia3,
            Sello : sello
        }
        changeLoadingState(true);
        SaveDetail(detalle).then(response => {
            changeLoadingState(false);
            const responseAPI = adaptResponseJDAPI(response.data);
            const { messageEn, messageEs } = responseAPI;
            if(responseAPI.error){
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                return;
            }

            const customConfig : SweetAlertOptions = {
                icon: 'success',
                title: isEnglish()? 'Success!' : '¡Éxito!',
                text: isEnglish()? messageEn : messageEs
            }
            Swal.fire({...BasicSwalConfig,...customConfig}).then(result => {
                if(result.isConfirmed){
                    onClose(reciboDetalleItem.entrada);
                }
            });
        })
        .catch(error => {
            changeLoadingState(false);
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get entry information' : 'Ocurrió un problema al intentar obtener información de entrada'}:\n ${error.message}`, 'error');
        })
    }

    return(
        <Stack direction='column' divider={<Divider />}>
            <div style={{display: 'flex', justifyContent:'flex-end', padding:'5px'}}>
                <div
                    onClick={() => {
                        changeLoadingState(false);
                        onClose('');
                    }} 
                >
                    <CancelIcon style={{fontSize:"1.5em", color: Colors.redError}}/>
                </div>
            </div>
            <Stack direction='column' style={{margin:'0px 10px 10px'}}>
                <CustomTextInput 
                    variant='standard'
                    label={isEnglish()? 'Quantity' : 'Cantidad'}
                    size='small'
                    sx={{
                        minWidth: '100px'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => setCantidad(+(event.target.value))}
                    onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                        if(!['0','1','2','3','4','5','6','7','8','9','Backspace'].includes(event.key)){
                            event.preventDefault();
                        }
                    }}
                    value={cantidad}
                    disabled={isLoading}
                />
                <CustomSelect
                    idLabelSelect='Bultos'
                    idSelect='Bultos'
                    value={bulto}
                    label={isEnglish()? 'Package Type' : 'Tipo Bulto'}
                    width='100%'
                    disabled={isLoading}
                    onChangeSelection={(event : SelectChangeEvent) => setBulto(+(event.target.value))}
                >
                    {
                        tiposBulto.map((tipoBulto : TipoBultoType) => <MenuItem key={tipoBulto.id} value={tipoBulto.id}>{tipoBulto.description}</MenuItem>)
                    }
                </CustomSelect>
                <CustomTextInput 
                    variant='standard'
                    label={isEnglish()? 'Description' : 'Descripción'}
                    size='small'
                    sx={{
                        minWidth: '100px'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => setDescripcion(event.target.value)}
                    value={descripcion}
                    disabled={isLoading}
                />
                <Stack direction='row' style={{justifyContent:'space-between', alignItems:'center', height:'64px'}}>
                    <CustomTextInput 
                        variant='standard'
                        label={isEnglish()? 'Scan Location' : 'Escanear Ubicación'}
                        size='small'
                        sx={{
                            width: '100%',
                            minWidth: '150px'
                        }}
                        onChange={(event : React.ChangeEvent<HTMLInputElement>) => {
                            const self = event.target;
                            if(isStringEmpty(self.value)){
                                setLocation({id:-1, description: ''});
                            }else{
                                setLocation({...location, description: self.value})
                            }
                        }}
                        onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                            if(event.key === 'Enter'){
                                SearchLocation(location.description);
                            }
                        }}
                        onBlur={(_event : React.FocusEvent<HTMLInputElement>) => {
                            if(!isStringEmpty(location.description)){
                                SearchLocation(location.description);
                            }
                        }}
                        value={location.description}
                        disabled={isLoading}
                    />
                    <div onClick={() => { 
                        if(!isLoading){
                            setOpenLocationModal(true);
                        }
                    }}>
                        <SearchIcon style={{fontSize: '2em', color: Colors.primary}}/>
                    </div>
                </Stack>
                <CustomSelect
                    idLabelSelect='Mercancia'
                    idSelect='Mercancia'
                    value={mercancia}
                    label={isEnglish()? 'Merchandise' : 'Mercancía'}
                    width='100%'
                    disabled={isLoading}
                    onChangeSelection={(event : SelectChangeEvent) => {
                        setMercancia(event.target.value);
                    }}
                >
                    {
                        mercancias.map((_mercancia : MercanciaType) => <MenuItem key={_mercancia.id} value={_mercancia.id}>{_mercancia.description}</MenuItem>)
                    }
                </CustomSelect>
                <CustomTextInput 
                    variant='standard'
                    label={isEnglish()? 'Reference 1' : 'Referencia 1'}
                    size='small'
                    sx={{
                        minWidth: '100px'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => setReferencia1(event.target.value)}
                    value={referencia1}
                    disabled={isLoading}
                />
                <CustomTextInput 
                    variant='standard'
                    label={isEnglish()? 'Reference 2' : 'Referencia 2'}
                    size='small'
                    sx={{
                        minWidth: '100px'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => setReferencia2(event.target.value)}
                    value={referencia2}
                    disabled={isLoading}
                />
                <CustomTextInput 
                    variant='standard'
                    label={isEnglish()? 'Reference 3' : 'Referencia 3'}
                    size='small'
                    sx={{
                        minWidth: '100px'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => setReferencia3(event.target.value)}
                    value={referencia3}
                    disabled={isLoading}
                />
                <CustomTextInput 
                    variant='standard'
                    label={isEnglish()? 'Stamp NOM' : 'Sello NOM'}
                    size='small'
                    sx={{
                        minWidth: '100px'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => setSello(event.target.value)}
                    value={sello}
                    disabled={isLoading}
                />
                <CustomButton
                    style={{
                        width: '100%',
                        minWidth: '100px',
                        maxWidth: '200px',
                        alignSelf:'center',
                        justifySelf:'center',
                        marginTop:'10px'
                    }}
                    disabled={!allowSave || isLoading}
                    onClick={Save_Detail}
                >
                    { isEnglish()? 'Save' : 'Guardar' }
                </CustomButton>
            </Stack>
            <Dialog
                open={openLocationModal}
                fullWidth
                maxWidth='md'
            >
                <LocationModal 
                    locations={locations}
                    onClose={() => setOpenLocationModal(false)}
                    onSelectLocation={(location : LocationType) => setLocation(location)}
                />
            </Dialog>
        </Stack>    
    )
}

type LocationModalProps = {
    locations: Array<LocationType>,
    onClose: () => void,
    onSelectLocation: (location : LocationType) => void,
}

const LocationModal = ({locations, onClose, onSelectLocation} : LocationModalProps) => {
    const [locationSearchParam, setLocationSearchParam] = useState<string>('');
    const [filteredLocations, setFilteredLocatios] = useState<Array<LocationType>>(locations);

    const filterLocationsByDescription = (locationDescription : string) => {
        if(!isStringEmpty(locationDescription)){
            setFilteredLocatios(locations.filter((location : LocationType) => location.description.includes(locationDescription)));
        }else{
            setFilteredLocatios(locations);
        }
    }

    return(
        <Stack direction='column' style={{display:'flex', alignItems:'flex-start', gap:'10px', margin:'20px'}}>
            <CustomTextInput 
                variant='standard'
                label={isEnglish()? 'Search Location' : 'Buscar Ubicación'}
                size='small'
                sx={{
                    width: 'calc(100% - 20px)',
                    minWidth: '150px',
                    margin: '10px'
                }}
                onChange={(event : React.ChangeEvent<HTMLInputElement>) => {
                    const self = event.target;
                    setLocationSearchParam(self.value)
                    filterLocationsByDescription(self.value)
                }}
                onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                    if(event.key === 'Enter'){
                        
                    }
                }}
                value={locationSearchParam}
            />
            <div
                style={{
                    width: '100%',
                    minHeight: '300px',
                    maxHeight: '400px',
                    height: '50%',
                    overflowY: 'auto'
                }}
            >
                {
                    filteredLocations.map((location : LocationType) => 
                        <div 
                            key={location.id} 
                            onClick={() => {
                                onSelectLocation(location)
                                onClose();
                            }}
                            style={styles.locationItem}
                        >
                            <p>{location.description}</p>
                        </div>
                    )
                }
            </div>
            <CustomButton
                style={{
                    width: '50%',
                    minWidth: '150px',
                    maxWidth: '300px',
                    alignSelf: 'center'
                }}
                variant='contained'
                onClick={onClose}
            >
                { isEnglish()? 'Cancel' : 'Cancelar' }
            </CustomButton>
        </Stack>    
    )
}

type PrintingModalProps = {
    printerType : PrinterType,
    onClose: () => void,
    onLoad: (isLoading : boolean) => void,
    printerParams : {
        idDetalle: number
    }
}

const PrintingModal = ({printerType, onClose, onLoad, printerParams} : PrintingModalProps) => {
    const [printer, setPrinter] = useState<number>(0);
    const [printers, setPrinters] = useState<Array<CatalogoType>>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        GetPrinters(printerType as string).then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const customConfig : SweetAlertOptions = {
                    icon: 'error',
                    text: isEnglish()? responseAPI.messageEn : responseAPI.messageEs
                }
                
                Swal.fire({...BasicSwalConfig,...customConfig});
            }else{
                const adaptedPrinters = ((responseAPI.data as Array<CatalogoType>)?? []).map((printer : any) => adaptPrinterType(printer));
                setPrinters(adaptedPrinters);
            }
        })
    },[]);

    const changeLoadingState = (_isLoading : boolean) => {
        setIsLoading(_isLoading);
        onLoad(_isLoading);
    }

    const Print = () => {
        if(printer > 0 && printerParams.idDetalle > 0) {
            changeLoadingState(true);
            const printParams = {...printerParams, impresora : printer};
            
            PrintLabel(printParams).then(response => {
                changeLoadingState(false);
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    ShowAlert('Error', isEnglish()? responseAPI.messageEn : responseAPI.messageEs);
                }else{
                    const customConfig : SweetAlertOptions = {
                        icon: 'info',
                        title: isEnglish()? 'Success' : 'Éxito',
                        text: isEnglish()? responseAPI.messageEn : responseAPI.messageEs
                    }
                    
                    Swal.fire({...BasicSwalConfig,...customConfig}).then(() => {
                        onClose();
                    });
                }
            })
            .catch(error => {
                changeLoadingState(false);
                ShowAlert('Critical Error', `${isEnglish()? 'There is no a valid guide id' : 'No se cuenta con un id de guía válido'}: ${error}`, 'error');
            })
        }else{
            ShowAlert('Error', isEnglish()? 'Please select label and printer' : 'Favor de seleccionar etiqueta e impresaora', 'error');
        }
    }

    return(
        <Stack direction='column' style={{display:'flex', gap:'10px', margin:'20px'}} spacing={1}>
            <CustomSelect
                idLabelSelect='Impresora'
                idSelect='Impresora'
                value={printer}
                label='Impresora'
                width='45%'
                disabled={isLoading}
                onChangeSelection={(event : SelectChangeEvent) => setPrinter(+(event.target.value))}
            >
                {
                    printers.map((printer : CatalogoType) => <MenuItem value={printer.id} key={printer.id}>{printer.description}</MenuItem>)
                }
            </CustomSelect>
            <Stack direction='row' style={{display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
                <CustomButton
                    style={{
                        width: '45%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center'
                    }}
                    variant='contained'
                    onClick={Print}
                    disabled={printer <= 0}
                >
                    {isEnglish()? 'Print' : 'Imprimir'}
                </CustomButton>
                <CustomButton
                    style={{
                        width: '45%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center',
                        backgroundColor: '#B9B9B9'
                    }}
                    variant='contained'
                    onClick={onClose}
                >
                    { isEnglish()? 'Cancel' : 'Cancelar'}
                </CustomButton>
            </Stack>
        </Stack>
    )
}

const styles : StyleObject = {
    tableDisplay: {
        display: 'grid',
        gridTemplateColumns: '10% 15% 1fr 1fr',
        height: '30px',
        alignItems: 'center'
    },
    tableHeader: {
        backgroundColor: Colors.primary,
        alignItems: 'center'
    },
    tableBody: {
        height: 'calc(100vh - 280px)', 
        overflowY: 'auto'
    },
    locationItem: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px 5px 0px 0px',
        padding: '10px 5px',
        borderBottom: `1px ${Colors.primary} solid`
    }
}