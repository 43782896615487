import { SweetAlertOptions } from "sweetalert2";

export const api_url = Object.freeze({
    WareHouse: process.env.REACT_APP_WAREHOUSE_API || 'http://localhost/JDAppApis/',
    SILA: process.env.REACT_APP_SILA_API || 'https://sila.jdgroup.net/SilaMovil/'
});

export const Colors : Record<string, string> = Object.freeze({
    primary: '#003B49',
    redError: '#F76464',
    greenSuccess: '#58D68D',
    softGray: '#DCDCDC',
    softBlue: '#0087FF'
});

export type RouteConfigProps = {
    title: string,
    titleEn: string,
    path: string
}

export const RoutesConfig : Record<string, {[key : string] : RouteConfigProps}> = Object.freeze({
    public: {
        login: {
            title: '',
            titleEn: '',
            path: 'Login'
        }
    },
    private: {
        configuracion: {
            title: 'Configuración',
            titleEn: 'Configuration',
            path: 'Configuration'
        },
        inventario: {
            title: 'Inventario',
            titleEn: 'Inventory',
            path: 'Inventario'
        },
        guias: {
            title: 'Guias',
            titleEn: 'Guide',
            path: 'Guias'
        },
        salidas: {
            title: 'Salidas',
            titleEn: 'Output',
            path: 'Salidas'
        },
        cambio_ubicacion: {
            title: 'Cambio de Ubicación',
            titleEn: 'Change Location',
            path: 'CambioUbicacion'
        },
        etiqueta_master: {
            title: 'Etiqueta Master',
            titleEn: 'Master Label',
            path: 'EtiquetaMaster'
        },
        recibo_mercancia: {
            title: 'Recibo de Mercancía',
            titleEn: 'Merchandise Receipt',
            path: 'ReciboMercancia'
        },
        reporte_inventario:  {
            title: 'Reporte de Inventario',
            titleEn: 'Inventory Report',
            path: 'ReporteInventario'
        },
        grupo_correos: {
            title: 'Grupo de Correos',
            titleEn: 'Mail Group',
            path: 'MailGroup'
        },
        reporte_damage: {
            title: 'Reporte de Daños',
            titleEn: 'Damage Report',
            path: 'DamageReport'
        }
    }
})

export const BasicSwalConfig : SweetAlertOptions = {
    icon: 'error',
    title: 'Error',
    text: 'Ha ocurrido un error',
    confirmButtonColor: Colors.primary,
    showCancelButton: false,
    cancelButtonColor: Colors.redError,
    allowEnterKey: false,
    allowOutsideClick:false
}