import { ClientInventoryType, FolioInventoryType, InventoryType, MasterLabelDetailType, OperationType, WareHouseType } from "models"

export const adaptOperationTypes = (data : any) : OperationType => {
    return({
        id: data?.Id?? 0,
        description: data?.Description?? 'NA'
    });
};

export const adaptWarehouse = (data : any) : WareHouseType => {
    return({
        id: data?.Id?? '',
        description: data?.Description?? 'NA'
    })
};

export const adaptClientsInventory = (data : any) : ClientInventoryType => {
    return({
        clientCode: data?.ClientCode?? '',
        clientName: data?.ClientName?? ''
    })
};

export const adaptFoliosInventory = (data : any) : FolioInventoryType => {
    return({
        idInventoryDetail: data?.IdInventoryDetail?? 0,
        folio: data?.Folio?? ''
    })
};

export const adaptInventory = (data : any) : InventoryType => {
    const adaptedInventory : InventoryType = {
        idInventoryDetails: data?.IdInventoryDetail ?? 0,
        folio: data?.Folio?? '',
        clientCode: data?.ClientCode?? '',
        clientName: data?.ClientName?? '',
        entry: data?.Entry?? '',
        location: data?.Location?? '',
        tipoBulto: data?.TipoBulto?? '',
        bulto: data?.Bulto?? '',
        exist: data?.Exist?? 0,
        scanned: data?.Scanned?? 0,
        anterior: data?.Anterior?? '',
        detalle: data?.Detalle?? '',
        comment: data?.Comment?? ''
    }

    return adaptedInventory;
} 

export const adaptMasterLabelDetails = (data: any) : MasterLabelDetailType => {
    const adaptedMasterLabelDetails : MasterLabelDetailType = {
        idEtiquetaGrupo: +(data?.idEtiquetaGrupo?? 0),
        etiqueta: data?.Etiqueta?? '',
        idEtiquetaMaster: +(data?.idEtiquetaMaster?? 0),
        numeroBulto: data?.NumeroBulto?? ''
    }

    return adaptedMasterLabelDetails;
}