import { MercanciaType, ReciboDetalleType, ReciboType, TipoBultoType } from "models"

export const adaptRecibo = (data : any) : ReciboType => {
    const adaptedRecibo : ReciboType = {
        idEntrada: data?.idEntrada?? 0,
        entrada: data?.Entrada?? '',
        almacen: data?.Almacen?? '',
        idUbicacion: data?.idUbicacion?? '',
        cliente: data?.Cliente?? '',
        estatus: data?.Estatus?? '',
        detalle: data?.Detalle?? []
    }

    return adaptedRecibo;
}

export const adaptReciboDetalle = (data : any) : ReciboDetalleType => {
    const adaptedReciboDetalle : ReciboDetalleType = {
        idDetalle: data?.idDetalle?? 0,
        cantidad: data?.Cantidad?? 0,
        idTipoBulto: data?.idTipoBulto?? '',
        descripcion: data?.Descripcion?? '',
        idUbicacion: data?.idUbicacion?? '',
        idTipoMercancia: data?.idTipoMercancia?? '',
        referencia1: data?.Referencia1?? '',
        referencia2: data?.Referencia2?? '',
        referencia3: data?.Referencia3?? '',
        sello: data?.Sello?? '',
        entrada: data?.Entrada?? ''
    }

    return adaptedReciboDetalle;
}

export const adaptTipoBulto = (data : any) : TipoBultoType => {
    const adaptedTipoBulto : TipoBultoType = {
        id: data?.Id?? 0,
        description: data?.Description?? ''
    }

    return adaptedTipoBulto;
}

export const adaptMercancia = (data : any) : MercanciaType => {
    const adaptedMercancia : MercanciaType = {
        id: data?.Id?? '',
        description: data?.Description?? ''
    }

    return adaptedMercancia;
}