import { Stack } from "@mui/material"
import { MenuIcon } from "Iconos"
import JDLogo from '../../assets/images/JDLogoWhite.svg';
import { CSSProperties, useContext } from "react";
import { Colors } from "Constants";
import { NavigationContext } from "context/global";

export default function Header({...props} : any) {
    const handleClickOpenMenu = useContext(NavigationContext).onOpenMenu;
    const handleOpenMenu = () => handleClickOpenMenu(true);
    return(
        <Stack direction='row' style={styles.headerContainer}>
            <MenuIcon style={{margin: '0px 10px 0px'}} onClick={handleOpenMenu}/>
            <h3>{ props.title?? 'Titulo'}</h3>
            <div style={{padding: '5px'}}>
                <img src={JDLogo} alt='jdlogo.png' style={styles.imgLogo}/>
            </div>
        </Stack>
    )
}

const styles : Record<string, CSSProperties> = {
    headerContainer: {
        display: 'flex',
        height: '50px',
        alignItems: 'center', 
        justifyContent: 'space-between',
        backgroundColor: Colors.primary,
        color: '#fff'
    },
    imgLogo: {
        objectFit: 'scale-down',
        width: '40px', 
        height:'40px',
    }
}