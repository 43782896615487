import { Dialog, Stack } from '@mui/material';
import { CameraAltIcon, CancelIcon, CloudUploadIcon, SaveIcon } from 'Iconos';
import { CustomButton } from 'components';
import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { SubirFoto } from 'services';
import { isEnglish, isStringEmpty } from 'utilities/Generals';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { adaptResponseJDAPI } from 'adapters';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';
import { BasicSwalConfig } from 'Constants';

type OnTakenPictureAction = 'upload' | 'localSave';

type CameraProps = {
    onUploadPhoto?: (urlPhoto : string) => void,
    isModal?: boolean | undefined,
    onClose: () => void,
    photoParameters: {
        pantalla: string,
        folio: string,
        entrada: string
    },
    onTakenPictureAction?: OnTakenPictureAction,
    onSavePhoto?: (photo : File, dataUrl: string) => void
    photoName?: string
}
export default function Camera(props : CameraProps){
    const cameraHeight = window.innerHeight * 0.88;
    const cameraWidth = window.innerWidth;
    const webCamRef = useRef<Webcam>(null);
    const [pictureTaken, setPictureTaken] = useState<string>('');
    const [openPreviewDialog, setOpenPreviewDialog] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onUploadPhoto = props.onUploadPhoto?? ((urlPhoto : string) => {});
    const onTakenPictureAction = props.onTakenPictureAction?? 'upload';
    const onSavePhoto = props.onSavePhoto?? ((photo : File, dataUrl: string) => {});
    return(
        <Stack direction='column' style={{height:'100%'}}>
            <Webcam
                audio={false}
                height={cameraHeight}
                screenshotFormat='image/jpeg'
                ref={webCamRef}
                width={cameraWidth}
                videoConstraints={{ 
                    width: {min: 300}, 
                    height: { min: 600}, 
                    aspectRatio: cameraWidth/cameraHeight,
                    facingMode: 'environment'
                }}
            />
            <div style={{justifyContent: 'center', alignItems:'center'}}>
                <CustomButton
                    style={{margin:'10px', width: 'calc(100% - 20px)'}}
                    onClick={() => {
                        setPictureTaken(webCamRef?.current?.getScreenshot()?? '');
                        setOpenPreviewDialog(true);
                    }}
                >
                    <CameraAltIcon style={{fontSize:'1.5em'}}/>
                </CustomButton>
                <CustomButton
                    style={{margin:'10px', width: 'calc(100% - 20px)'}}
                    onClick={() => {
                        props.onClose();
                    }}
                >
                    Cerrar
                </CustomButton>
            </div>
            <Dialog
                open={openPreviewDialog}
                fullWidth
                maxWidth='md'
            >
                <Stack direction='column' style={{margin: '10px', alignItems: 'center', rowGap: '10px'}}>
                    <img width='75%' height='75%' src={pictureTaken} alt='preview' style={{borderRadius: '15px', objectFit:'scale-down'}}/>
                    <Box sx={{ width: '100%' }}>
                        { isLoading && <LinearProgress />}
                    </Box>
                    <Stack direction='row' spacing={2}>
                        <div onClick={() => setOpenPreviewDialog(false)}>
                            <CancelIcon style={{fontSize:'2em', color: 'red'}}/>
                        </div>
                        {
                            onTakenPictureAction === 'upload' &&
                            <div onClick={async () => { 
                                onUploadPhoto(pictureTaken);
                                setIsLoading(true);
                                const pic = pictureTaken
                                if(!isStringEmpty(pic)){
                                    const type = pic.split(';')[0].replace('data:','');
                                    const extension = type.split('/')[1];
                                    const {pantalla, folio, entrada} = props.photoParameters;
                                    const picName = `${pantalla}-${folio}${!isStringEmpty(entrada)? '-' : ''}${entrada}.${extension}`;
                                    let formDataParams = new FormData();
                                    const picBlob = await fetch(pic).then(result => result.blob());
                                    const picFile = new File([picBlob], picName, { type });
                                    formDataParams.set('photo', picFile)
                                    await SubirFoto(formDataParams).then(response => {
                                        const responseAPI = adaptResponseJDAPI(response.data);
                                        const customSwalConfig = {
                                            icon: (responseAPI.error? 'error' : 'success' as SweetAlertIcon),
                                            title: responseAPI.error? 'Error' : isEnglish()? 'Success' : 'Éxito',
                                            text: isEnglish()? responseAPI.messageEn : responseAPI.messageEs
                                        }
                                        setIsLoading(false);
                                        Swal.fire({...BasicSwalConfig, ...customSwalConfig}).then(result => {
                                            if(result.isConfirmed)
                                                setOpenPreviewDialog(false);
                                        });
                                    })
                                    .catch(() => setIsLoading(false));
                                }
                            }}>
                                <CloudUploadIcon style={{fontSize:'2em', color: 'blue'}}/>
                            </div>
                        }
                        {
                            onTakenPictureAction === 'localSave' && 
                            <div
                                onClick={async () => {
                                    setIsLoading(true);
                                    const pic = pictureTaken
                                    if(!isStringEmpty(pic)){
                                        const {pantalla, folio, entrada} = props.photoParameters;
                                        const type = pic.split(';')[0].replace('data:','');
                                        const extension = type.split('/')[1];
                                        const picBlob = await fetch(pic).then(result => result.blob());
                                        const picName = `${pantalla}-${folio}${!isStringEmpty(entrada)? '-' : ''}${entrada}.${extension}`;
                                        const picFile = new File([picBlob], props.photoName?? picName, { type });
                                        setIsLoading(false);
                                        onSavePhoto(picFile, pic);
                                        const customSwalConfig : SweetAlertOptions = {
                                            icon: 'success',
                                            title: isEnglish()? 'Success' : 'Éxito',
                                            text: isEnglish()? 'Photo saved successfully' : 'Foto guardada correctamente'
                                        }
                                        Swal.fire({...BasicSwalConfig, ...customSwalConfig}).then(result => {
                                            if(result.isConfirmed)
                                                setOpenPreviewDialog(false);
                                        });
                                    }
                                }}
                            >
                                <SaveIcon style={{fontSize:'2em', color: 'blue'}}/>
                            </div>
                        }
                    </Stack>
                </Stack>

            </Dialog>
        </Stack>
    );
}