import { CatalogoType } from "models";

export const adaptGuideType = (data : any) : CatalogoType => {
    const adaptedGuideType : CatalogoType = {
        id: data?.Id?? '',
        description: data?.Description?? ''
    }

    return adaptedGuideType;
}

export const adaptPrinterType = (data : any) : CatalogoType => {
    const adaptedPrinterType : CatalogoType = {
        id: data?.Id?? '',
        description: data?.Description?? ''
    }

    return adaptedPrinterType;
}