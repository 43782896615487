import { Dialog, Divider, Stack } from "@mui/material";
import { BasicSwalConfig, Colors, RouteConfigProps, RoutesConfig } from "Constants";
import { StyleObject } from "GlobalTypes";
import { CameraAltIcon, MessageIcon, MoreHorizIcon, StraightIcon } from "Iconos";
import { adaptInventory, adaptMasterLabelDetails, adaptResponseJDAPI } from "adapters";
import { Camera, CustomButton, CustomTextInput } from "components";
import { NavigationContext } from "context/global";
import Awaiter from "hooks/Awaiter";
import { Inventory, InventoryType, MasterLabelDetailType } from "models";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom"
import { GetInventoryDetail, GetMasterLabelInventoryDetail, SaveCommentService, SavePackageService, SendFinalReportService, SendPreliminaryReportService, UpdateScannedService, ValidatePackageService } from "services";
import Swal, { SweetAlertIcon, SweetAlertOptions } from "sweetalert2";
import { isEnglish, isStringEmpty } from "utilities/Generals";

const ShowAlert = ( title: string, message : string, icono : SweetAlertIcon = 'info') => {
    const customConfig : SweetAlertOptions = {
        icon: icono,
        title: isStringEmpty(title)? BasicSwalConfig.title : title,
        text: isStringEmpty(message)? BasicSwalConfig.text : message
    }
    
    Swal.fire({...BasicSwalConfig,...customConfig});
}

type SortBy = 'clientName' | 'entry' | 'bulto' | 'location' | 'scanned';
type SortOrder = 'asc' | 'desc'
type SortOrderParameters = Record<SortBy, SortOrder>;
type SoundType = 'E' | 'U' | 'S';
type ScannedLabelType = {
    code : string,
    date : Date
}

export default function InventoryReport(){
    const state = useLocation().state;
    const navigationContext = useContext(NavigationContext);
    const locationPage = useLocation().pathname;
    const [folio] = useState<string>(state?.folio?? '');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [entry, setEntry] = useState<string>('');
    const [inventoryDetail, setInventoryDetail] = useState<Array<InventoryType>>([]);
    const [selectedInventoryDetail, setSelectedInventoryDetail] = useState<InventoryType>(new Inventory());
    const [openCameraModal, setOpenCameraModal] =  useState<boolean>(false);
    const [allowSave] = useState<boolean>(!isStringEmpty(folio));
    const [showFloatMenu, setShowFloatMenu] = useState<boolean>(false);
    const [openInventoryDetailCommentModal, setOpenInventoryDetailCommentModal] = useState<boolean>(false);
    const [activeSortOrderParam, setActiveSortOrderParam] = useState<SortBy | ''>('');
    const [sortOrderParams, setSortOrderParams] = useState<SortOrderParameters>({
        clientName: 'asc',
        entry: 'asc',
        bulto: 'asc',
        location: 'asc',
        scanned: 'asc'
    });
    const audioPlayer = useRef<HTMLAudioElement>(null);
    const [sound] = useState<string>(require('../../../assets/sounds/Success.mp3'));
    const [scannedLabels, setScannedLabels] = useState<Array<ScannedLabelType>>([]);

    useEffect(() => {
        setIsLoading(true);
        const routeByLocation = Object.entries(RoutesConfig.private).find(([, route] : [string, RouteConfigProps]) =>  locationPage === `/Global/${route.path}${locationPage.endsWith('/')? '/' : ''}`);
        navigationContext.onChangePage((isEnglish()? routeByLocation?.[1].titleEn : routeByLocation?.[1].title)?? '');

        GetInventoryDetail(folio).then(response => {
            setIsLoading(false);
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const {messageEn, messageEs} = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                return;
            }

            if(responseAPI.data){
                const adaptedInventoryDetail = (response.data.Object as Array<any>).map((detail : any) => adaptInventory(detail));
                setInventoryDetail(adaptedInventoryDetail);
            }
        })
        .catch(error => {
            setIsLoading(false);
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to get inventory detail' : 'Ocurrió un problema al intentar obtener el detalle de inventario'}:\n ${error.message}`, 'error');
        })
    },[]);

    const playSound = async (_status : SoundType) => {
        const sounds = [
            { 
                status: 'E',
                sound: require('../../../assets/sounds/Wrong.mp3')
            },
            { 
                status: 'U',
                sound: require('../../../assets/sounds/Urgent.mp3')
            },
            { 
                status: 'S',
                sound: require('../../../assets/sounds/Success.mp3')
            }
        ];
        const soundPlayer = audioPlayer.current;
        const soundToPlay = sounds.find(({status}) => status === _status)?.sound;
        if(soundPlayer){
            soundPlayer.src = soundToPlay;
            soundPlayer.play();
        }
    }

    const AddScan = (label : string) => {
        if(!isStringEmpty(label)){
            if(isMasterLabel(label)){
                ValidaEtiquetaMaster(label);
            }else{
                ValidaSoloBulto(label);
            }
        }else{
            ShowErrorMessage(isEnglish()? 'A label has not been scanned' : 'No se ha escaneado una etiqueta');
        }
        setEntry('');
    }

    const isMasterLabel = (etiqueta : string) => etiqueta.toUpperCase().startsWith('M');

    const ValidaEtiquetaMaster = (etiqueta : string) => {
        setIsLoading(true);
        GetMasterLabelInventoryDetail(etiqueta).then(response => {
            setIsLoading(false);
            const responseAPI = adaptResponseJDAPI(response.data);
            const {messageEn, messageEs} = responseAPI;
            if(responseAPI.error){
                ShowErrorMessage(isEnglish()? messageEn : messageEs);
                return;
            }

            if(responseAPI.data){
                const adaptedMasterLabelDetail = (responseAPI.data as Array<any>).map((masterLabelDetail : any) => adaptMasterLabelDetails(masterLabelDetail));
                adaptedMasterLabelDetail.forEach((masterLabelDetail : MasterLabelDetailType) => {
                    ValidaSoloBulto(masterLabelDetail.numeroBulto);
                });
            }
        })
        .catch(error => {
            setIsLoading(false);
            ShowErrorMessage(`${isEnglish()? 'There was a problem trying to generate preliminary reporting' : 'Ocurrió un problema al intentar generar reporte preliminar'}:\n ${error.message}`);
        });
    }

    const ValidaSoloBulto = async (label : string) => {
        const splittedLabel = label.split('-');
        const [numeroEntrada, tipoBulto] = splittedLabel;
        try{
            if(!isStringEmpty(numeroEntrada) && !isStringEmpty(tipoBulto)){
                if(CheckValidCode(numeroEntrada, tipoBulto)){
                    if(isStringEmpty(scannedLabels.find((scannedLabel : ScannedLabelType) => scannedLabel.code === label)?.code?? '')){
                        if(await AddPackage(label)){
                            UpdateQuantity(numeroEntrada, tipoBulto);
                        }
                    }else{
                        ShowErrorMessage(isEnglish()? 'Label was already scanned' : 'Etiqueta ya fue escaneado');
                    }
                }else{
                    ValidatePackage(numeroEntrada, tipoBulto, label);
                }
            }else{
                ShowErrorMessage(isEnglish()? 'The label is incomplete' : 'La etiqueta esta incompleta');
            }
        }
        catch(error) {
            ShowErrorMessage(isEnglish()? 'There was a problem validating single package' : 'Ocurrió un problema al validar solo bulto');
        }
    }

    const CheckValidCode = (numeroEntrada : string, tipoBulto : string) => {
        return inventoryDetail.some((detail : InventoryType) => detail.tipoBulto === tipoBulto && [detail.anterior, detail.detalle].includes(numeroEntrada))
    }

    const AddPackage = async (label : string) => {
        let result : boolean = false;
        setIsLoading(true);
        await SavePackageService(folio, label).then(response => {
            setIsLoading(false);
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const {messageEn, messageEs} = responseAPI;
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
                return;
            }
        
            let scannedTemp = scannedLabels;
            scannedTemp.push({
                code: label,
                date: new Date()
            });
            setScannedLabels([...scannedTemp]);
        
            result = true
        })
        .catch(error => {
            setIsLoading(false);
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem adding package' : 'Ocurrió un problema al agregar bulto'}:\n ${error.message}`, 'error');
        });
        return result;
    }

    const UpdateQuantity = (numeroEntrada : string, tipoBulto : string) => {
        let tipo = '';
        if(CheckValidCode(numeroEntrada, tipoBulto)){
            var result = inventoryDetail.find((detail : InventoryType) => detail.tipoBulto === tipoBulto && detail.anterior === numeroEntrada);
            if(result){
                tipo = 'anterior'
            }else{
                tipo = 'detalle'
            }
            const param = {
                folio: folio,
                numeroEntrada,
                tipoBulto,
                tipo
            }
            setIsLoading(true);
            UpdateScannedService(param).then(response => {
                setIsLoading(false);
                const responseAPI = adaptResponseJDAPI(response.data);
                if(responseAPI.error){
                    const {messageEn, messageEs} = responseAPI;
                    ShowErrorMessage(isEnglish()? messageEn : messageEs);
                    return;
                }else{
                    if(result) result.scanned = (result?.scanned?? 0) + 1;
                    playSound('S');
                }
            })
            .catch(error => {
                setIsLoading(false);
                ShowErrorMessage(`${isEnglish()? 'There was a problem saving the scanner verification' : 'Ocurrió un problema al guardar la verificación del escaner'}:\n ${error.message}`);
            });
        }
    }

    const ValidatePackage = (numeroEntrada : string, tipoBulto : string, label : string) => {
        setIsLoading(true);
        ValidatePackageService(numeroEntrada, tipoBulto).then(async response => {
            setIsLoading(false);
            const responseAPI = adaptResponseJDAPI(response.data);
            if(responseAPI.error){
                const {messageEn, messageEs} = responseAPI;
                ShowErrorMessage(isEnglish()? messageEn : messageEs);
                return;
            }

            if(responseAPI.data){
                const bulto = adaptInventory(responseAPI.data);
                if(inventoryDetail.find((detail : InventoryType) => detail.clientCode === bulto.clientCode)){
                    if(isStringEmpty(scannedLabels.find((scannedLabel : ScannedLabelType) => scannedLabel.code === label)?.code?? '')){
                        if(await AddPackage(label)){
                            const temp = inventoryDetail;
                            temp.push(bulto);
                            setInventoryDetail([...temp]);
                            UpdateQuantity(numeroEntrada, tipoBulto);
                        }
                    }else{
                        ShowErrorMessage(isEnglish()? 'Label was already scanned' : 'Etiqueta ya fue escaneado');
                    }
                }else{
                    ShowErrorMessage(isEnglish()? 'The label does not belong to these customers' : 'La etiqueta no pertenece a estos clientes');
                }
            }else{
                ShowErrorMessage(isEnglish()? 'Label does not exist in the database, please validate' : 'Etiqueta no existe en la base de datos, favor de validar');
            }
        })
        .catch(error => {
            setIsLoading(false);
            ShowErrorMessage(`${isEnglish()? 'There was a problem validating package' : 'Ocurrio un problema al validar bulto'}:\n ${error.message}`);
        })
    }

    /**
     * 
     * @param leftValue 
     * @param rightValue 
     * @returns -1 si leftValue es menor que rightValue, 0 si son iguales ó 1 si leftValue es mayor que rigthValue
     */
    const ComparerFunction = (leftValue: number, rightValue: number) : -1 | 0 | 1 => {
        let diff = leftValue - rightValue;
        return diff === 0? diff : (diff > 0? 1 : -1);
    }

    const SendPreliminaryReport = (folio : string) => {
        setIsLoading(true);
        SendPreliminaryReportService(folio).then(response => {
            setIsLoading(false);
            const responseAPI = adaptResponseJDAPI(response.data);
            const {messageEn, messageEs} = responseAPI;
            if(responseAPI.error){
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
            }else{
                ShowAlert('Info', isEnglish()? messageEn : messageEs, 'info');
            }
        })
        .catch(error => {
            setIsLoading(false);
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to generate preliminary reporting' : 'Ocurrió un problema al intentar generar reporte preliminar'}:\n ${error.message}`, 'error');
        });
    } 

    const SendFinalReport = (folio : string) => {
        setIsLoading(true);
        SendFinalReportService(folio).then(response => {
            const responseAPI = adaptResponseJDAPI(response.data);
            const {messageEn, messageEs} = responseAPI;
            setIsLoading(false);
            if(responseAPI.error){
                ShowAlert('Error', isEnglish()? messageEn : messageEs, 'error');
            }else{
                ShowAlert('Info', isEnglish()? messageEn : messageEs, 'info');
            }
        })
        .catch(error => {
            setIsLoading(false);
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to generate final reporting' : 'Ocurrió un problema al intentar generar reporte final'}:\n ${error.message}`, 'error');
        });
    } 

    const sortFunction = (sortOrderParam : SortOrder, current : string, next : string) => {
        current = current.toUpperCase();
        next = next.toUpperCase();
        let comparison = 0;
        if(current > next)
            comparison = sortOrderParam === 'asc'? 1 : -1;
        else
            comparison = sortOrderParam === 'asc'? -1 : 1;

        return comparison;
    }

    const OrderBy = (orderParam : SortBy) => {
        setActiveSortOrderParam(orderParam);
        let sortedInventoryDetail = inventoryDetail;
        sortedInventoryDetail = sortedInventoryDetail.sort((current : InventoryType, next : InventoryType) => sortFunction(sortOrderParams[orderParam], current[orderParam].toString(), next[orderParam].toString()));
        setInventoryDetail(sortedInventoryDetail);
        setSortOrderParams({...sortOrderParams, [orderParam]: sortOrderParams[orderParam] === 'asc'? 'desc' : 'asc'});
    }
    
    const ShowErrorMessage = ( message : string) => {
        playSound('E');
        const customConfig : SweetAlertOptions = {
            text: isStringEmpty(message)? BasicSwalConfig.text : message,
            showCancelButton: true
        }
        
        Swal.fire({...BasicSwalConfig,...customConfig}).then(result => {
            if(!result.isConfirmed){
                ShowErrorMessage(message);
            }
        });
    }

    return(
        <Awaiter showLoader={isLoading}>
            <audio src={sound} style={{display:'none'}} ref={audioPlayer}></audio>
            <Stack direction='column' divider={<Divider />} spacing={1} style={{padding: '0px 5px'}}>
                <CustomTextInput 
                    variant='standard'
                    label={isEnglish()? 'Label' : 'Etiqueta'}
                    size='small'
                    sx={{
                        minWidth: '100px'
                    }}
                    onChange={(event : React.ChangeEvent<HTMLInputElement>) => setEntry(event.target.value)}
                    onKeyDown={(event : React.KeyboardEvent<HTMLInputElement>) => {
                        if(event.key === 'Enter'){
                            AddScan(entry);
                        }
                        
                    }}
                    value={entry}
                />
                <Stack direction='column'>
                    <Stack 
                        direction='row' 
                        style={{...styles.tableDisplay,...styles.tableHeader}} 
                        sx={{
                            '& div > p, ' : { 
                                color: '#fff', 
                                margin:'2px 2px', 
                                textAlign: 'center',
                                fontSize: '0.9em'
                            }
                        }}
                    >
                        <div style={styles.sortContainers}>
                            <p onClick={() => { OrderBy('clientName'); }}>{isEnglish()? 'Client' : 'Cliente'}</p>
                            { activeSortOrderParam === 'clientName' && <StraightIcon style={{...styles.sortIcon, transform: `rotate(${sortOrderParams.clientName === 'asc'? 0 : 180}deg)`}}/> }
                        </div>
                        <div style={styles.sortContainers}>
                            <p onClick={() => { OrderBy('entry'); }}> {isEnglish()? 'Entry' : 'Entrada'}</p>
                            { activeSortOrderParam === 'entry' && <StraightIcon style={{...styles.sortIcon, transform: `rotate(${sortOrderParams.entry === 'asc'? 0 : 180}deg)`}}/> }
                        </div>
                        <div style={styles.sortContainers}>
                            <p onClick={() => { OrderBy('location'); }}>{isEnglish()? 'Location' : 'Ubicación'}</p>
                            { activeSortOrderParam === 'location' && <StraightIcon style={{...styles.sortIcon, transform: `rotate(${sortOrderParams.location === 'asc'? 0 : 180}deg)`}}/> }
                        </div>
                        <div style={styles.sortContainers}>
                            <p onClick={() => { OrderBy('bulto'); }}>{isEnglish()? 'Packages' : 'Bulto'}</p>
                            { activeSortOrderParam === 'bulto' && <StraightIcon style={{...styles.sortIcon, transform: `rotate(${sortOrderParams.bulto === 'asc'? 0 : 180}deg)`}}/> }
                        </div>
                        <div style={styles.sortContainers}>
                            <p>{isEnglish()? 'Exist' : 'Existe'}</p>
                        </div>
                        <div style={styles.sortContainers}>
                            <p onClick={() => { OrderBy('scanned'); }}>{isEnglish()? 'Scan' : 'Scan'}</p>
                            { activeSortOrderParam === 'scanned' && <StraightIcon style={{...styles.sortIcon, transform: `rotate(${sortOrderParams.scanned === 'asc'? 0 : 180}deg)`}}/> }
                        </div>
                    </Stack>
                    <Stack direction='column' style={styles.tableBody} sx={{'& div > p': {fontSize: '0.9em'}}}>
                        {
                            inventoryDetail.map((_detalle : InventoryType) => 
                                <div 
                                    key={_detalle.idInventoryDetails} 
                                    style={{
                                        ...styles.tableDisplay, 
                                        height:'auto', 
                                        fontSize:'0.9em', 
                                        backgroundColor: rowColor[_detalle.idInventoryDetails === selectedInventoryDetail.idInventoryDetails? 'selected' : ComparerFunction(_detalle.exist, _detalle.scanned?? 0).toString()].backgroundColor
                                    }} 
                                    className='rowDetalleItem'
                                    onClick={() => setSelectedInventoryDetail(_detalle)}
                                >
                                    <p>{_detalle.clientName}</p>
                                    <p>{_detalle.entry}</p>
                                    <p>{_detalle.location}</p>
                                    <p>{_detalle.bulto}</p>
                                    <p>{_detalle.exist}</p>
                                    <p>{_detalle.scanned}</p>
                                </div>
                            )
                        }
                    </Stack>
                </Stack>
                <Stack direction='row' style={{padding:'0px 5px', gap:'20px', marginTop:'10px'}}>
                    <CustomButton
                        style={{
                            height:'30px',
                            width: '100%',
                            minWidth: '100px',
                            maxWidth: '200px',
                            alignSelf:'center',
                            justifySelf:'center'
                        }}
                        disabled={!allowSave}
                        onClick={() => {
                            SendPreliminaryReport(folio);
                        }}
                    >
                        {isEnglish()? 'Preliminary' : 'Preliminar'}
                    </CustomButton>
                    <CustomButton
                        style={{
                            height:'30px',
                            width: '100%',
                            minWidth: '100px',
                            maxWidth: '200px',
                            alignSelf:'center',
                            justifySelf:'center'
                        }}
                        disabled={!allowSave}
                        onClick={() => {
                            SendFinalReport(folio);
                        }}
                    >
                        {isEnglish()? 'Final' : 'Final'}
                    </CustomButton>
                </Stack>
            </Stack>
            <div style={{...styles.floatButton, opacity: (selectedInventoryDetail.idInventoryDetails <= 0)? '0.5' : '1'}}>
                <div 
                    style={{
                        ...styles.floatButtonOptionsContainer, 
                        ...{
                                display: `${showFloatMenu? 'block' : 'none'}`, 
                                animation: `${showFloatMenu? 'appear' : 'disappear'} 0.5s`
                            }
                        }}
                    >
                    <div style={styles.floatButtonOptions}>
                        <div 
                            style={{...styles.floatButtonOption, backgroundColor: '#3B5998'}}
                            onClick={() => {
                                if(!isStringEmpty(selectedInventoryDetail.entry)){
                                    setOpenCameraModal(true);
                                }
                            }}
                        >
                            <CameraAltIcon style={{fontSize:'1.2em', color:'#FFF'}} />
                        </div>
                        <div 
                            style={{...styles.floatButtonOption, backgroundColor: '#34A34F'}}
                            onClick={() => {
                                if(selectedInventoryDetail.idInventoryDetails > 0){
                                    setOpenInventoryDetailCommentModal(true);
                                }
                            }}
                        >
                            <MessageIcon style={{fontSize:'1.2em', color:'#FFF'}} />
                        </div>
                    </div>
                </div>
                <div style={styles.floatButtonIconContainer} onClick={() => {
                        if(!isStringEmpty(selectedInventoryDetail.entry)){
                            setShowFloatMenu(!showFloatMenu)
                        }
                    }}
                >
                    <MoreHorizIcon style={{fontSize:'1.5em', color:'#FFF'}}/>
                </div>
            </div>
            <Dialog
                open={openCameraModal}
                fullScreen
            >
                <Stack direction='column' style={{height:'80vh', width:'100vw'}}>
                    <Camera
                        isModal={true}
                        onClose={() => setOpenCameraModal(false)}
                        photoParameters={{
                            pantalla: 'INV',
                            folio: folio,
                            entrada: selectedInventoryDetail.entry
                        }}
                        onTakenPictureAction='upload'
                    />
                </Stack>
            </Dialog>
            <Dialog
                open={openInventoryDetailCommentModal}
                fullWidth
                maxWidth='md'
            >
                <InventoryDetailCommentModal 
                    selectedInventoryDetail={selectedInventoryDetail}
                    onUpdateSelectedInventoryDetail={(detail : InventoryType) => {
                        setSelectedInventoryDetail(detail);
                        setInventoryDetail(inventoryDetail.map((_detail : InventoryType) => {
                            if(_detail.idInventoryDetails === detail.idInventoryDetails){
                                _detail.comment = detail.comment;
                            }
                            return _detail;
                        }));
                    }}
                    onClose={() => {
                        setOpenInventoryDetailCommentModal(false);
                    }}
                    onLoading={(_isLoading : boolean) => setIsLoading(_isLoading)}
                />
            </Dialog>
        </Awaiter>
    );
}

type InventoryDetailCommentModalProps = {
    selectedInventoryDetail: InventoryType,
    onUpdateSelectedInventoryDetail: (detail : InventoryType) => void,
    onClose: () => void,
    onLoading: (_isLoading : boolean) => void
}

const InventoryDetailCommentModal = ({selectedInventoryDetail, onUpdateSelectedInventoryDetail, onClose, onLoading} : InventoryDetailCommentModalProps) => {
    const [comment, setComment] = useState<string>(selectedInventoryDetail.comment);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const changeLoadingState = (_isLoading : boolean) => {
        onLoading(_isLoading);
        setIsLoading(_isLoading);
    }

    const saveComment = (comentario : string) => {
        changeLoadingState(true);
        SaveCommentService({
            idInventoryDetail : selectedInventoryDetail.idInventoryDetails, 
            description: comentario
        }).then(response => {
            changeLoadingState(false);
            const responseAPI = adaptResponseJDAPI(response.data);
            const {messageEn, messageEs} = responseAPI;
            const customSwalConfig = {
                icon: (responseAPI.error? 'error' : 'success' as SweetAlertIcon),
                title: responseAPI.error? 'Error' : isEnglish()? 'Success' : 'Éxito',
                text: isEnglish()? (responseAPI.error? messageEn : 'Comment was saved') : (responseAPI.error? messageEs : 'Se guardó comentario')
            }
            Swal.fire({...BasicSwalConfig, ...customSwalConfig}).then(result => {
                if(result.isConfirmed){
                    if(!responseAPI.error)
                        onUpdateSelectedInventoryDetail({...selectedInventoryDetail, comment: comment});
                    onClose();
                }
            });
        })
        .catch(error => {
            changeLoadingState(false);
            ShowAlert('Critical Error', `${isEnglish()? 'There was a problem trying to save comment' : 'Ocurrió un problema al intentar guardar el comentario'}:\n ${error.message}`, 'error');
        });
    }

    return(
        <Stack direction='column' style={{display:'flex', alignItems:'flex-start', gap:'10px', margin:'20px'}}>
            <p>{isEnglish()? 'Comment' : 'Comentarios'}</p>
            <CustomTextInput 
                variant='standard'
                label=''
                sx={{
                    width: '100%',
                    minWidth: '150px',
                    maxHeight: '181px',
                    overflowY:'auto',
                    '& .MuiInputBase-root': {
                        '& textarea': {
                            padding: '10px'
                        }
                    }
                }}
                onChange={(event : React.ChangeEvent<HTMLInputElement>) => setComment(event.target.value)}
                value={comment}
                multiline={true}
                disabled={isLoading}
            />
            <Stack direction='row' style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'10px'}}>
                <CustomButton
                    style={{
                        width: '50%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center'
                    }}
                    variant='contained'
                    onClick={() =>{ 
                        saveComment(comment);
                    }}
                    disabled={isLoading}
                >
                    Guardar
                </CustomButton>
                <CustomButton
                    style={{
                        width: '50%',
                        minWidth: '150px',
                        maxWidth: '300px',
                        alignSelf: 'center',
                        backgroundColor: '#B9B9B9'
                    }}
                    variant='contained'
                    onClick={onClose}
                >
                    Cancelar
                </CustomButton>
            </Stack>
        </Stack>
    )
};

const rowColor : StyleObject = {
    '-1' : {
        backgroundColor: Colors.redError
    },
    '0': {
        backgroundColor:  Colors.greenSuccess
    },
    '1' : {
        backgroundColor: 'transparent'
    },
    'selected' : {
        backgroundColor : '#E5E5E5'
    }
}

const styles : StyleObject = {
    tableDisplay: {
        display: 'grid',
        gridTemplateColumns: '15% 16% 1fr 1fr 12% 13%',
        height: '30px',
        alignItems: 'center'
    },
    tableHeader: {
        backgroundColor: Colors.primary,
        alignItems: 'center'
    },
    tableBody: {
        height: 'calc(100vh - 210px)',  
        overflowY: 'auto'
    },
    entryItem: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px 5px 0px 0px',
        padding: '10px 5px',
        borderBottom: `1px ${Colors.primary} solid`
    },
    floatButton: {
        position:'absolute', 
        bottom:'50px', 
        right:'10px', 
        backgroundColor: Colors.primary,
        width: '50px',
        height: '50px',
        borderRadius: '50%',
    },
    floatButtonIconContainer: {
        display: 'flex', 
        width:'100%', 
        height:'100%', 
        alignItems: 'center', 
        justifyContent:'center'
    },
    floatButtonOption: {
        display: 'flex', 
        width:'40px', 
        height:'40px', 
        borderRadius: '50%',
        alignItems:'center',
        justifyContent: 'center'
    },
    floatButtonOptions: {
        display:'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px'
    },
    floatButtonOptionsContainer: {
        position: 'absolute',
        bottom: '55px',
        width: '50px',
        animation: 'appear 0.5s' 
    },
    sortContainers: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    sortIcon: {
        fontSize: '1em',
        color: '#FFF'
    }
}