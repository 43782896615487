import { api_url } from "Constants"
import AxiosRequest from "./AxiosRequest"
import { SalidaType } from "models";

const apiUrl = api_url.WareHouse;

export const GetSalidasByODC = (odc : string) => {
    return AxiosRequest.get(`${apiUrl}Salida/BuscarSalida?odc=${odc}`);
}

export const SaveSalida = (data : SalidaType) => {
    return AxiosRequest.post(`${apiUrl}Salida/GuardarSalida`, data, {
        headers: {
            'Content-Type' : 'application/json'
        }
    });
}

export const ValidateMasterLabel = ({etiqueta, idEntrada, cliente} : {etiqueta: string, idEntrada: number, cliente: string}) => {
    return AxiosRequest.get(`${apiUrl}Salida/ValidarEtiquetaMaster?etiqueta=${etiqueta}&idEntrada=${idEntrada}&cliente=${encodeURIComponent(cliente)}`);
}